import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MultiLang } from '../config';
import CerebellarPlatformCommittee from '../custom/blocks/CerebellarPlatformCommittee';
import CerebrationOfDrItosNewBook from '../custom/blocks/CerebrationOfDrItosNewBook';
import NewArticleAlert from '../custom/blocks/NewArticleAlert';
import Rankings from '../database/blocks/Rankings';
import RecentContents from '../database/blocks/RecentContents';
import DatabaseTop from '../database/DatabaseTop';
import Functions from '../functions';
import Footer from './Footer';
import MainContent from './MainContent';
import Welcome from '../custom/blocks/Welcome';

interface Props {
    lang: MultiLang;
}

const CenterBlocks = (props: Props) => {
    const { lang } = props;
    return (
        <div className="block">
            <div className="centerCcolumn">
            <div className="block">
                    <div className="blockTitle">Welcome to Cerebellar Platform</div>
                    <div className="blockContent">
                        <Welcome lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">Cerebration of Dr. Ito's New Book</div>
                    <div className="blockContent">
                        <CerebrationOfDrItosNewBook lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">Cerebellar Platfrom Committee</div>
                    <div className="blockContent">
                        <CerebellarPlatformCommittee lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">New article alert</div>
                    <div className="blockContent">
                        <NewArticleAlert lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">{Functions.mlang('[en]Item Types[/en][ja]アイテムタイプ一覧[/ja]', lang)}</div>
                    <div className="blockContent">
                        <DatabaseTop lang={lang} />
                    </div>
                </div>
            </div>
            <div className="clearfix">
                <div className="centerLcolumn">
                    <div className="block">
                        <div className="blockTitle">{Functions.mlang('[en]Ranking[/en][ja]ランキング[/ja]', lang)}</div>
                        <div className="blockContent">
                            <Rankings lang={lang} />
                        </div>
                    </div>
                </div>
                <div className="centerRcolumn">
                    <div className="block">
                        <div className="blockTitle">{Functions.mlang('[en]Recent Contents[/en][ja]新着コンテンツ[/ja]', lang)}</div>
                        <div className="blockContent">
                            <RecentContents lang={lang} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

const CenterColumn = (props: Props) => {
    const { lang } = props;

    return (
        <div className="centercolumn">
            <Switch>
                <Route exact path="/" render={() => <CenterBlocks lang={lang} />} />
            </Switch>
            <div className="block">
                <MainContent lang={lang} />
            </div>
            <div className="block">
                <div id="page_top">
                    <a href="#header" onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); }}>
                        <span className="hidden">Go Page Top</span>
                    </a>
                </div>
                <Footer lang={lang} />
            </div>
        </div>
    );
}

export default CenterColumn;