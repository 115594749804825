import moment from 'moment';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../config';
import Functions from '../functions';
import imageCategory0 from './assets/images/category_0.gif';
import imageCategory1 from './assets/images/category_1.gif';
import imageForum0 from './assets/images/forum_0.gif';
import imageForum1 from './assets/images/forum_1.gif';
import ForumJump from './lib/ForumJump';
import D3ForumUtils, { D3ForumCategoryData, D3ForumForumData } from './lib/D3ForumUtils';
import XoopsCode from '../common/lib/XoopsCode';
import PageNotFound from '../common/lib/PageNotFound';

export interface Props {
    lang: MultiLang;
    name: string;
}

interface D3ForumTopSubCategoryProps extends Props {
    catId: number;
}

interface D3ForumTopForumProps extends Props {
    catId: number;
}

const D3ForumTopForum = (props: D3ForumTopForumProps) => {
    const { lang, name, catId } = props;
    const LABEL_HEADER_TITLE = Functions.mlang('[en]Forum[/en][ja]フォーラム[/ja]', lang);
    const LABEL_TOPICS = Functions.mlang('[en]Topics[/en][ja]トピック数[/ja]', lang);
    const LABEL_POSTS = Functions.mlang('[en]Posts[/en][ja]投稿数[/ja]', lang);
    const LABEL_LATEST_POST = Functions.mlang('[en]Latest post[/en][ja]最新投稿[/ja]', lang);
    const forums = D3ForumUtils.getForums(name, catId).map((forum: D3ForumForumData) => {
        const title = Functions.mlang(forum.forum_title, lang);
        const url = D3ForumUtils.getForumUrl(name, forum.forum_id);
        const image = forum.forum_posts_count === 0 ? imageForum0 : imageForum1;
        const latest = forum.forum_last_post_time === 0 ? '' : ', ' + LABEL_LATEST_POST + ': ' + moment(new Date(forum.forum_last_post_time * 1000)).format('Y/M/D H:m');
        return (
            <li key={forum.forum_id}>
                <Link to={url} title={title}><img src={image} alt={title} width="16" height="16" /> {title}</Link>
                &nbsp;
                {LABEL_TOPICS}: {forum.forum_topics_count}, {LABEL_POSTS}: {forum.forum_posts_count}{latest}
            </li>
        );
    });
    if (forums.length === 0) {
        return null;
    }
    return (
        <>
            <h3 className="d3f_head_h3">{LABEL_HEADER_TITLE}</h3>
            <ul className="d3f_listforums">
                {forums}
            </ul>
        </>
    );
}

const D3ForumTopSubCategory = (props: D3ForumTopSubCategoryProps) => {
    const { lang, name, catId } = props;
    const LABEL_HEADER_TITLE = Functions.mlang('[en]Subcategories[/en][ja]サブカテゴリ[/ja]', lang);
    const LABEL_TOTAL_TOPICS = Functions.mlang('[en]Total topics[/en][ja]総トピック数[/ja]', lang);
    const LABEL_TOTAL_POSTS = Functions.mlang('[en]Total posts[/en][ja]総投稿数[/ja]', lang);
    const LABEL_LATEST_POST = Functions.mlang('[en]Latest post[/en][ja]最新投稿[/ja]', lang);
    const subCategories = D3ForumUtils.getSubCategories(name, catId).map((category: D3ForumCategoryData) => {
        const title = Functions.mlang(category.cat_title, lang);
        const url = D3ForumUtils.getCategoryUrl(name, category.cat_id);
        const image = category.cat_posts_count === 0 ? imageCategory0 : imageCategory1;
        const latest = category.cat_last_post_time === 0 ? '' : ', ' + LABEL_LATEST_POST + ': ' + moment(new Date(category.cat_last_post_time * 1000)).format('Y/M/D H:m');
        return (
            <li key={category.cat_id}>
                <Link to={url} title={title}><img src={image} alt={title} width="16" height="16" /> {title}</Link>
                &nbsp;
                ({LABEL_TOTAL_TOPICS}: {category.cat_topics_count}, {LABEL_TOTAL_POSTS}: {category.cat_posts_count}{latest})
            </li>
        );
    });
    if (subCategories.length === 0) {
        return null;
    }
    return (
        <>
            <h3 className="d3f_head_h3">{LABEL_HEADER_TITLE}</h3>
            <ul className="d3f_subcategories">
                {subCategories}
            </ul>
        </>
    );
}

const D3ForumTopCategory = (props: Props) => {
    const { lang, name } = props;
    const LABEL_TOTAL_TOPICS = Functions.mlang('[en]Total topics[/en][ja]総トピック数[/ja]', lang);
    const LABEL_TOTAL_POSTS = Functions.mlang('[en]Total posts[/en][ja]総投稿数[/ja]', lang);
    const LABEL_LATEST_POST = Functions.mlang('[en]Latest post[/en][ja]最新投稿[/ja]', lang);
    const categories = D3ForumUtils.getCategories(name).map((category: D3ForumCategoryData) => {
        const title = Functions.mlang(category.cat_title, lang);
        const image = category.cat_posts_count === 0 ? imageCategory0 : imageCategory1;
        const url = D3ForumUtils.getCategoryUrl(name, category.cat_id);
        const latest = category.cat_last_post_time === 0 ? '' : ', ' + LABEL_LATEST_POST + ': ' + moment(new Date(category.cat_last_post_time * 1000)).format('Y/M/D H:m');
        return (
            <Fragment key={category.cat_id}>
                <div className="itemHead">
                    <h2 className="itemTitle">
                        <Link to={url}><img src={image} alt="" width="16" height="16" /> {title}</Link>
                    </h2>
                    <p>{LABEL_TOTAL_TOPICS}: {category.cat_topics_count},  {LABEL_TOTAL_POSTS}: {category.cat_posts_count}{latest}</p>
                </div>
                <D3ForumTopSubCategory lang={lang} name={name} catId={category.cat_id} />
                <D3ForumTopForum lang={lang} name={name} catId={category.cat_id} />
                <div className="d3f_info_ctrl">&nbsp;
                </div>
            </Fragment>
        );
    });
    if (categories.length === 0) {
        return null;
    }
    return (
        <div className="d3f_wrap">
            {categories}
        </div>
    );
}

const D3ForumTop = (props: Props) => {
    const { lang, name } = props;
    const LABEL_TOTAL_TOPICS = Functions.mlang('[en]Total topics:[/en][ja]総トピック数:[/ja]', lang);
    const LABEL_TOTAL_POSTS = Functions.mlang('[en]Total posts:[/en][ja]総投稿数:[/ja]', lang);
    const LABEL_NEW_POSTS = Functions.mlang('[en]New posts[/en][ja]新しい投稿があります[/ja]', lang);
    const LABEL_NO_NEW_POSTS = Functions.mlang('[en]No new posts[/en][ja]新しい投稿はありません[/ja]', lang);
    const d3forum = D3ForumUtils.getModule(name);
    if (d3forum === null) {
        return <PageNotFound lang={lang} />;
    }
    return (
        <div>
            <XoopsCode lang={lang} text={d3forum.message} dohtml={true} />
            <dl className="d3f_bbsinfo">
                <dt>{LABEL_TOTAL_TOPICS} </dt>
                <dd>{D3ForumUtils.getTotalTopics(name)}, </dd>
                <dt>{LABEL_TOTAL_POSTS} </dt>
                <dd>{D3ForumUtils.getTotalPosts(name)}</dd>
            </dl>
            <D3ForumTopCategory lang={lang} name={name} />
            <div className="d3f_iconexps clearfix">
                <ul className="d3f_iconexp">
                    <li><img src={imageForum1} alt="" width="18" height="18" /> = {LABEL_NEW_POSTS}</li>
                </ul>
                <ul className="d3f_iconexp">
                    <li><img src={imageForum0} alt="" width="18" height="18" /> = {LABEL_NO_NEW_POSTS}</li>
                </ul>
            </div>

            <ForumJump lang={lang} name={name} forumId={0} />
        </div>
    );
}

export default D3ForumTop;
