import loki from 'lokijs';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import d3forumJson from '../assets/d3forum.json';

export interface D3ForumModuleData {
    name: string;
    dirname: string;
    message: string;
}

export interface D3ForumCategoryData {
    cat_id: number;
    pid: number;
    cat_title: string;
    cat_desc: string;
    cat_topics_count: number;
    cat_posts_count: number;
    cat_last_post_id: number;
    cat_last_post_time: number;
    cat_topics_count_in_tree: number;
    cat_posts_count_in_tree: number;
    cat_last_post_id_in_tree: number;
    cat_last_post_time_in_tree: number;
    cat_depth_in_tree: number;
    cat_order_in_tree: number;
    cat_path_in_tree: any;
    cat_unique_path: string;
    cat_weight: number;
    cat_options: any;
}

export interface D3ForumForumData {
    forum_id: number;
    cat_id: number;
    forum_external_link_format: string;
    forum_title: string;
    forum_desc: string;
    forum_topics_count: number;
    forum_posts_count: number;
    forum_last_post_id: number;
    forum_last_post_time: number;
    forum_weight: number;
    forum_options: any;
}

export interface D3ForumTopicData {
    topic_id: number;
    forum_id: number;
    topic_external_link_id: string;
    topic_title: string;
    topic_first_uid: number;
    topic_first_post_id: number;
    topic_first_post_time: number;
    topic_last_uid: number;
    topic_last_post_id: number;
    topic_last_post_time: number;
    topic_views: number;
    topic_posts_count: number;
    topic_locked: number;
    topic_sticky: number;
    topic_solved: number;
    topic_invisible: number;
    topic_votes_sum: number;
    topic_votes_count: number;
    topic_first_uid_name: string;
    topic_first_uid_uname: string;
    topic_last_uid_name: string;
    topic_last_uid_uname: string;
}

export interface D3ForumPostData {
    post_id: number;
    pid: number;
    topic_id: number;
    post_time: number;
    modified_time: number;
    uid: number;
    uid_hidden: number;
    poster_ip: string;
    modifier_ip: string;
    subject: string;
    subject_waiting: string;
    html: number;
    smiley: number;
    xcode: number;
    br: number;
    number_entity: number;
    special_entity: number;
    icon: number;
    attachsig: number;
    invisible: number;
    approval: number;
    votes_sum: number;
    votes_count: number;
    depth_in_tree: number;
    order_in_tree: number;
    path_in_tree: string;
    unique_path: string;
    guest_name: string;
    post_text: string;
    post_text_waiting: string;
    uid_name: string;
    uid_uname: string;
    uid_rank: number;
    uid_posts: number;
}

interface D3ForumData {
    module: D3ForumModuleData;
    categories: D3ForumCategoryData[];
    forums: D3ForumForumData[];
    topics: D3ForumTopicData[];
    posts: D3ForumPostData[];
}

interface D3ForumLokiData {
    module: D3ForumModuleData;
    categories: Collection<D3ForumCategoryData>;
    forums: Collection<D3ForumForumData>;
    topics: Collection<D3ForumTopicData>;
    posts: Collection<D3ForumPostData>;
}

const categorySort = (a: D3ForumCategoryData, b: D3ForumCategoryData) => {
    if (a.cat_weight > b.cat_weight) {
        return 1;
    } else if (a.cat_weight < b.cat_weight) {
        return -1;
    }
    if (a.cat_id > b.cat_id) {
        return 1;
    } else if (a.cat_id < b.cat_id) {
        return -1;
    }
    return 0;
}

const forumSort = (a: D3ForumForumData, b: D3ForumForumData) => {
    if (a.forum_weight > b.forum_weight) {
        return 1;
    } else if (a.forum_weight < b.forum_weight) {
        return -1;
    }
    if (a.forum_id > b.forum_id) {
        return 1;
    } else if (a.forum_id < b.forum_id) {
        return -1;
    }
    return 0;
}

export enum D3ForumPostSortOrder { TREE, OLD, NEW }
class D3ForumPostSorter {

    private order: D3ForumPostSortOrder;

    constructor(order: D3ForumPostSortOrder) {
        this.order = order;
        this.sort = this.sort.bind(this);
    }

    sort(a: D3ForumPostData, b: D3ForumPostData) {
        switch (this.order) {
            case D3ForumPostSortOrder.TREE: {
                if (a.unique_path > b.unique_path) {
                    return 1;
                } else if (a.unique_path < b.unique_path) {
                    return -1;
                }
                break;
            }
            case D3ForumPostSortOrder.OLD: {
                if (a.post_time > b.post_time) {
                    return 1;
                } else if (a.post_time < b.post_time) {
                    return -1;
                }
                break;
            }
            case D3ForumPostSortOrder.NEW: {
                if (a.post_time > b.post_time) {
                    return -1;
                } else if (a.post_time < b.post_time) {
                    return 1;
                }
                break;
            }
        }
        return 0;
    }
}

class D3ForumUtils {

    private database: loki;
    private modules: Map<string, D3ForumLokiData>;

    constructor(json: D3ForumData[]) {
        this.database = new loki('d3forum');
        this.modules = new Map<string, D3ForumLokiData>();
        json.forEach((data) => {
            const name = data.module.dirname;
            const d3forum = {
                module: data.module,
                categories: this.database.addCollection<D3ForumCategoryData>(name + '_categories'),
                forums: this.database.addCollection<D3ForumForumData>(name + '_forums'),
                topics: this.database.addCollection<D3ForumTopicData>(name + '_topics'),
                posts: this.database.addCollection<D3ForumPostData>(name + '_posts'),
            }
            data.categories.forEach((category) => {
                d3forum.categories.insert(category);
            });
            data.forums.forEach((forum) => {
                d3forum.forums.insert(forum);
            });
            data.topics.forEach((topic) => {
                d3forum.topics.insert(topic);
            });
            data.posts.forEach((post) => {
                d3forum.posts.insert(post);
            });
            this.modules.set(name, d3forum);
        });
    }

    getTitle(name: string, lang: MultiLang): string {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return '';
        }
        return Functions.mlang(d3forum.module.name, lang);
    }

    getIndexUrl(name: string): string {
        return '/' + name;
    }

    getCategoryUrl(name: string, catId: number): string {
        return this.getIndexUrl(name) + '/category/' + catId;
    }

    getForumUrl(name: string, forumId: number): string {
        return this.getIndexUrl(name) + '/forum/' + forumId;
    }

    getTopicUrl(name: string, topicId: number, order: D3ForumPostSortOrder, postId: number | null): string {
        const params = new URLSearchParams();
        if (order !== D3ForumPostSortOrder.TREE) {
            params.set('order', String(order));
        }
        const paramString = params.toString();
        return this.getIndexUrl(name) + '/topic/' + topicId + (paramString !== '' ? '?' + paramString : '') + (postId !== null ? '#postId' + postId : '');
    }

    getPostUrl(name: string, postId: number): string {
        return this.getIndexUrl(name) + '/post/' + postId;
    }

    getTotalTopics(name: string): number {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return 0;
        }
        return d3forum.topics.count();
    }

    getTotalPosts(name: string): number {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return 0;
        }
        return d3forum.posts.count();
    }

    getModule(name: string): D3ForumModuleData | null{
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return null;
        }
        return d3forum.module;
    }

    getCategories(name: string): D3ForumCategoryData[] {
        return this.getSubCategories(name, 0);
    }

    getSubCategories(name: string, parentCatId: number): D3ForumCategoryData[] {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return [];
        }
        const filter = {
            'pid': parentCatId
        };
        const result = d3forum.categories.chain().find(filter).sort(categorySort).data();
        return result;
    }

    getCategory(name: string, catId: number): D3ForumCategoryData | null {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return null;
        }
        const filter = {
            'cat_id': catId
        };
        const result = d3forum.categories.findOne(filter);
        return result;
    }

    getForums(name: string, catId: number): D3ForumForumData[] {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return [];
        }
        const filter = {
            'cat_id': catId
        };
        const result = d3forum.forums.chain().find(filter).sort(forumSort).data();
        return result;
    }

    getForum(name: string, forumId: number): D3ForumForumData | null {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return null;
        }
        const filter = {
            'forum_id': forumId
        };
        const result = d3forum.forums.findOne(filter);
        return result;
    }

    getTopics(name: string, forumId: number): D3ForumTopicData[] {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return [];
        }
        const filter = {
            'forum_id': forumId
        };
        const result = d3forum.topics.find(filter);
        return result;
    }

    getTopic(name: string, topicId: number): D3ForumTopicData | null {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return null;
        }
        const filter = {
            'topic_id': topicId
        };
        const result = d3forum.topics.findOne(filter);
        return result;
    }

    getPosts(name: string, topicId: number, order: D3ForumPostSortOrder): D3ForumPostData[] {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return [];
        }
        const filter = {
            'topic_id': topicId
        };
        const sorter = new D3ForumPostSorter(order);
        const result = d3forum.posts.chain().find(filter).sort(sorter.sort).data();
        return result;
    }

    getChildPosts(name: string, parentPostId: number): D3ForumPostData[] {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return [];
        }
        const filter = {
            'pid': parentPostId
        };
        const sorter = new D3ForumPostSorter(D3ForumPostSortOrder.TREE);
        const result = d3forum.posts.chain().find(filter).sort(sorter.sort).data();
        return result;
    }

    getPost(name: string, postId: number): D3ForumPostData | null {
        const d3forum = this.modules.get(name);
        if (typeof d3forum === 'undefined') {
            return null;
        }
        const filter = {
            'post_id': postId
        };
        const result = d3forum.posts.findOne(filter);
        return result;
    }
}

export default new D3ForumUtils(d3forumJson);