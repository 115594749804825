import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import D3ForumUtils, { D3ForumCategoryData, D3ForumForumData } from './D3ForumUtils';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    name: string;
    forumId: number;
}

export const ForumJump = (props: Props) => {
    const { lang, name, forumId } = props;
    const LABE_SUBMIT = Functions.mlang('[en]Jump to a forum[/en][ja]フォーラムジャンプ[/ja]', lang);
    const forumSelect = React.createRef<HTMLSelectElement>();
    const forumSelectOptions: JSX.Element[] = [];
    const forumSelectLoop = (categories: D3ForumCategoryData[]) => {
        categories.forEach((category) => {
            D3ForumUtils.getForums(name, category.cat_id).forEach((forum: D3ForumForumData) => {
                const title = Functions.mlang(category.cat_title + ' - ' + forum.forum_title, lang);
                const depth = category.cat_depth_in_tree !== 0 ? '--'.repeat(category.cat_depth_in_tree) + ' ' : '';
                forumSelectOptions.push(<option key={forum.forum_id} value={forum.forum_id}>{depth}{title}</option>);
            });
            forumSelectLoop(D3ForumUtils.getSubCategories(name, category.cat_id));
        });
    };
    forumSelectLoop(D3ForumUtils.getCategories(name));
    return (
        <form className="d3f_form" onSubmit={(e) => {
            e.preventDefault();
            if (forumSelect.current !== null) {
                const url = D3ForumUtils.getForumUrl(name, parseInt(forumSelect.current.value, 10));
                props.history.push(url);
            }
        }}>
            <select ref={forumSelect} defaultValue={String(forumId)}>
                {forumSelectOptions}
            </select>
            <input type="submit" value={LABE_SUBMIT} />
        </form>
    );
};

export default withRouter(ForumJump);