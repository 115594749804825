import moment from 'moment';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import D3ForumUtils, { D3ForumPostData } from './D3ForumUtils';
import PostIcon from './PostIcon';

interface Props {
    lang: MultiLang;
    name: string;
    posts: D3ForumPostData[];
    postId: number;
}

const postUrl = (name: string, postId: number, inTopic: boolean) => {
    return inTopic ? '#postId' + postId : D3ForumUtils.getPostUrl(name, postId);
}

const PostsTree = (props: Props) => {
    const { lang, name, posts, postId } = props;
    const inTopic = postId === 0;
    return (
        <div className="d3f_post_tree">
            <h2 className="head">{Functions.mlang('[en]Posts tree[/en][ja]投稿ツリー[/ja]', lang)}</h2>
            <ul className="d3f_eachbranch">
                {posts.map((post) => {
                    const style = {
                        paddingLeft: String(post.depth_in_tree * 10) + 'px',
                    }
                    const className = post.post_id === postId ? 'd3f_eachbranchitem d3f_currenttopic' : 'd3f_eachbranchitem';
                    const subject = <><PostIcon lang={lang} post={post} /> {Functions.mlang(post.subject, lang)}</>;
                    return (
                        <li key={post.post_id} className={className}>
                            <span style={style}>
                                {post.post_id === postId ? subject : <HashLink to={postUrl(name, post.post_id, inTopic)}>{subject}</HashLink>}
                                &nbsp;
                                ({post.uid !== 0 ? post.uid_uname : post.guest_name}, {moment(new Date(post.post_time * 1000)).format('YYYY/M/D H:mm')})
                                &nbsp;
                            </span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default PostsTree;