import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    name: string;
}

class D3ForumXoopsPathRedirect extends Component<Props> {

    getRedirectUrl(): string {
        const { name, location } = this.props;
        const pathname = location.pathname || '';
        const hash = location.hash || '';
        const query = new URLSearchParams(location.search);
        const search = new RegExp(`^/modules/${name}(?:/+(.*))?$`);
        const matches = pathname.match(search);
        if (matches === null) {
            return '';
        }
        const path = matches[1] || '';
        switch (path) {
            case '':
            case 'index.php': {
                const catId = query.get('cat_id');
                if (catId !== null && catId.match(/^\d+$/) !== null) {
                    return '/' + name + '/category/' + Functions.escape(catId);
                }
                const forumId = query.get('forum_id');
                if (forumId !== null && forumId.match(/^\d+$/) !== null) {
                    return '/' + name + '/forum/' + Functions.escape(forumId);
                }
                const topicId = query.get('topic_id');
                if (topicId !== null && topicId.match(/^\d+$/) !== null) {
                    const matches = hash.match(/^#post_id(\d+)$/);
                    return '/' + name + '/topic/' + Functions.escape(topicId) + (matches === null ? '' : '#postId' + matches[1]);
                }
                const postId = query.get('post_id');
                if (postId !== null && postId.match(/^\d+$/) !== null) {
                    return '/' + name + '/post/' + Functions.escape(postId);
                }
                const catIds = query.get('cat_ids');
                if (catIds !== null && catIds.match(/^\d+$/) !== null) {
                    return '/' + name + '/category/' + Functions.escape(catIds);
                }
                return '/' + name + '/';
            }
        }
        return '/' + name + '/' + path;
    }

    render() {
        const { lang } = this.props;
        const url = this.getRedirectUrl();
        if (url === '') {
            return <PageNotFound lang={lang} />;
        }
        return <Redirect to={url} />;
    }
}

export default withRouter(D3ForumXoopsPathRedirect);
