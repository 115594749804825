import moment from 'moment';
import React, { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import noAvatarImage from '../../common/assets/images/no_avatar.gif';
import UserRankStarImage from '../../common/lib/UserRankStarImage';
import XoopsCode from '../../common/lib/XoopsCode';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import D3ForumUtils, { D3ForumPostData } from './D3ForumUtils';
import PostIcon from './PostIcon';

interface Props {
    lang: MultiLang;
    name: string;
    post: D3ForumPostData;
    nextPostId: number;
    prevPostId: number;
    inTopic: boolean;
}

const postUrl = (name: string, postId: number, inTopic: boolean) => {
    return inTopic ? '#postId' + postId : D3ForumUtils.getPostUrl(name, postId);
}

const Post = (props: Props) => {
    const { lang, name, post, nextPostId, prevPostId, inTopic } = props;
    const depth = String(post.depth_in_tree * 5) + '%';
    const url = D3ForumUtils.getPostUrl(name, post.post_id)
    const subject = <><PostIcon lang={lang} post={post} /> {Functions.mlang(post.subject, lang)}</>;
    const children = D3ForumUtils.getChildPosts(name, post.post_id);
    const LABEL_PARENT = Functions.mlang('[en]Parent[/en][ja]親投稿[/ja]', lang);
    const LABEL_PREV = Functions.mlang('[en]Previous post[/en][ja]前の投稿[/ja]', lang);
    const LABEL_NEXT = Functions.mlang('[en]Next post[/en][ja]次の投稿[/ja]', lang);
    const LABEL_NO_CHILD = Functions.mlang('[en]No child[/en][ja]子投稿なし[/ja]', lang);
    const LABEL_CHILD = Functions.mlang('[en]Child[/en][ja]子投稿[/ja]', lang);
    return (
        <>
            <div className="itemHead">
                <img className="d3f_avatar" src={noAvatarImage} alt={post.uid_uname} />
                <h2 className=" itemTitle">
                    {inTopic ? <HashLink id={'postId' + post.post_id} to={url}>{subject}</HashLink> : subject}
                </h2>
                <span className="d3f_msgnum">msg# {post.unique_path.substring(1)}</span>
            </div>
            <div className="d3f_info">
                <dl className="d3f_head_tree_depth" style={{ width: depth }}>
                    <dt>depth:</dt>
                    <dd>{post.depth_in_tree}</dd>
                </dl>
                <div className="d3f_info_val">
                    {prevPostId === 0 ? LABEL_PREV : <HashLink to={postUrl(name, prevPostId, inTopic)}>{LABEL_PREV}</HashLink>}
                    &nbsp;-&nbsp;
                    {nextPostId === 0 ? LABEL_NEXT : <HashLink to={postUrl(name, nextPostId, inTopic)}>{LABEL_NEXT}</HashLink>}
                    &nbsp;|&nbsp;
                    {post.pid === 0 ? LABEL_PARENT : <HashLink to={postUrl(name, post.pid, inTopic)}>{LABEL_PARENT}</HashLink>}
                    &nbsp;-&nbsp;
                    {children.length === 0 ? LABEL_NO_CHILD : children.map((child: D3ForumPostData, idx: number) => {
                        const label = (idx === 0 ? LABEL_CHILD : '') + child.unique_path.substr(post.unique_path.length);
                        return (
                            <Fragment key={child.post_id}>
                                {idx > 0 && ' '}
                                <HashLink to={postUrl(name, child.post_id, inTopic)}>{label}</HashLink>
                            </Fragment>
                        );
                    })}
                    &nbsp;|&nbsp;
                    {Functions.mlang('[en]Posted on[/en][ja]投稿日時[/ja]', lang)} {moment(new Date(post.post_time * 1000)).format('YYYY/M/D H:m')}
                </div>
            </div>
            <div className="itemInfo" style={{ textAlign: 'left' }}>
                {post.uid === 0 ? Functions.mlang(post.guest_name, lang) :
                    <>
                        {post.uid_uname}
                        &nbsp;
                        <UserRankStarImage lang={lang} rank={post.uid_rank} posts={post.uid_posts} />
                        &nbsp;
                        {Functions.mlang('[en]Posts[/en][ja]投稿数[/ja]', lang)}: {post.uid_posts}
                    </>
                }
            </div>
            <div className="d3f_body">
                <XoopsCode lang={lang} text={post.post_text} dohtml={post.html === 1} dosmiley={post.smiley === 1} doxcode={post.xcode === 1} dobr={post.br === 1} />
            </div>
            <div className="itemFoot">
                <span className="d3f_vote">
                    {Functions.mlang('[en]Votes[/en][ja]投票数[/ja]', lang)}:{post.votes_count}
                    &nbsp;&nbsp;
                    {Functions.mlang('[en]Average[/en][ja]平均点[/ja]', lang)}:{(post.votes_count === 0 ? 0 : post.votes_sum / post.votes_count).toFixed(2)}
                </span>
            </div>
        </>
    );
}

export default Post;