import React from 'react';
import { MultiLang } from '../../config';
import imageBookCover from '../assets/images/book-cover.jpg';
import imageBookToc from '../assets/images/book-toc.jpg';
import imageGroupPhoto from '../assets/images/group-photo.jpg';

interface Props {
    lang: MultiLang;
}

const CerebrationOfDrItosNewBook = (props: Props) => {
    return (
        <p style={{ textAlign: "center" }}>
            <img src={imageBookCover} alt="" width="222" height="300" />
            <img src={imageBookToc} alt="" width="252" height="300" />
            <img src={imageGroupPhoto} alt="" width="480" height="229" />
        </p>
    );
}

export default CerebrationOfDrItosNewBook;
