import React from 'react';
import { MultiLang } from '../../config';

interface Props {
  lang: MultiLang;
}

const NewArticleAlert = (props: Props) => {
  return (
    <>
      <h3 id="2018-March">2018 March</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience and Biobehavioral Reviews Volume 86, March 2018, Pages 176-206</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Assessing cerebellar brain inhibition (CBI) via transcranial magnetic stimulation (TMS): A systematic review<br />
        Lara Fernandez, Brendan P. Major, Wei-Peng Teo, Linda K. Byrne, Peter G. Enticott<br />
        - <a href="https://doi.org/10.1016/j.neubiorev.2017.11.018" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neubiorev.2017.11.018</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Research Volume 128, March 2018, Page 63</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Corrigendum to Projections from the lowest lumbar and sacral-caudal segments to the cerebellar cortex in the rat: An anterograde tracing study [Neurosci. Res. 114 (January) (2017) 43-54]<br />
        Matsuo Matsushita<br />
        - <a href="https://doi.org/10.1016/j.neures.2017.12.001" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neures.2017.12.001</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, February 24, 2018 to March 02, 2018, Published: February 28, 2018</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Subclinical recurrent neck pain and its treatment impacts motor training-induced plasticity of the cerebellum and motor cortex<br />
        Julianne K. Baarb&eacute;, Paul Yielder, Heidi Haavik, Michael W. R. Holmes, Bernadette Ann Murphy<br />
        - <a href="https://doi.org/10.1371/journal.pone.0193413" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0193413</a>
      </p>
      <h3 id="2018-February">2018 February</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron, Volume 97, Issue 4, 21 February 2018, Pages 796-805.e5</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Retrograde Signaling from Progranulin to Sort1 Counteracts Synapse Elimination in the Developing Cerebellum<br />
        Naofumi Uesaka, Manabu Abe, Kohtarou Konno, Maya Yamazaki, Kazuto Sakoori, Takaki Watanabe, Tzu-Huei Kao, Takayasu Mikuni, Masahiko Watanabe, Kenji Sakimura, Masanobu Kano<br />
        - <a href="https://doi.org/10.1016/j.neuron.2018.01.018" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuron.2018.01.018</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, 20 February 2018</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Beta-gamma burst stimulations of the inferior olive induce high-frequency oscillations in the deep cerebellar nuclei<br />
        Julian Cheron, Guy Cheron<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13873/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13873/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Opinion in Neurobiology Volume 48, February 2018, Pages 146-152</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Local and long-range circuit elements for cerebellar function<br />
        Le Xiao, Peter Scheiffele<br />
        - <a href="https://doi.org/10.1016/j.conb.2017.12.016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.conb.2017.12.016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Aging Neurosci., 16 February 2018</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Increased Degradation Rates in the Components of the Mitochondrial Oxidative Phosphorylation Chain in the Cerebellum of Old Mice<br />
        Aurel Popa-Wagner, Raluca E. Sandu, Coman Cristin, Adriana Uzoni, Kevin A. Welle, Jennifer R. Hryhorenko, Sina Ghaemmaghami<br />
        - <a href="https://doi.org/10.3389/fnagi.2018.00032" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fnagi.2018.00032</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neural Circuits, 07 February 2018</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Anatomical Evidence for a Direct Projection from Purkinje Cells in the Mouse Cerebellar Vermis to Medial Parabrachial Nucleus<br />
        Mitsuhiro Hashimoto, Akihiro Yamanaka, Shigeki Kato, Manabu Tanifuji, Kazuto Kobayashi, Hiroyuki Yaginuma<br />
        - <a href="https://doi.org/10.3389/fncir.2018.00006" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fncir.2018.00006</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Physiol., 09 February 2018</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Circadian Clock Proteins and Melatonin Receptors in Neurons and Glia of the Sapajus apella Cerebellum<br />
        Leila Guissoni Campos, Isis Vieira, Isadora Robalinho, Giovanna Piffer, Joyce Vi&eacute;gas, Henrique Bosso, Rafael Bravos, Luciana Pinato<br />
        - <a href="https://doi.org/10.3389/fphys.2018.00005" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fphys.2018.00005</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. February 2018, Volume 17, Issue 1, Pages 1-3</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellum: from Development to Disease the 8th International Symposium of the Society for Research on the Cerebellum and Ataxias<br />
        Hassan MarzbanEmail, Mario Manto, Jean Mariani<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-018-0919-4?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-018-0919-4</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. February 2018, Volume 17, Issue 1, Pages 4-11</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Epidemiology of Cerebellar Diseases and Therapeutic APagesroaches<br />
        Michael S. Salman<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0885-2?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0885-2</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 12-16</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Ferdinando Rossi Memorial Lecture: Zones and Stripes Pattern Formation in the Cerebellum<br />
        Richard Hawkes<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0887-0?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0887-0</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 17-22</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Presynaptic Mechanisms Mediating Retrograde Semaphorin Signals for Climbing Fiber Synapse Elimination During Postnatal Cerebellar Development<br />
        Naofumi Uesaka, Masanobu Kano<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0888-z?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0888-z</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 23-27</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Protein Kinase C in the Cerebellum: Its Significance and Remaining Conundrums<br />
        Hirokazu Hirai<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0898-x?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0898-x</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 28-36</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Epigenetic Drivers in Pediatric Medulloblastoma<br />
        Martine F. Roussel, Jennifer L. Stripay<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0899-9?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0899-9</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 37-41</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Self-Organized Cerebellar Tissue from Human Pluripotent Stem Cells and Disease Modeling with Patient-Derived iPSCs<br />
        Keiko Muguruma<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0905-2?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0905-2</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 42-48</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Molecular Pathway Regulating Bergmann Glia and Folia Generation in the Cerebellum<br />
        Alan W. Leung, James Y. H. Li<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0904-3?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0904-3</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 49-55</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Crus I in the Rodent Cerebellum: Its Homology to Crus I and II in the Primate Cerebellum and Its Anatomical Uniqueness Among Neighboring Lobules<br />
        Izumi Sugihara<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0911-4?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0911-4</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 62-71</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Migration of Interneuron Precursors in the Nascent Cerebellar Cortex<br />
        Annika K. Wefers, Christian Haberlandt, Lachezar Surchev, Christian Steinh&auml;user, Ronald Jabs, Karl Schilling<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0900-7?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0900-7</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum February 2018, Volume 17, Issue 1, Pages 72-77</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Targeting the CACNA1A IRES as a Treatment for Spinocerebellar Ataxia Type 6<br />
        Parviz Daniel Hejazi Pastor, Xiaofei Du, Sarah Fazal, Andre N. Davies, Christopher M. Gomez<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-018-0917-6?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-018-0917-6</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, February 03, 2018 to February 09, 2018</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Correction: Mutational analysis of ITPR1 in a Taiwanese cohort with cerebellar ataxias<br />
        Cheng-Tsung Hsiao, Yo-Tsen Liu, Yi-Chu Liao, Ting-Yi Hsu, Yi-Chung Lee, Bing-Wen Soong<br />
        - <a href="https://doi.org/10.1371/journal.pone.0192866" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0192866</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurol., 05 February 2018</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Hypothesis: The Vestibular and Cerebellar Basis of the Mal de Debarquement Syndrome<br />
        Bernard Cohen, Sergei B. Yakushin and Catherine Cho<br />
        - <a href="https://doi.org/10.3389/fneur.2018.00028" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fneur.2018.00028</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 371, 10 February 2018, Pages 119-125</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Effect of Cerebellar Transcranial Direct Current Stimulation on A Throwing Task Depends on Individual Level of Task Performance<br />
        Nobuaki Mizuguchi, Takashi Katayama, Kazuyuki Kanosue<br />
        - <a href="https://doi.org/10.1016/j.neuroscience.2017.11.048" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuroscience.2017.11.048</a>
      </p>
      <h3 id="2018-January">2018 January</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology, Volume 596, Issue 2 15 January 2018 Pages 139-141</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Heading in the right direction: the importance of direction selectivity for cerebellar motor learning<br />
        Miranda A. Mathews, Fatema Mohammed Ali, Rajiv Wijesinghe and Aaron J. Camp<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP275441/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP275441/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 368, 1 January 2018, Pages 229-239</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Convergence of Primary Sensory Cortex and Cerebellar Nuclei Pathways in the Whisker System<br />
        Carmen B. Sch&auml;fer, Freek E. Hoebeek<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0306452217305134?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0306452217305134</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS BIOLOGY</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neurotransmitter-mediated activity spatially controls neuronal migration in the zebrafish cerebellum<br />
        Ulrike Theisen, Christian Hennig, Tobias Ring, Ralf Schnabel, Reinhard W. K&ouml;ster<br />
        - <a href="http://journals.plos.org/plosbiology/article?id=10.1371/journal.pbio.2002226" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosbiology/article?id=10.1371/journal.pbio.2002226</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Delineation of Subregions in the Early Postnatal Human Cerebellum for Design-Based Stereologic Studies<br />
        Anna Fichtl, Andreas Buettner, Patrick Hof, Christoph Schmitz, and Maren Kiessling<br />
        - <a href="https://www.frontiersin.org/articles/10.3389/fnana.2017.00134/full?utm_source=F-AAE&amp;utm_medium=EMLF&amp;utm_campaign=MRK_514068_55_Neuros_20180116_arts_A" target="_blank" rel="noopener noreferrer">https://www.frontiersin.org/articles/10.3389/fnana.2017.00134/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS GENETICS</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Correction: Alkaline Ceramidase 3 Deficiency Results in Purkinje Cell Degeneration and Cerebellar Ataxia Due to Dyshomeostasis of Sphingolipids in the Brain<br />
        Kai Wang, Ruijuan Xu, Jennifer Schrandt, Prithvi Shah, Yong Z. Gong, Chet Preston, Louis Wang, Jae Kyo Yi, Chih-Li Lin, Wei Sun, Demetri D. Spyropoulos, Soyoung Rhee, Mingsong Li, Jie Zhou, Shaoyu Ge, Guofeng Zhang, Ashley J. Snider, Yusuf A. Hannun, Lina M. Obeid, Cungui Mao<br />
        - <a href="http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007190" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007190</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Implicit learning deficit in children with Duchenne muscular dystrophy: Evidence for a cerebellar cognitive impairment?<br />
        Stefano Vicari, Giorgia Piccini, Eugenio Mercuri, Roberta Battini, Daniela Chieffo, Sara Bulgheroni, Chiara Pecini, Simona Lucibello, Sara Lenzi, Federica Moriconi, Marika Pane, Adele D&rsquo;Amico, Guja Astrea, Giovanni Baranello, Daria Riva, Giovanni Cioni, Paolo Alfieri<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0191164" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0191164</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research, Volume 1678, 1 January 2018, Pages 330-336</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Variation in MUTYH expression in Arabian horses with Cerebellar Abiotrophy<br />
        E.Y. Scott, K.D. Woolard, C.J. Finno, M.C.T. Penedo, J.D. Murray<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0006899317304924?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0006899317304924</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Experimental Brain Research January 2018, Volume 236, Issue 1, Pages 83-97</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Acute and repetitive fronto-cerebellar tDCS stimulation improves mood in non-depressed participants<br />
        Simon Newstead, Hayley Young, David Benton, Gabriela Jiga-Boy, Maria L. Andrade Sienz, R. M. Clement, Fr&eacute;d&eacute;ric Boy<br />
        - <a href="https://link.springer.com/article/10.1007/s00221-017-5109-y?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s00221-017-5109-y</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience 31 January 2018, 38 (5) 1277-1294</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Meis1 Coordinates Cerebellar Granule Cell Development by Regulating Pax6 Transcription, BMP Signaling and Atoh1 Degradation<br />
        Tomoo Owa, Shinichiro Taya, Satoshi Miyashita, Mariko Yamashita, Toma Adachi, Koyo Yamada, Miwa Yokoyama, Shogo Aida, Tomoki Nishioka, Yukiko U. Inoue, Ryo Goitsuka, Takuro Nakamura, Takayoshi Inoue, Kozo Kaibuchi and Mikio Hoshino<br />
        - <a href="https://doi.org/10.1523/JNEUROSCI.1545-17.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1523/JNEUROSCI.1545-17.2017</a>
      </p>
      <h3 id="2017-December">2017 December</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology, Volume 595, Issue 2415 December 2017, Pages 7477-7493</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Axonal GABAA receptors depolarize presynaptic terminals and facilitate transmitter release in cerebellar Purkinje cells<br />
        Javier Zorrilla de San Martin, Federico F. Trigo and Shin-ya Kawaguchi<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP275369/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP275369/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex, Volume 27, Issue 12, 1 December 2017, Pages 5652-5662</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Distinct Cerebellar Contributions to Cognitive-Perceptual Dynamics During Natural Viewing<br />
        Vinh Thai Nguyen, Saurabh Sonkusare, Jane Stadler, Xintao Hu, Michael Breakspear, Christine Cong Guo<br />
        - <a href="https://academic.oup.com/cercor/article-abstract/27/12/5652/2559299?redirectedFrom=fulltext" target="_blank" rel="noopener noreferrer">https://academic.oup.com/cercor/article-abstract/27/12/5652/2559299</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature Neuroscience Contents: December 2017 Volume 20 Number 12</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Altered cerebellar connectivity in autism and cerebellar-mediated rescue of autism-related behaviors in mice<br />
        Catherine J. Stoodley, Anila M. D'Mello, Jacob Ellegood, Vikram Jakkamsetti, Pei Liu, Mary Beth Nebel, Jennifer M. Gibson, Elyza Kelly, Fantao Meng, Christopher A. Cano, Juan M. Pascual, Stewart H. Mostofsky, Jason P. Lerch and Peter T. Tsai<br />
        - <a href="http://www.nature.com/articles/s41593-017-0004-1?WT.ec_id=NEURO-201711&amp;spMailingID=55466771&amp;spUserID=MTc2NjE4NTk1MAS2&amp;spJobID=1285244116&amp;spReportId=MTI4NTI0NDExNgS2" target="_blank" rel="noopener noreferrer">http://www.nature.com/articles/s41593-017-0004-1</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 877&ndash;879</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        From Mice to Men: TRPC3 in Cerebellar Ataxia<br />
        Esther B. E. Becker<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-015-0663-y/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-015-0663-y/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 880&ndash;912</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Arteries and Veins of the Cerebellum<br />
        Matthieu Delion, Mickael Dinomais and Philippe Mercier<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0828-3/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0828-3/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 913&ndash;922</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Viral Vector-Based Evaluation of Regulatory Regions in the Neuron-Specific Enolase (NSE) Promoter in Mouse Cerebellum In Vivo<br />
        Yoichiro Shinohara, Toshinori Ohtani, Ayumu Konno and Hirokazu Hirai<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0866-5/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0866-5/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 923&ndash;928</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Gray Matter Alterations in Huntington Disease: A Voxel-Based Morphometry Study<br />
        Paula C de Azevedo, Rachel P Guimar&atilde;es, Camila C Piccinin, Luiza G Piovesana, Lidiane S Campos, Juliana R Zuiani, Eliza M. Tamashiro, Giordanna Pinheiro, Augusto C Amato-Filho, Fernando Cendes, Iscia Lopes- Cendes and Anelyssa D&rsquo;Abreu<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0865-6/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0865-6/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 945&ndash;950</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Impairments in Walking Ability, Dexterity, and Cognitive Function in Multiple Sclerosis Are Associated with Different Regional Cerebellar Gray Matter Loss<br />
        Matthias Grothe, Martin Lotze, S&ouml;nke Langner and Alexander Dressel<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0871-8/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0871-8/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 964&ndash;972</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Contributions to Persistent Auditory Verbal Hallucinations in Patients with Schizophrenia<br />
        Maximilian Cierpka, Nadine D. Wolf, Katharina M. Kubera, Mike M. Schmitgen, Nenad Vasic, Karel Frasch and Robert Christian Wolf<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0874-5/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0874-5/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 973&ndash;978</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Permanent Cerebellar Degeneration After Acute Hyperthermia with Non-toxic Lithium Levels: a Case Report and Review of Literature<br />
        Fabian H. Rossi, Elisa Marie Rossi, Michael Hoffmann, Welwin Liu, Ramon Rodriguez Cruz, Natasha Antonovich, Arash Rezaei, Elizabeth Gonzalez, Maria Clara Franco, Alvaro Estevez and Florian Thomas<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0868-3/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0868-3/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, December 2017, Volume 16, Issue 5&ndash;6, pp 988&ndash;990</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Hypomagnesemia: a Treatable Cause of Ataxia with Cerebellar Edema<br />
        Idoia Rouco Axpe, Javier Almeida Velasco, Jose Guillermo Barreiro Garcia, Jose Manuel Urbizu Gallardo and Beatriz Mateos Go&ntilde;i<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0873-6/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0873-6/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience, Vol. 37, Issue 50 13 Dec 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Morphological Constraints on Cerebellar Granule Cell Combinatorial Diversity<br />
        Jesse I. Gilmer and Abigail L. Person<br />
        - <a href="http://www.jneurosci.org/content/37/50/12153?etoc=" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/50/12153</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 46, Issue 12, December 2017, Pages 2875&ndash;2884</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The effects of cerebellar transcranial direct current stimulation on static and dynamic postural stability in older individuals: a randomized double-blind sham-controlled study<br />
        Fatemeh Ehsani, Afshin Samaei, Maryam Zoghi, Rozita Hedayati and Shapour Jaberzadeh<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13731/full" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13731/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage, Volume 163, December 2017, Pages 177-182</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The cerebellum's contribution to beat interval discrimination<br />
        S. Paquette, S. Fujii, H.C. Li, G. Schlaug<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S1053811917307656?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S1053811917307656</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Pharmacology</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Prostaglandin E2 Impairs P2Y2/P2Y4 Receptor Signaling in Cerebellar Astrocytes via EP3 Receptors<br />
        Luc&iacute;a Paniagua-Herranz, Juan Carlos Gil-Redondo, Mar&iacute;a Jos&eacute; Queipo, Silvia Gonz&aacute;lez-Ramos, Lisardo Bosca, Raquel P&eacute;rez-Sen, Maria Teresa Miras-Portugal, and Esmerilda Delicado<br />
        - <a href="https://www.frontiersin.org/articles/10.3389/fphar.2017.00937/full?utm_source=F-AAE&amp;utm_medium=EMLF&amp;utm_campaign=MRK_503647_62_Pharma_20180104_arts_A" target="_blank" rel="noopener noreferrer">https://www.frontiersin.org/articles/10.3389/fphar.2017.00937/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Specific Temporal Distribution and Subcellular Localization of a Functional Vesicular Nucleotide Transporter (VNUT) in Cerebellar Granule Neurons<br />
        Aida Men&eacute;ndez, Juan Ignacio D&iacute;az, Felipe Ortega, Javier Gualix, Rosa Gomez-Villafuertes, and M. Teresa Miras-Portugal<br />
        - <a href="https://www.frontiersin.org/articles/10.3389/fphar.2017.00951/full?utm_source=F-AAE&amp;utm_medium=EMLF&amp;utm_campaign=MRK_503647_62_Pharma_20180104_arts_A" target="_blank" rel="noopener noreferrer">https://www.frontiersin.org/articles/10.3389/fphar.2017.00951/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Short-term succinic acid treatment mitigates cerebellar mitochondrial OXPHOS dysfunction, neurodegeneration and ataxia in a Purkinje-specific spinocerebellar ataxia type 1 (SCA1) mouse model<br />
        Austin Ferro, Emily Carbone, Jenny Zhang, Evan Marzouk, Monica Villegas, Asher Siegel, Donna Nguyen, Thomas Possidente, Jessilyn Hartman, Kailen Polley, Melissa A. Ingram, Georgia Berry, Thomas H. Reynolds, Bernard Possidente, Kimberley Frederick, Stephen Ives, Sarita Lagalwar<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0188425" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0188425</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 46, Issue 12, December 2017, Pages 2875-2884</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The effects of cerebellar transcranial direct current stimulation on static and dynamic postural stability in older individuals: a randomized double-blind sham-controlled study<br />
        Fatemeh Ehsani, Afshin Samaei, Maryam Zoghi, Rozita Hedayati, Shapour Jaberzadeh<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13731/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13731/abstract</a>
      </p>
      <h3 id="2017-November">2017 November</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex, Volume 27, Issue 11, 1 November 2017, Pages 5170&ndash;5184</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Asynchronous Development of Cerebellar, Cerebello-Cortical, and Cortico-Cortical Functional Networks in Infancy, Childhood, and Adulthood<br />
        Judy A Kipping, Ta Ahn Tuan, Marielle V Fortier, Anqi Qiu<br />
        - <a href="https://academic.oup.com/cercor/article-abstract/27/11/5170/3056481?redirectedFrom=fulltext" target="_blank" rel="noopener noreferrer">https://academic.oup.com/cercor/article-abstract/27/11/5170/3056481</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 660, 1 November 2017, Pages 135-139</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Roles of N-methyl-d-aspartate receptors during the sensory stimulation-evoked field potential responses in mouse cerebellar cortical molecular layer<br />
        Yin-Hua Xu, Guang-Jian Zhang, Jing-Tong Zhao, Chun-Ping Chu, Yu-Zi Li, De-Lai Qiu<br />
        - <a href="https://www.sciencedirect.com/science/article/abs/pii/S0304394017307723" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/abs/pii/S0304394017307723</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Lobular patterns of cerebellar resting- state connectivity in adults with Autism Spectrum Disorder<br />
        Giusy Olivito, Michela Lupo, Fiorenzo Laghi, Silvia Clausi, Roberto Baiocco, Mara Cercignani, Marco Bozzali and Maria Leggio<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13752/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13752/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Experimental Brain Research, November 2017, Volume 235, Issue 11, pp 3437&ndash;3448</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar compartments for the processing of kinematic and kinetic information related to hindlimb stepping<br />
        M. S. Valle, G. Bosco and R. E. Poppele<br />
        - <a href="https://link.springer.com/article/10.1007/s00221-017-5067-4/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s00221-017-5067-4/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology, Volume 595, Issue 21 Pages 6585 - 6789, 1 November 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Collateral impact: a dual role for climbing fibre collaterals to the cerebellar nuclei?<br />
        Jasmine Pickford and Richard Apps<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP275091/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP275091/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology, Volume 595, Issue 21 Pages 6585 - 6789, 1 November 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Synaptic excitation by climbing fibre collaterals in the cerebellar nuclei of juvenile and adult mice<br />
        Marion Najac and Indira M. Raman<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274598/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274598/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience, Vol. 37, Issue 47, 22 Nov 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Concerted Interneuron Activity in the Cerebellar Molecular Layer During Rhythmic Oromotor Behaviors<br />
        Guadalupe Astorga, Dongdong Li, Ludivine Therreau, Merouann Kassa, Alain Marty and Isabel Llano<br />
        - <a href="http://www.jneurosci.org/content/37/47/11455?etoc=" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/47/11455</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Mutational analysis of ITPR1 in a Taiwanese cohort with cerebellar ataxias<br />
        Cheng-Tsung Hsiao, Yo-Tsen Liu, Yi-Chu Liao, Ting-Yi Hsu, Yi-Chung Lee, Bing-Wen Soong<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0187503" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0187503</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Structural Covariance of Sensory Networks, the Cerebellum, and Amygdala in Autism Spectrum Disorder<br />
        Garrett Cardon, Susan Hepburn, and Donald Rojas<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pubmed/29230189" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pubmed/29230189</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Secretin Modulates the Postnatal Development of Mouse Cerebellar Cortex Via PKA- and ERK-dependent Pathways<br />
        LEI WANG, LI ZHANG, and Billy Chow<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pubmed/29249942" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pubmed/29249942</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Propofol Exposure in Early Life Induced Developmental Impairments in the Mouse Cerebellum<br />
        Rui Xiao, Dan Yu, Xin Li, Jing Huang, Sheng Jing, Xiaohang Bao, Tiande Yang, and Xiaotang Fan<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5715384/" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5715384/</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology, Volume 595, Issue 21, 1 November 2017, Pages 6589-6590</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Collateral impact: a dual role for climbing fibre collaterals to the cerebellar nuclei?<br />
        Jasmine Pickford, Richard APagess<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP275091/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP275091/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The journal of Physiology, Volume 595, Issue 21, 1 November 2017, Pages 6703-6718</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Synaptic excitation by climbing fibre collaterals in the cerebellar nuclei of juvenile and adult mice<br />
        Marion Najac, Indira M. Raman<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274598/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274598/abstract</a>
      </p>
      <h3 id="2017-October">2017 October</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Opinion in Neurobiology Volume 46, October 2017, Pages 58-67</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        New insights into olivo-cerebellar circuits for learning from a small training sample<br />
        Isao T Tokuda, Huu Hoang, Mitsuo Kawato<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0959438817300521?via%3Dihub" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0959438817300521</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Biological Cybernetics, Vol. 111, Issue 5</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Fuzzy neuronal model of motor control inspired by cerebellar pathways to online and gradually learn inverse biomechanical functions in the presence of delay<br />
        Marion Najac and Indira M. Raman<br />
        - <a href="https://link.springer.com/content/pdf/10.1007/s00422-017-0735-9?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/content/pdf/10.1007/s00422-017-0735-9</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Motor Deficits and Cerebellar Atrophy in Elovl5 Knock Out Mice<br />
        Eriola Hoxha, Rebecca Gabriele, Ilaria Balbo, Francesco Ravera, Linda Masante, Vanessa Zambelli, Cristian Albergo, Nico Mitro, Donatella Caruso, Eleonora Di Gregorio, Alfredo Brusco, Barbara Borroni, and Filippo Tempia<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pubmed/29163054" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pubmed/29163054</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Vermis and Midbrain Hypoplasia Upon Conditional Deletion of Chd7 from the Embryonic Mid-Hindbrain Region<br />
        Alex Donovan, Tian Yu, Jacob Ellegood, Kimberley Riegman, Christa de Geus, Conny van Ravenswaaij-Arts, Cathy Fernandes, Jason Lerch, and M. Albert Basson<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5632662/" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5632662/</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Human iPSC-Derived Cerebellar Neurons from a Patient with Ataxia-Telangiectasia Reveal Disrupted Gene Regulatory Networks<br />
        Samuel Nayler, Joseph Powell, Darya Vanichkina, Othmar Korn, Christine Wells, Refik Kanjhan, Jane Sun, Ryan Taft, Martin Lavin, and Ernst Wolvetang<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pubmed/29081736" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pubmed/29081736</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 46, Issue 8, October 2017, Pages 2339-2354</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Beta-band oscillations during passive listening to metronome sounds reflect improved timing representation after short-term musical training in healthy older adults<br />
        Takako Fujioka, Bernhard Ross<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13693/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13693/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience 4 October 2017, Volume 37, Issue 40, 9795-9798</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Cortex and Cerebellar Nuclei Are Concomitantly Activated during Eyeblink Conditioning: A 7T fMRI Study in Humans<br />
        Published By: Society for Neuroscience<br />
        - <a href="https://doi.org/10.1523/JNEUROSCI.2133-17.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1523/JNEUROSCI.2133-17.2017</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 659, 17 October 2017, Pages 69-74</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar pathology in childhood-onset vs. adult-onset essential tremor<br />
        Elan D. Louis, Sheng-Han Kuo, William J. Tate, Geoffrey C. Kelly, Phyllis L. Faust<br />
        - <a href="https://doi.org/10.1016/j.neulet.2017.08.072" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neulet.2017.08.072</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Experimental Brain Research October 2017, Volume 235, Issue 10, Pages 2971-2981</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Atrophic degeneration of cerebellum impairs both the reactive and the proactive control of movement in the stop signal paradigm<br />
        Giusy Olivito, Emiliano Brunamonti, Silvia Clausi, Pierpaolo Pani, Francesca R. Chiricozzi, Margherita Giamundo, Marco Molinari, Maria Leggio, Stefano Ferraina<br />
        - <a href="https://link.springer.com/article/10.1007/s00221-017-5027-z?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s00221-017-5027-z</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuropharmacology Volume 125, October 2017, Pages 166-180</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar perineuronal nets in cocaine-induced pavlovian memory: Site matters<br />
        Maria Carbo-Gas, Josep Moreno-Rius, Julian Guarque-Chabrera, Dolores Vazquez-Sanroman, Isis Gil-Miravet, Daniela Carulli, Freek Hoebeek, Chris De Zeeuw, Carla Sanchis-Segura, Marta Miquel<br />
        - <a href="https://doi.org/10.1016/j.neuropharm.2017.07.009" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuropharm.2017.07.009</a>
      </p>
      <h3 id="2017-September">2017 September</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Cell. Neurosci., 20 September 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Motor and Cerebellar Architectural Abnormalities during the Early Progression of Ataxia in a Mouse Model of SCA1 and How Early Prevention Leads to a Better Outcome Later in Life<br />
        Mohamed F. Ibrahim, Emmet M. Power, Kay Potapov, Ruth M. Empson<br />
        - <a href="https://doi.org/10.3389/fncel.2017.00292" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fncel.2017.00292</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neural Circuits, 20 September 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Purkinje Cells Generate Highly Correlated Spontaneous Slow-Rate Fluctuations<br />
        Ying Cao, Yu Liu, Dieter Jaeger, Detlef Heck<br />
        - <a href="https://doi.org/10.3389/fncir.2017.00067" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fncir.2017.00067</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 3, September 2017, Pages 1888-1902</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Climbing fibers predict movement kinematics and performance errors<br />
        Martha L. Streng, Laurentiu S. Popa and Timothy J. Ebner<br />
        - <a href="https://doi.org/10.1152/jn.00266.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00266.2017</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 3, September 2017, Pages 1622-1636</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The cerebellum does more than sensory prediction error-based learning in sensorimotor adaptation tasks<br />
        Peter A. Butcher, Richard B. Ivry, Sheng-Han Kuo, David Rydz, John W. Krakauer and Jordan A. Taylor<br />
        - <a href="https://doi.org/10.1152/jn.00451.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00451.2017</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature Neuroscience Volume 20, Issue 9, 26 September 2017, Pages 1319-1321</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Unexpected help to repair the cerebellum<br />
        Baptiste N Jaeger, Sebastian Jessberger<br />
        - <a href="http://links.ealert.nature.com/ctt?kn=27&amp;ms=NTUwMDkwNjkS1&amp;r=MTc2NjE4NTk1MAS2&amp;b=2&amp;j=MTI0Nzc2NDU1MAS2&amp;mt=1&amp;rt=0" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/nn.4640</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS, PUBLISHED: September 15, 2017 to September 21, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Spatiotemporal network coding of physiological mossy fiber inputs by the cerebellar granular layer<br />
        Shyam Kumar Sudhakar, Sungho Hong, Ivan Raikov, Rodrigo Publio, Claus Lang, Thomas Close, Daqing Guo, Mario Negrello, Erik De Schutter<br />
        - <a href="https://doi.org/10.1371/journal.pcbi.1005754" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pcbi.1005754</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience 20 September 2017, Volume 37, Issue 38, 9249-9258</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Impaired Feedforward Control and Enhanced Feedback Control of Speech in Patients with Cerebellar Degeneration<br />
        Benjamin Parrell, Zarinah Agnew, Srikantan Nagarajan, John Houde, Richard B. Ivry<br />
        - <a href="https://doi.org/10.1523/JNEUROSCI.3363-16.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1523/JNEUROSCI.3363-16.2017</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience 20 September 2017, Volume 37, Issue 38, 9101-9115</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Synergistic Toxicity of Polyglutamine-Expanded TATA-Binding Protein in Glia and Neuronal Cells: Therapeutic Implications for Spinocerebellar Ataxia 17<br />
        Yang Yang, Su Yang, Jifeng Guo, Yiting Cui, Beisha Tang, Xiao-Jiang Li, Shihua Li<br />
        - <a href="https://doi.org/10.1523/JNEUROSCI.0111-17.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1523/JNEUROSCI.0111-17.2017</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience 13 September 2017, Volume 37, Issue 37, 8876-8894</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        STIM1 Regulates Somatic Ca2+ Signals and Intrinsic Firing Properties of Cerebellar Purkinje Neurons<br />
        Changhyeon Ryu, Dong Cheol Jang, Dayoon Jung, Yong Gyu Kim, Hyun Geun Shim, Hyun-Hee Ryu, Yong-Seok Lee, David J. Linden, Paul F. Worley, Sang Jeong Kim<br />
        - <a href="https://doi.org/10.1523/JNEUROSCI.3973-16.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1523/JNEUROSCI.3973-16.2017</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 657, 14 September 2017, Pages 22-26</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Expression of the GluA2 subunit of glutamate receptors is required for the normal dendritic differentiation of cerebellar Purkinje cells<br />
        Masahiko Tanaka, Tomomi Senda, Naohide Hirashima<br />
        - <a href="https://doi.org/10.1016/j.neulet.2017.07.054" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neulet.2017.07.054</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 657, 14 September 2017, Pages 159-165</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Valproic acid exposure decreases the mRNA stability of Bcl-2 via up-regulating miR-34a in the cerebellum of rat<br />
        Xufang Dai, Yunhou Yin, Liyan Qin<br />
        - <a href="https://doi.org/10.1016/j.neulet.2017.08.018" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neulet.2017.08.018</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, September 02, 2017 to September 08, 2017, Published: September 8, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Alkylation induced cerebellar degeneration dependent on Aag and Parp1 does not occur via previously established cell death mechanisms<br />
        Carrie M. Margulies, Isaac Alexander Chaim, Aprotim Mazumder, June Criscione, Leona D. Samson<br />
        - <a href="https://doi.org/10.1371/journal.pone.0184619" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0184619</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The journal of Physiology, Volume 595, Issue 17, 1 September 2017, Pages 5945-5963</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        In vivo analysis of synaptic activity in cerebellar nuclei neurons unravels the efficacy of excitatory inputs<br />
        Yasmin Yarden-Rabinowitz, Yosef Yarom<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274115/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274115/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 360, 30 September 2017, Pages 230-239</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Syk kinases orchestrate cerebellar granule cell tangential migration<br />
        Aurelien Benon, Choua Ya, Laurent Martin, Chantal Watrin, Naura Chounlamountri, Iness Jaaoini, Je rome Honnorat, Ve ronique Pellier-Monnin, Nelly Noraz<br />
        - <a href="https://doi.org/10.1016/j.neuroscience.2017.07.057" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuroscience.2017.07.057</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex, Volume 27, Issue 9, 1 September 2017, Pages 4537-4548</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Human Cerebellar Sub-millimeter Diffusion Imaging Reveals the Motor and Non-motor Topography of the Dentate Nucleus<br />
        C.J. Steele A. Anwander P.-L. Bazin R. Trampel A. Schaefer R. Turner N. Ramnani A. Villringer<br />
        - <a href="https://doi.org/10.1093/cercor/bhw258" target="_blank" rel="noopener noreferrer">https://doi.org/10.1093/cercor/bhw258</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuro Report, September 6th, 2017 - Volume 28 - Issue 13</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Longitudinal analysis of motor symptoms and histopathology in woozy mice, a model of cerebellar ataxia<br />
        Hayashi Takemitsua, Onozato Tomoyab, Wanajo Isaoa, Hayashi Morimichib, Takeda Hirooa, Fujimori Yoshikazua<br />
        - <a href="http://journals.lww.com/neuroreport/Fulltext/2017/10010/Longitudinal_analysis_of_motor_symptoms_and.2.aspx" target="_blank" rel="noopener noreferrer">http://journals.lww.com/neuroreport/Fulltext/2017/10010/Longitudinal_analysis_of_motor_symptoms_and.2.aspx</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 358, 1 September 2017, Pages 181-189</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Contribution of the suPageslementary motor area and the cerebellum to the anticipatory postural adjustments and execution phases of human gait initiation<br />
        Ali&eacute;nor Richard, Ang&egrave;le Van Hamme, Xavier Drevelle, Jean-Louis Golmard, Sabine Meunier, Marie-Laure Welter<br />
        - <a href="https://doi.org/10.1016/j.neuroscience.2017.06.047" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuroscience.2017.06.047</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research, Volume 1670, 1 September 2017, Pages 32-43</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neuregulin-1 protects mouse cerebellum against oxidative stress and neuroinflammation<br />
        Junping Xu, Chengliang Hu, Shuangxi Chen, Huifan Shen, Qiong Jiang, Peizhi Huang, Weijiang Zhao<br />
        - <a href="https://doi.org/10.1016/j.brainres.2017.06.012" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.brainres.2017.06.012</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 46, Issue 5, September 2017, Pages 2108-2120</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Chronic pharmacological blockade of the Na+/Ca2+ exchanger modulates the growth and development of the Purkinje cell dendritic arbor in mouse cerebellar slice cultures<br />
        Pradeep Sherkhane, Josef P. Kapfhammer<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13649/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13649/abstract</a>
      </p>
      <h3 id="2017-August">2017 August</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology, Volume 595, Issue 15, 1 August 2017, Pages 5301&ndash;5326</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Mechanisms underlying vestibulo-cerebellar motor learning in mice depend on movement direction<br />
        Kai Voges, Bin Wu, Laura Post, Martijn Schonewille and Chris I. De Zeeuw <br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274346/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274346/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology, Volume 595, Issue 15, 1 August 2017, Pages 5245&ndash;5264</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Facilitation of mossy fibre-driven spiking in the cerebellar nuclei by the synchrony of inhibition<br />
        Yeechan Wu and Indira M. Raman<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274321/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274321/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature Neuroscience Volume 20, Issue 8, 14 August 2017, Pages 1361-1370</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar granule cell replenishment postinjury by adaptive reprogramming of Nestin progenitors<br />
        Alexandre Wojcinski, Andrew K Lawton, N Sumru Bayin, Zhimin Lao, Daniel N Stephen, Alexandra L Joyner<br />
        - <a href="http://links.ealert.nature.com/ctt?kn=88&amp;ms=NTUwMDkwNjkS1&amp;r=MTc2NjE4NTk1MAS2&amp;b=2&amp;j=MTI0Nzc2NDU1MAS2&amp;mt=1&amp;rt=0" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/nn.4621</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Mol. Neurosci., 31 August 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Distinct Activities of Tfap2A and Tfap2B in the Specification of GABAergic Interneurons in the Developing Cerebellum<br />
        Norliyana Zainolabidin, Sandhya Kamath, Ayesha Thanawalla, and Albert Chen<br />
        - <a href="https://doi.org/10.3389/fnmol.2017.00281" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fnmol.2017.00281</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurol., 29 August 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Impaired Cerebellum to Primary Motor Cortex Associative Plasticity in Parkinson's Disease and Spinocerebellar Ataxia Type 3<br />
        Ming-Kuei Lu, Jui-Cheng Chen, Chun-Ming Chen, Jeng-Ren Duann, Ulf Ziemann, Chon-Haw Tsai<br />
        - <a href="https://doi.org/10.3389/fneur.2017.00445" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fneur.2017.00445</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Cell. Neurosci., 29 August 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Atrophy and Changes in Cytokines Associated with the CACNA1A R583Q Mutation in a Russian Familial Hemiplegic Migraine Type 1 Family<br />
        Svetlana Khaiboullina, Elena Mendelevich, Leyla Shigapova, Elena Shagimardanova, Guzel Gazizova, Alexey Nikitin, Ekaterina Martynova, Yuriy Davidyuk, Enver Bogdanov, Oleg Gusev, Arn van den Maagdenberg, Rashid Giniatullin, Albert Rizvanov<br />
        - <a href="https://doi.org/10.3389/fncel.2017.00263" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fncel.2017.00263</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Cell. Neurosci., 22 August 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Mechanisms of Spontaneous Climbing Fiber Discharge-Evoked Pauses and Output Modulation of Cerebellar Purkinje Cell in Mice<br />
        De-Lai Qiu, Xian-Hua Jin, Hong-Wei Wang, Xin-Yuan Zhang, Chun-Ping Chu, Yuan-Zhe Jin, Song-Biao Cui<br />
        - <a href="https://doi.org/10.3389/fncel.2017.00247" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fncel.2017.00247</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 95, Issue 5, 30 August 2017, Pages 1103-1111.e4</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Inferior Olivary TMEM16B Mediates Cerebellar Motor Learning<br />
        Yang Zhang, Zhushan Zhang, Shaohua Xiao, Jason Tien, Son Le, Trieu Le, Lily Y. Jan, Huanghe Yang<br />
        - <a href="https://doi.org/10.1016/j.neuron.2017.08.010" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuron.2017.08.010</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 2, August 2017, Pages 655-665</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        No consistent effect of cerebellar transcranial direct current stimulation on visuomotor adaptation<br />
        Roya Jalali, R. Chris Miall, Joseph M. Galea<br />
        - <a href="https://doi.org/10.1152/jn.00896.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00896.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 2, August 2017, Pages 732-748</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar patients do not benefit from cerebellar or M1 transcranial direct current stimulation during force-field reaching adaptation<br />
        Thomas Hulst, Liane John, Michael Kuper, Jos N. van der Geest, Sophia L. Goricke, Opher Donchin, Dagmar Timmann<br />
        - <a href="https://doi.org/10.1152/jn.00808.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00808.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 2, August 2017, Pages 778-781</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Motor recovery beginning 23 years after ischemic stroke<br />
        Peter Soros, Robert Teasell, Daniel F. Hanley, J. David Spence<br />
        - <a href="https://doi.org/10.1152/jn.00868.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00868.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 2, August 2017, Pages 986-1001</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Responses of Purkinje cells in the oculomotor vermis of monkeys during smooth pursuit eye movements and saccades: comparison with floccular complex<br />
        Ramanujan T. Raghavan, Stephen G. Lisberger<br />
        - <a href="https://doi.org/10.1152/jn.00209.2017" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00209.2017</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology Volume 118, Issue 2, August 2017, Pages 693-702</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The cerebellum contributes to proprioception during motion<br />
        Heidi M. Weeks, Amanda S. Therrien, Amy J. Bastian<br />
        - <a href="https://doi.org/10.1152/jn.00417.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00417.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Biology, Volume 27, Issue 16, 21 August 2017, Pages 2442-2451.e3</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Role of the Cerebellum in Adaptation to Delayed Action Effects<br />
        Liyu Cao, Domenica Veniero, Gregor Thut, Joachim Gross<br />
        - <a href="https://doi.org/10.1016/j.cub.2017.06.074" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.cub.2017.06.074</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The journal of Physiology, Volume 595, Issue 16, 15 August 2017, Pages 5525-5544</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Functional expression of calcium-permeable canonical transient receptor potential 4-containing channels promotes migration of medulloblastoma cells<br />
        Wei-Chun Wei, Wan-Chen Huang, Yu-Ping Lin, Esther B. E. Becker, Olaf Ansorge, Veit Flockerzi, Daniele Conti, Giovanna Cenacchi and Maike D. Glitsch<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274659/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274659/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, August 05, 2017 to August 11, 2017, Published: August 7, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Comprehensive investigation of CASK mutations and other genetic etiologies in 41 patients with intellectual disability and microcephaly with pontine and cerebellar hypoplasia (MICPCH)<br />
        Shin Hayashi, Daniela Tiaki Uehara, Kousuke Tanimoto, Seiji Mizuno, Yasutsugu Chinen, Shinobu Fukumura, Jun-ichi Takanashi, Hitoshi Osaka, Nobuhiko Okamoto, Johji Inazawa<br />
        - <a href="https://doi.org/10.1371/journal.pone.0181791" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0181791</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, August 05, 2017 to August 11, 2017, Published: August 8, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Microstructural analyses of the posterior cerebellar lobules in relapsing-onset multiple sclerosis and their implication in cognitive impairment<br />
        Amandine Moroso, Aur&eacute;lie Ruet, Delphine Lamargue-Hamel, Fanny Munsch, Mathilde Deloire, Pierrick Coup&eacute;, Julie Charr&eacute;-Morin, Aurore Saubusse, Jean-Christophe Ouallet, Vincent Planche, Thomas Tourdias, Vincent Dousset, Bruno Brochet<br />
        - <a href="https://doi.org/10.1371/journal.pone.0182479" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0182479</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurol., 02 August 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Pure Endovascular Management of an Arteriovenous Malformation and an Aneurysm Both SuPageslied by Anterio-Inferior Cerebellar Artery: A Case Report and a Review of Literature<br />
        Hassan khayat, Fawaz Alshareef, Abdulrahman Alshamy, Abdulrahman Algain, Essam Alhejaili, Omar Alnabihi, Saeed Alzahrani, Ruediger Stendel<br />
        - <a href="https://doi.org/10.3389/fneur.2017.00382" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fneur.2017.00382</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurol., 04 August 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A GABAergic Dysfunction in the Olivary-Cerebellar-Brainstem Network May Cause Eye Oscillations and Body Tremor. II. Model Simulations of Saccadic Eye Oscillations<br />
        Lance Optican, Elena Pretegiani<br />
        - <a href="https://doi.org/10.3389/fneur.2017.00372" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fneur.2017.00372</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurosci., 03 August 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Impairment of Long-Term Plasticity of Cerebellar Purkinje Cells Eliminates the Effect of Anodal Direct Current Stimulation on Vestibulo-Ocular Reflex Habituation<br />
        Suman Das, Marcella Spoor, Tafadzwa Sibindi, Peter Holland, Martijn Schonewille, Chris De Zeeuw, Maarten Frens, Opher Donchin<br />
        - <a href="https://doi.org/10.3389/fnins.2017.00444" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fnins.2017.00444</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 156, 1 August 2017, Pages 190-198</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Network-targeted cerebellar transcranial magnetic stimulation improves attentional control<br />
        Michael Esterman, Michelle Thai, Hidefusa Okabe, Joseph DeGutis, Elyana Saad, Simon E. Laganiere, Mark A. Halko<br />
        - <a href="https://doi.org/10.1016/j.neuroimage.2017.05.011" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuroimage.2017.05.011</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The journal of Physiology, Volume 595, Issue 15, 1 August 2017, Pages 5245-5264</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Facilitation of mossy fibre-driven spiking in the cerebellar nuclei by the synchrony of inhibition<br />
        Yeechan Wu, Indira M. Raman<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274321/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274321/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The journal of Physiology, Volume 595, Issue 15, 1 August 2017, Pages 5301-5326</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Mechanisms underlying vestibulo-cerebellar motor learning in mice depend on movement direction<br />
        Kai Voges, Bin Wu, Laura Post, Martijn Schonewille, Chris I. De Zeeuw<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274346/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274346/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The journal of Physiology, Volume 595, Issue 15, 1 August 2017, Pages 5341-5357</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Heterogeneity of Purkinje cell simple spike-complex spike interactions: zebrin- and non-zebrin-related variations<br />
        Tianyu Tang, Jianqiang Xiao, Colleen Y. Suh, Amelia Burroughs, Nadia L. Cerminara, Linjia Jia, Sarah P. Marshall, Andrew K. Wise, Richard APagess, Izumi Sugihara, Eric J. Lang<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP274252/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP274252/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroReport: August 16th, 2017 - Volume 28 - Issue 12 - p 705-711</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Radiation-induced cerebellar - cerebral functional connectivity alterations in nasopharyngeal carcinoma patients<br />
        Ma Qiongmina, Zeng Ling-Lia, Qin Jiana, Luo Zhiguoa, Su Jianpoa, Wu Donglinb, Qiu Shijunb, Hu Dewena<br />
        - <a href="https://journals.lww.com/neuroreport/Abstract/2017/09010/Radiation_induced_cerebellar_cerebral_functional.1.aspx" target="_blank" rel="noopener noreferrer">https://journals.lww.com/neuroreport/Abstract/2017/09010/Radiation_induced_cerebellar_cerebral_functional.1.aspx</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 764-771</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        After Effects of Cerebellar Continuous Theta Burst Stimulation on Reflexive Saccades and Smooth Pursuit in Humans<br />
        Silvia Colnaghi, P. Colagiorgio, S. Ramat, E. D&rsquo;Angelo, G. Koch, M. Versino<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0852-y?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0852-y</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 786-791</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Pathology in Familial vs. Sporadic Essential Tremor<br />
        Elan D. Louis, Sheng-Han Kuo, Jie Wang, William J. Tate, Ming-Kai Pan, Geoffrey C. Kelly, Jesus Gutierrez, Etty P. Cortes, Jean-Paul G. Vonsattel, Phyllis L. Faust<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0853-x?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0853-x</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 792-796</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Spinal Cord Damage in Spinocerebellar Ataxia Type 1<br />
        Carlos Roberto Martins, Alberto Rolim Muro Martinez, Thiago Junqueira Ribeiro de Rezende, Lucas Melo Teixeira Branco, Jos&eacute; Luiz Pedroso, Orlando G. P. Barsottini, Iscia Lopes-Cendes, Marcondes C. Fran&ccedil;a<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0854-9?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0854-9</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum August 2017, Volume 16, Issue 4, Pages 797-801</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A Comparative Optical Coherence Tomography Study of Spinocerebellar Ataxia Types 3 and 10<br />
        Fernando Spina Tensini, Mario T. Sato, Naoye Shiokawa, Tetsuo Ashizawa, H&eacute;lio A. G. Teive<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0856-7?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0856-7</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 802-811</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Gap Junction Modulation of Low-Frequency Oscillations in the Cerebellar Granule Cell Layer<br />
        Jennifer Claire Robinson, C. Andrew Chapman, Richard Courtemanche<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0858-5?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0858-5</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 827-839</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Computational Theory Underlying Acute Vestibulo-ocular Reflex Motor Learning with Cerebellar Long-Term Depression and Long-Term Potentiation<br />
        Keiichiro Inagaki, Yutaka Hirata<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0857-6?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0857-6</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 852-858</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        NESSCA Validation and Responsiveness of Several Rating Scales in Spinocerebellar Ataxia Type 2<br />
        Thais L. Monte, Estela R. Reckziegel, Marina C. Augustin, Amanda S. P. Silva, Lucas D. Locks-Coelho, Orlando Barsottini, Jos&eacute; L. Pedroso, Fernando R. Vargas, Maria-Luiza Saraiva-Pereira, Vanessa Bielefeldt Leotti, Laura Bannach Jardim<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0855-8?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0855-8</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 859-867</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        What Do We Know About the Influence of the Cerebellum on Walking Ability? Promising Findings from Transcranial Alternating Current Stimulation<br />
        Antonino Naro, Demetrio Milardi, Alberto Cacciola, Margherita Russo, Francesca Sciarrone, Gianluca La Rosa, Alessia Bramanti, Placido Bramanti, Rocco Salvatore Calabr&ograve;<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0859-4?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0859-4</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 868-871</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Low-Titre GAD Antibody-Associated Late-Onset Cerebellar Ataxia with a Significant Clinical Response to Intravenous Immunoglobulin Treatment<br />
        Timotej Petrijan, Marija Menih<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0851-z?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0851-z</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, August 2017, Volume 16, Issue 4, Pages 872-875</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Transcranial Direct Current Stimulation (ctDCS) Impairs Balance Control in Healthy Individuals<br />
        &Aacute;guida Foerster, Lorena Melo, Marina Mello, Rebeca Castro, L&iacute;via Shirahige, S&eacute;rgio Rocha, K&aacute;tia Monte-Silva<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0863-8?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0863-8</a>
      </p>
      <h3 id="2017-July">2017 July</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 46, Issue 2, July 2017, Pages 1790&ndash;1804</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cx36, Cx43 and Cx45 in mouse and rat cerebellar cortex: species-specific expression, compensation in Cx36 null mice and co-localization in neurons vs. glia<br />
        J. I. Nagy and J. E. Rash<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13614/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13614/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurol., 25 July 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Malignant Cerebellar Edema Subsequent to Accidental Prescription Opioid Intoxication in Children<br />
        Daniel Duran, Robert Messina, Lauren Beslow, Julio Montejo, Jason Karimy, Charuta Furey, Alison Sheridan, Gordon Sze, Yanki Yarman, Michael Diluna, Kristopher Kahle<br />
        - <a href="https://doi.org/10.3389/fneur.2017.00362" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fneur.2017.00362</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Cell. Neurosci., 28 July 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Erythropoietin Protects Against Lipopolysaccharide-Induced Microgliosis and Abnormal Granule Cell Development in the Ovine Fetal Cerebellum<br />
        Annie Mcdougall, Nadia Hale, Sandra Rees, Richard Harding, Robert De Matteo, Stuart Hooper, Mary Tolcos<br />
        - <a href="https://doi.org/10.3389/fncel.2017.00224" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fncel.2017.00224</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 1, July 2017, Pages 267-279</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Recreational concentrations of alcohol enhance synaptic inhibition of cerebellar unipolar brush cells via pre- and postsynaptic mechanisms<br />
        Ben D. Richardson, David J. Rossi<br />
        - <a href="https://doi.org/10.1152/jn.00963.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00963.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 1, July 2017, Pages 471-485</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Temporal integration and 1/f power scaling in a circuit model of cerebellar interneurons<br />
        Reinoud Maex, Boris Gutkin<br />
        - <a href="https://doi.org/10.1152/jn.00789.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00789.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, Volume 118, Issue 1, July 2017, Pages 234-242</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Musculoskeletal geometry accounts for aPagesarent extrinsic representation of paw position in dorsal spinocerebellar tract<br />
        Raeed H. Chowdhury, Matthew C. Tresch, Lee E. Miller<br />
        - <a href="https://doi.org/10.1152/jn.00695.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00695.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology Volume 118, Issue 1, July 2017, Pages 254-266</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Parallel processing of internal and external feedback in the spinocerebellar system of primates<br />
        Oren Cohen, Ran Harel, Tim D. Aumann, Zvi Israel, Yifat Prut<br />
        - <a href="https://doi.org/10.1152/jn.00825.2016" target="_blank" rel="noopener noreferrer">https://doi.org/10.1152/jn.00825.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neuroanat., 21 July 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Temporal Contribution of the Gbx2 Lineage to Cerebellar Neurons<br />
        Nellwyn Hagan, Juliana Guarente, Debra Ellisor, Mark Zervas<br />
        - <a href="https://doi.org/10.3389/fnana.2017.00050" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fnana.2017.00050</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 46, Issue 2, July 2017, Pages 1790-1804</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cx36, Cx43 and Cx45 in mouse and rat cerebellar cortex: species-specific expression, compensation in Cx36 null mice and co-localization in neurons vs. glia<br />
        J. I. Nagy J. E. Rash<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13614/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13614/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, July 08, 2017 to July 14, 2017, Published: July 10, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neuronal correlates of cognitive function in patients with childhood cerebellar tumor lesions<br />
        Johanna L. Reichert, Monika Chocholous, Ulrike Leiss, Thomas Pletschko, Gregor Kasprian, Julia Furtner, Kathrin Kollndorfer, Jacqueline Krajnik, Irene Slavc, Daniela Prayer, Thomas Czech, Veronika Sch&ouml;pf, Christian Dorfer<br />
        - <a href="https://doi.org/10.1371/journal.pone.0180200" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0180200</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, July 08, 2017 to July 14, 2017, Published: July 10, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Bilateral effects of unilateral cerebellar lesions as detected by voxel based morphometry and diffusion imaging<br />
        Giusy Olivito, Michael Dayan, Valentina Battistoni, Silvia Clausi, Mara Cercignani, Marco Molinari, Maria Leggio, Marco Bozzali<br />
        - <a href="https://doi.org/10.1371/journal.pone.0180439" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0180439</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, July 01, 2017 to July 07, 2017, Published: July 7, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Can cerebellar and brainstem a Pagesarent diffusion coefficient (ADC) values predict neuromotor outcome in term neonates with hypoxic-ischemic encephalopathy (HIE) treated with hypothermia?<br />
        Gemma Arca-D&iacute;az, Thomas J. Re, Marie Drottar, Carmen Rosa Fortuno, Katyucia De Macedo-Rodrigues, Kiho Im, Josep Figueras-Aloy, Patricia Ellen Grant<br />
        - <a href="https://doi.org/10.1371/journal.pone.0178510" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0178510</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE, July 01, 2017 to July 07, 2017, Published: July 7, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar anodal tDCS increases implicit learning when strategic re-aiming is suPagesressed in sensorimotor adaptation<br />
        Li-Ann Leow , Welber Marinovic, Stephan Riek, Timothy J. Carroll<br />
        - <a href="https://doi.org/10.1371/journal.pone.0179977" target="_blank" rel="noopener noreferrer">https://doi.org/10.1371/journal.pone.0179977</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron, Volume 95, Issue 1, 5 July 2017, Pages 51-62.e4</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Selective Optogenetic Control of Purkinje Cells in Monkey Cerebellum<br />
        Yasmine El-Shamayleh, Yoshiko Kojima, Robijanto Soetedjo, Gregory D. Horwitz<br />
        - <a href="https://doi.org/10.1016/j.neuron.2017.06.002" target="_blank" rel="noopener noreferrer">https://doi.org/10.1016/j.neuron.2017.06.002</a>
      </p>
      <h3 id="2017-June">2017 June</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar ataxia and intrathecal baclofen therapy: Focus on patients&acute; experiences<br />
        Shala Ghaderi Berntsson, Anne-Marie Landtblom, Gullvi Flensner<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0180054" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0180054</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Using c-kit to genetically target cerebellar molecular layer interneurons in adult mice<br />
        Samantha B. Amat, Matthew J. M. Rowan, Michael A. Gaffield, Audrey Bonnan, Chikako Kikuchi, Hiroki Taniguchi, Jason M. Christie<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0179347" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0179347</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience, Vol. 37, Issue 26, 28 Jun 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Right Lateral Cerebellum Represents Linguistic Predictability<br />
        Elise Lesage, Peter C. Hansen, and R. Chris Miall<br />
        - <a href="http://www.jneurosci.org/content/37/26/6231?etoc=" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/26/6231</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature Neuroscience, July 2017, Volume 20, Number 7, pp 943 - 950</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A cerebellum-like circuit in the auditory system cancels responses to self-generated sounds<br />
        Shobhit Singla, Conor Dempsey, Richard Warren, Armen G Enikolopov and Nathaniel B Sawtell<br />
        - <a href="https://www.nature.com/articles/nn.4567?WT.ec_id=NEURO-201707&amp;spMailingID=54372727&amp;spUserID=MTc2NjE4NTk1MAS2&amp;spJobID=1184669487&amp;spReportId=MTE4NDY2OTQ4NwS2" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/nn.4567</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 45, Issue 12, June 2017, Pages 1538&ndash;1552</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The control of eye movements by the cerebellar nuclei: polysynaptic projections from the fastigial, interpositus posterior and dentate nuclei to lateral rectus motoneurons in primates<br />
        Vincent Prevosto, Werner Graf and Gabriella Ugolini<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13546/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13546/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 45, Issue 12, June 2017, Pages 1623&ndash;1633</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Probabilistic vs. deterministic fiber tracking and the influence of different seed regions to delineate cerebellar-thalamic fibers in deep brain stimulation<br />
        Juergen R. Schlaier, Anton L. Beer, Rupert Faltermeier, Claudia Fellner, Kathrin Steib, Max Lange, Mark W. Greenlee, Alexander T. Brawanski and Judith M. Anthofer<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13575/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13575/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS COMPUTATIONAL BIOLOGY</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Robust transmission of rate coding in the inhibitory Purkinje cell to cerebellar nuclei pathway in awake mice<br />
        Samira Abbasi, Amber E. Hudson, Selva K. Maran, Ying Cao, Ataollah Abbasi, Detlef H. Heck, Dieter Jaeger<br />
        - <a href="http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005578" target="_blank" rel="noopener noreferrer">http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005578</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Online Version of Record published before inclusion in an issue</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Eye movements, sensorimotor adaptation and cerebellar-dependent learning in autism: toward potential biomarkers and subphenotypes<br />
        Edward G. Freedman and John J. Foxe<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13625/abstract?campaign=wolacceptedarticle" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13625/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Biology, Volume 27, Issue 11, 5 June 2017, Pages R415-R418</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Granule Cells: Dense, Rich and Evolving Representations<br />
        Aleksandra Badura, Chris I. De Zeeuw<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0960982217304189?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0960982217304189</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience, Vol. 37, Issue 23, 7 Jun 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Long-Term Depression of Intrinsic Excitability Accompanied by Synaptic Depression in Cerebellar Purkinje Cells<br />
        Hyun Geun Shim, Dong Cheol Jang, Jaegeon Lee, Geehoon Chung, Sukchan Lee, Yong Gyu Kim, Da Eun Jeon and Sang Jeong Kim<br />
        - <a href="http://www.jneurosci.org/content/37/23/5659?etoc=" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/23/5659</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Proceedings of the National Academy of Sciences, 6 June 2017; Vol. 114, No. 23</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Learned response sequences in cerebellar Purkinje cells<br />
        Dan-Anders Jirenhed, Anders Rasmussen, Fredrik Johansson and Germund Hesslow<br />
        - <a href="http://www.pnas.org/content/114/23/6127?etoc=" target="_blank" rel="noopener noreferrer">http://www.pnas.org/content/114/23/6127</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience, Volume 354, 23 June 2017, Pages 30-42</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Wls expression in the rhombic lip orchestrates the embryonic development of the mouse cerebellum<br />
        Joanna Yeung, Daniel Goldowitz<br />
        - <a href="https://www.sciencedirect.com/science/article/abs/pii/S0306452217302695" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/abs/pii/S0306452217302695</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 623-628</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Differential Pattern of Cerebellar Atrophy in Tremor-Predominant and Akinetic/Rigidity-Predominant Parkinson&rsquo;s Disease<br />
        Camila Callegari Piccinin, Lidiane Soares Campos, Rachel Paes Guimar&atilde;es, Luiza Gonzaga Piovesana, Maria Cristina Arci dos Santos, Paula Christina Azevedo, Brunno Machado Campos, Thiago Junqueira Ribeiro de Rezende, Augusto Amato-Filho, Fernando Cendes and Anelyssa D&rsquo;Abreu<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0834-5/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0834-5/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 629-637</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Progression of Gait Ataxia in Patients with Degenerative Cerebellar Disorders: a 4-Year Follow-Up Study<br />
        Mariano Serrao, Giorgia Chini, Carlo Casali, Carmela Conte, Martina Rinaldi, Alberto Ranavolo, Christian Marcotulli, Luca Leonardi, Gaia Fragiotta, Fabiano Bini, Gianluca Coppola and Francesco Pierelli<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0837-2/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0837-2/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 638-647</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        No Medium-Term Spinocerebellar Input Plasticity in Deep Cerebellar Nuclear Neurons In Vivo?<br />
        Hannes Mogensen, Fredrik Bengtsson and Henrik J&ouml;rntell<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0839-0/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0839-0/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 648-655</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Pathways in Mouse Model of Purkinje Cell Degeneration Detected by High-Angular Resolution Diffusion Imaging Tractography<br />
        Yuri Kanamaru, Jianxue Li, Natalie Stewart, Richard L. Sidman and Emi Takahashi<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0842-5/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0842-5/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 673-682</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Neural Circuits Involving Executive Control Network Predict Response to Group Cognitive Behavior Therapy in Social Anxiety Disorder<br />
        MinlanYuan, Yajing Meng, Yan Zhang, Xiaojing Nie, Zhengjia Ren, Hongru Zhu, Yuchen Li, Su Lui, Qiyong Gong, Changjian Qiu and Wei Zhang<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0845-x/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0845-x/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 691-694</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellum from J. E. Purkyně up to Contemporary Research<br />
        Franti&scaron;ek Vožeh<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0835-4/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0835-4/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 695-741</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Targeting the Cerebellum by Noninvasive Neurostimulation: a Review<br />
        Kim van Dun, Florian Bodranghien, Mario Manto and Peter Mari&euml;n<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0840-7/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0840-7/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 742-745</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Fast Progression of Cerebellar Atrophy in PLA2G6-Associated Infantile Neuronal Axonal Dystrophy<br />
        Mario Mascalchi, Francesco Mari, Beatrice Berti, Emanuele Bartolini, Matteo Lenge, Andrea Bianchi, Laura Antonucci, Filippo M. Santorelli, Barbara Garavaglia and Renzo Guerrini<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0843-z/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0843-z/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 746-750</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Selective Transgenic Expression of Mutant Ubiquitin in Purkinje Cell Stripes in the Cerebellum<br />
        Bert M. Verheijen, Romina J. G. Gentier, Denise J. H. P. Hermes, Fred W. van Leeuwen and David A. Hopkins<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0838-1/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0838-1/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 751-752</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Commentary on the Paper &ldquo;Progression of Gait Ataxia in Patients with Degenerative Cerebellar Disorders: a 4-Year Follow-Up Study<br />
        Mariano Serrao<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-016-0841-6/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-016-0841-6/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum, June 2017, Volume 16, Issue 3, pp 753-755</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Known and Missing Links Between the Cerebellum, Basal Ganglia, and Cerebral Cortex<br />
        Alberto Cacciola, Demetrio Milardi, Paolo Livrea, Paolo Flace, Giuseppe Anastasi and Angelo Quartarone<br />
        - <a href="https://link.springer.com/article/10.1007/s12311-017-0850-0/fulltext.html?wt_mc=alerts.TOCjournals" target="_blank" rel="noopener noreferrer">https://link.springer.com/article/10.1007/s12311-017-0850-0/fulltext.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Learning &amp; Memory, June 2017; Vol. 24, No. 6</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Memory consolidation within the central amygdala is not necessary for modulation of cerebellar learning<br />
        Adam B. Steinmetz, Ka H. Ng, and John H. Freeman<br />
        - <a href="http://learnmem.cshlp.org/content/24/6/225.abstract?etoc" target="_blank" rel="noopener noreferrer">http://learnmem.cshlp.org/content/24/6/225.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Activation of the CREB/c-Fos Pathway during Long-Term Synaptic Plasticity in the Cerebellum Granular Layer<br />
        Daniela Gandolfi, Egidio D&lsquo;Angelo, Silvia Cerri, Jonathan Mapelli, Mariarosa Polimeni, Simona Tritto, Marie Therese Fuzzati-Armentero, Albertino Bigiani, Fabio Blandini, and Lisa Mapelli<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5487453/" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5487453/</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurol., 22 June 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Decompression in Chiari Malformation: Clinical, Ocular Motor, Cerebellar, and Vestibular Outcome<br />
        Nicolina Goldschagg, Katharina Feil, Franziska Ihl, Siegbert Krafczyk, Mathias Kunz, Joerg Tonn, Michael StruPages, Aurelia Peraud<br />
        - <a href="https://doi.org/10.3389/fneur.2017.00292" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fneur.2017.00292</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Front. Neurol., 28 June 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Dysfunction in Multiple Sclerosis<br />
        Alastair Wilkins<br />
        - <a href="https://doi.org/10.3389/fneur.2017.00312" target="_blank" rel="noopener noreferrer">https://doi.org/10.3389/fneur.2017.00312</a>
      </p>
      <h3 id="2017-May">2017 May</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Story time turbocharger? Child engagement during shared reading and cerebellar activation and connectivity in preschool-age children listening to stories<br />
        John S. Hutton, Kieran Phelan, Tzipi Horowitz-Kraus, Jonathan Dudley, Mekibib Altaye, Thomas DeWitt, Scott K. Holland<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0177398" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0177398</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Ectopically expressed Slc34a2a sense- antisense transcripts cause a cerebellar phenotype in zebrafish embryos depending on RNA complementarity and Dicer<br />
        Monica J. Piatek, Victoria Henderson, Amy Fearn, Bill Chaudhry, Andreas Werner<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0178219" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0178219</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Unmasking adrenoleukodystrophy in a cohort of cerebellar ataxia<br />
        Ying-Hao Chen, Yi-Chung Lee, Yu-Shuen Tsai, Yuh-Cherng Guo, Cheng-Tsung Hsiao, Pei-Chien Tsai, Jin-An Huang, Yi-Chu Liao, Bing-Wen Soong<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0177296" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0177296</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience, Vol. 37, Issue 21, 24 May 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Allometric Analysis Detects Brain Size- Independent Effects of Sex and Sex Chromosome Complement on Human Cerebellar Organization<br />
        Clasen, Deanna Greenstein, Jay N. Giedd, Jonathan D. Blumenthal, Jason P. Lerch, M. Mallar Chakravarty, and Armin Raznahan<br />
        - <a href="http://www.jneurosci.org/content/37/21/5221?etoc=" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/21/5221</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage, Volume 152, 15 May 2017, Pages 207-220</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Dopamine depletion impairs gait automaticity by altering cortico-striatal and cerebellar processing in Parkinson's disease<br />
        Moran Gilat, Peter T. Bell, Kaylena A. Ehgoetz Martens, Matthew J. Georgiades, Julie M. Hall, Courtney C. Walton, Simon J.G. Lewis, James M. Shine<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S1053811917301842?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S1053811917301842</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Biology, Volume 27, Issue 9, 8 May 2017, Pages 1288-1302</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Sensorimotor Representations in Cerebellar Granule Cells in Larval Zebrafish Are Dense, Spatially Organized, and Non-temporally Patterned<br />
        Laura D. Knogler, Daniil A. Markov, Elena I. Dragomir, Vilim &Scaron;tih, Ruben Portugues<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0960982217303275?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0960982217303275</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS GENETICS</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Hypothermia-induced dystonia and abnormal cerebellar activity in a mouse model with a single disease-mutation in the sodium-potassium pump<br />
        Toke Jost Isaksen, Lieke Kros, Natascia Vedovato, Thomas Helles&oslash;e Holm, Ariel Vitenzon, David Christopher Gadsby, Kamran Khodakhah, Karin Lykke- Hartmann<br />
        - <a href="http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1006763" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1006763</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neuroscience, Vol. 37, Issue 18, 3 May 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Movement Rate Is Encoded and Influenced by Widespread, Coherent Activity of Cerebellar Molecular Layer Interneurons <br />
        Michael A. Gaffield and Jason M. Christie<br />
        - <a href="http://www.jneurosci.org/content/37/18/4751?etoc=" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/18/4751</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Trends in Neurosciences, Volume 40, Issue 5, May 2017, Pages 309-323</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Sleeping Cerebellum<br />
        Cathrin B. Canto, Yoshiyuki Onuki, Bastiaan Bruinsma, Ysbrand D. van der Werf, Chris I. De Zeeuw<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0166223617300309?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0166223617300309</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 45, Issue 9, May 2017 Pages 1177-1185</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Online and offline effects of cerebellar transcranial direct current stimulation on motor learning in healthy older adults: a randomized double-blind sham-controlled study<br />
        Afshin Samaei, Fatemeh Ehsani, Maryam Zoghi, Mohaddese Hafez Yosephi and Shapour Jaberzadeh<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13559/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13559/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuropharmacology, Volume 118, 15 May 2017, Pages 79-89</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Monoaminergic modulation of GABAergic transmission onto cerebellar globular cells<br />
        Moritoshi Hirono, Soichi Nagao, Yuchio Yanagawa, Shiro Konishi<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0028390817300941?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0028390817300941</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature Neuroscience: May 2017, Volume 20, Number 5, pp 633 - 634</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar granule cells expand their talents<br />
        Matthew I Becker and Abigail L Person<br />
        - <a href="https://www.nature.com/articles/nn.4552?WT.ec_id=NEURO-201705&amp;spMailingID=53925999&amp;spUserID=MTc2NjE4NTk1MAS2&amp;spJobID=1144231889&amp;spReportId=MTE0NDIzMTg4OQS2" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/nn.4552</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature Neuroscience: May 2017, Volume 20, Number 5, pp 727 - 734</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar granule cells acquire a widespread predictive feedback signal during motor learning<br />
        Andrea Giovannucci, Aleksandra Badura, Ben Deverett, Farzaneh Najafi, Talmo D Pereira, Zhenyu Gao, Ilker Ozden, Alexander D Kloth, Eftychios Pnevmatikakis, Liam Paninski, Chris I De Zeeuw, Javier F Medina and Samuel S-H Wang<br />
        - <a href="https://www.nature.com/articles/nn.4531?WT.ec_id=NEURO-201705&amp;spMailingID=53925999&amp;spUserID=MTc2NjE4NTk1MAS2&amp;spJobID=1144231889&amp;spReportId=MTE0NDIzMTg4OQS2" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/nn.4531</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature Reviews Neuroscience, Volume 18, Number 5, May 2017, pp 263</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellum: The little learning brain<br />
        Springer<br />
        - <a href="https://www.nature.com/articles/nrn.2017.47?WT.ec_id=NRN-201705&amp;spMailingID=53872736&amp;spUserID=MTc2ODA0NDgwNQS2&amp;spJobID=1142936534&amp;spReportId=MTE0MjkzNjUzNAS2" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/nrn.2017.47</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Maternal Different Degrees of Iodine Deficiency during Pregnant and Lactation Impair the Development of Cerebellar Pinceau in Offspring<br />
        Jing Dong, Heling Song, Yuan Wang, Min Li, Ye Yu, Yi Wang, and Jie Chen<br />
        - <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5446996/" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5446996/</a>
      </p>
      <h3 id="2017-April">2017 April</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar volume as imaging outcome in progressive multiple sclerosis<br />
        Matilde Inglese, Maria Petracca, Enricomaria Mormina, Anat Achiron, Rebecca Straus-Farber, Shmuel Miron, Michelle Fabian, Stephen Krieger, Aaron Miller, Fred Lublin, Maria Pia Sormani<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0176519" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0176519</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Vertebral artery terminating in posterior inferior cerebellar artery: A normal variation with clinical significance<br />
        I-Wen Liu, Bo-Lin Ho, Chien-Fu Chen, Ke Han, Chung-Jung Lin, Wen-Yung Sheng, Han-Hwa Hu, A-Ching Chao<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0175264" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0175264</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Biology, Volume 27, Issue 8, 24 April 2017, Pages 1249-1250</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Rapid Evolution of the Cerebellum in Humans and Other Great Apes<br />
        Robert A. Barton, Chris Venditti<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0960982217303597?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0960982217303597</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology, April 2017; Vol. 117, No. 4, Pages 1474-1482</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Role of the cerebellum in high stages of motor planning hierarchy<br />
        Robert A. Barton, Chris Venditti<br />
        - <a href="http://www.physiology.org/doi/abs/10.1152/jn.00771.2016?etoc=&amp;" target="_blank" rel="noopener noreferrer">http://www.physiology.org/doi/abs/10.1152/jn.00771.2016</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage, Volume 150, 15 April 2017, Pages 112-118</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Activity and connectivity of the cerebellum in trigeminal nociception<br />
        Jan Mehnert, Laura Schulte, Dagmar Timmann, Arne May<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S1053811917301271?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S1053811917301271</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature, volume 544, pages 96&ndash; 100 (06 April 2017)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar granule cells encode the expectation of reward<br />
        Mark J. Wagner, Tony Hyun Kim, Joan Savall, Mark J. Schnitzer and Liqun Luo<br />
        - <a href="https://www.nature.com/articles/nature21726?WT.ec_id=NATURE-20170406&amp;spMailingID=53784341&amp;spUserID=MjA1NjQ3MjA5NQS2&amp;spJobID=1140784154&amp;spReportId=MTE0MDc4NDE1NAS2" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/nature21726</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience, Volume 45, Issue 7, April 2017, Pages 932&ndash;939</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Dorsomedial prefrontal cortex and cerebellar contribution to in-group attitudes: a transcranial magnetic stimulation study<br />
        Lucile Gamond, Chiara Ferrari, Stefania La Rocca and Zaira Cattaneo<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13529/abstract?campaign=woletoc" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13529/abstract</a>
      </p>
      <h3 id="2017-March">2017 March</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex, Volume 27, Issue 3, March 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        When one is Enough: Impaired Multisensory Integration in Cerebellar Agenesis<br />
        L. Ronconi L. Casartelli S. Carna M. Molteni F. Arrigoni R. Borgatti<br />
        - <a href="https://academic.oup.com/cercor/article/27/3/2041/3056322" target="_blank" rel="noopener noreferrer">https://academic.oup.com/cercor/article/27/3/2041/3056322</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS COMPUTATIONAL BIOLOGY</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Dysfunctions of the basal ganglia- cerebellar-thalamo-cortical system produce motor tics in Tourette syndrome<br />
        Daniele Caligiore, Francesco Mannella, Michael A. Arbib, Gianluca Baldassarre<br />
        - <a href="http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005395" target="_blank" rel="noopener noreferrer">http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005395</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuropharmacology, Volume 115, 15 March 2017, Pages 51-59</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Expression of the K+/Cl－ cotransporter, KCC2, in cerebellar Purkinje cells is regulated by group-I metabotropic glutamate receptors<br />
        Serena Notartomaso, Giada Mascio, Pamela Scarselli, Katiuscia Martinello, Sergio Fucile, Roberto Gradini, Valeria Bruno, Giuseppe Battaglia, Ferdinando Nicoletti<br />
        - <a href="https://www.sciencedirect.com/science/article/pii/S0028390816303288?_rdoc=1&amp;_fmt=high&amp;_origin=gateway&amp;_docanchor=&amp;md5=b8429449ccfc9c30159a5f9aeaa92ffb&amp;dgcid=raven_sd_via_email" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0028390816303288</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience 29 March 2017; Vol. 37, No. 13</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Roles in Self-Timing for Sub- and Supra-Second Intervals<br />
        Shogo Ohmae, Jun Kunimatsu, and Masaki Tanaka<br />
        - <a href="http://www.jneurosci.org/content/37/13/3511" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/13/3511</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 348, Pages 1-346, 21 April 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Further studies of the effects of aging on arginine metabolites in the rat vestibular nucleus and cerebellum<br />
        P. Liu, N. Gupta, Y. Jing, N.D. Collie, H. Zhang, P.F. Smith<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452217301161" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452217301161</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology March 1, 2017; Vol. 117, No. 3</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The inion response revisited: evidence for a possible cerebellar contribution to vestibular-evoked potentials produced by air-conducted sound stimulation<br />
        Neil P. M. Todd, Sendhil Govender, James G. Colebatch<br />
        - <a href="http://jn.physiology.org/content/117/3/1000" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/117/3/1000</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE March 18, 2017 to March 24, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Different subregional metabolism patterns in patients with cerebellar ataxia by 18F-fluorodeoxyglucose positron emission tomography<br />
        Minyoung Oh, Jae Seung Kim, Jungsu S. Oh, Chong Sik Lee, Sun Ju Chung<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0173275" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0173275</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature contents: 23 March 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar granule cells encode the expectation of reward<br />
        Mark J. Wagner, Tony Hyun Kim, Joan Savall&nbsp; et al.<br />
        - <a href="http://www.nature.com/nature/journal/vaop/ncurrent/full/nature21726.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/nature/journal/vaop/ncurrent/full/nature21726.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 16 Number 2</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/16/2" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/16/2</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 595, Issue 6 Pages 1847 - 2202, 15 March 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        N-Cadherin, a novel and rapidly remodelling site involved in vasoregulation of small cerebral arteries<br />
        Zhe Sun, Min Li, Zhaohui Li, Michael A. Hill and Gerald A. Meininger<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272995/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272995/abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Gaze-evoked nystagmus induced by alcohol intoxication<br />
        Fausto Romano, Alexander A. Tarnutzer, Dominik Straumann, Stefano Ramat and Giovanni Bertolini<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP273204/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP273204/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Online and offline effects of cerebellar transcranial direct current stimulation on motor learning in healthy older adults: A randomized double-blind sham-controlled study<br />
        Afshin Samaei, Fatemeh Ehsani, Maryam Zoghi, Mohaddese Hafez Yosephi and Shapour Jaberzadeh<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13559/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13559/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 15 March 2017, 37(11)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Hebbian Spike-Timing Dependent Plasticity at the Cerebellar Input Stage<br />
        Martina Sgritta, Francesca Locatelli, Teresa Soda, Francesca Prestori, and Egidio Ugo D'Angelo<br />
        - <a href="http://www.jneurosci.org/content/37/11/2809" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/11/2809</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE March 04, 2017 to March 10, 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The brain-specific RasGEF very-KIND is required for normal dendritic growth in cerebellar granule cells and proper motor coordination<br />
        Kanehiro Hayashi, Asako Furuya, Yuriko Sakamaki, Takumi Akagi, Yo Shinoda, Tetsushi Sadakata, Tsutomu Hashikawa, Kazuki Shimizu, Haruka Minami, Yoshitake Sano, Manabu Nakayama, Teiichi Furuichi<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0173175" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0173175</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 346, Pages 1-450, 27 March 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Facilitation of temporal prediction by electrical stimulation to the primate cerebellar nuclei<br />
        Akiko Uematsu, Shogo Ohmae, Masaki Tanaka<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452217300362" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452217300362</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 7 March 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Ethanol Modulates the Spontaneous Complex Spike Waveform of Cerebellar Purkinje Cells Recorded in vivo in Mice<br />
        Guang-Jian Zhang, Mao-Cheng Wu, Jin-Di Shi, Yin-Hua Xu, Chun-Ping Chu, Song-Biao Cui, and De-Lai Qiu<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2017.00043/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2017.00043/full</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroanatomy<br />
        Disynaptic Subthalamic Input to the Posterior Cerebellum in Rat<br />
        Saad Jwair, Patrice Coulon, and Tom J. H. Ruigrok<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2017.00013/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2017.00013/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Control of Eye Movements by the Cerebellar Nuclei: Polysynaptic Projections from the Fastigial, Interpositus Posterior and Dentate nuclei to Lateral Rectus Motoneurons in Primates<br />
        Vincent Prevosto, Werner Graf and Gabriella Ugolini<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13546/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13546/abstract</a>
      </p>
      <h3 id="2017-February">2017 February</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 28 February 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Crossed Cerebellar Atrophy of the Lateral Cerebellar Nucleus in an Endothelin-1-Induced, Rodent Model of Ischemic Stroke<br />
        Hugh H. Chan, Jessica L. Cooperrider, Hyun-Joo Park, Connor A. Wathen, John T. Gale, Kenneth B. Baker, and Andre G. Machado<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnagi.2017.00010/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnagi.2017.00010/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Thursday, 16 February 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Molecular Neuroscience<br />
        A model of induction of cerebellar Long-Term Depression including RKIP inactivation of Raf and MEK<br />
        Iain Hepburn, Anant Jain, Himanshu Gangal, Yukio Yamamoto, Keiko Tanaka-Yamamoto, and Erik De Schutter<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnmol.2017.00019/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnmol.2017.00019/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 595, Issue 4 Pages 1001 - 1420, 15 February 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Active integration of glutamatergic input to the inferior olive generates bidirectional postsynaptic potentials<br />
        Derek L. F. Garden, Arianna Rinaldi and Matthew F. Nolan<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP273424/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP273424/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 345, Pages 1-314, 14 March 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar contribution to higher and lower order rule learning and cognitive flexibility in mice<br />
        P.E. Dickson, J. Cairns, D. Goldowitz, G. Mittleman<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452216300197" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452216300197</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Friday, 10 February 2017</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Psychiatry<br />
        Practice and Learning: Spatiotemporal Differences in Thalamo-Cortical-Cerebellar Networks Engagement across Learning Phases in Schizophrenia<br />
        Michele Korostil, Gary Remington, and Anthony Randal McIntosh<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fpsyt.2016.00212/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fpsyt.2016.00212/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 8 February 2017, 37(6)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar tDCS Modulates Neural Circuits during Semantic Prediction: A Combined tDCS-fMRI Study<br />
        Anila M. D'Mello, Peter E. Turkeltaub, and Catherine J. Stoodley<br />
        - <a href="http://www.jneurosci.org/content/37/6/1604" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/6/1604</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1657, Pages 1-384, 15 February 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Purkinje cells die by apoptosis in the shaker mutant rat<br />
        Nour S. Erekat<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899316308460" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899316308460</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 6 February 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Cerebellar tDCS Effects on Conditioned Eyeblinks using Different Electrode Placements and Stimulation Protocols<br />
        Linda Beyer, Giorgi Batsikadze, Dagmar Timmann, and Marcus Gerwig<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2017.00023/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2017.00023/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 595, Issue 3 Pages 599 - 999, 1 February 2017</em></strong>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The pledge, the turn, and the prestige of transient cerebellar alterations in SCA6<br />
        Lauren N. Miterko and Roy V. Sillitoe<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP273301/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP273301/abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Transient cerebellar alterations during development prior to obvious motor phenotype in a mouse model of spinocerebellar ataxia type 6<br />
        Sriram Jayabal, Lovisa Ljungberg and Alanna J. Watt<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP273184/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP273184/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Dorsomedial prefrontal cortex and cerebellar contribution to in-group attitudes: a TMS study<br />
        Lucile Gamond, Chiara Ferrari, Stefania La Rocca and Zaira Cattaneo<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13529/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13529/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 1 February 2017, 37(5)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Granular Layer Neurons Control Cerebellar Neurovascular Coupling Through an NMDA Receptor/NO-Dependent System<br />
        Lisa Mapelli, Giuseppe Gagliano, Teresa Soda, Umberto Laforenza, Francesco Moccia, and Egidio U. D'Angelo<br />
        - <a href="http://www.jneurosci.org/content/37/5/1340.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/5/1340.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Experimental Brain Research. Volume 235 Number 2</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar tDCS does not improve performance in probabilistic classification learning<br />
        N. Seyed Majidi, M. C. Verhage, O. Donchin, P. Holland, M. A. Frens &amp; J. N. van der Geest<br />
        - <a href="http://link.springer.com/article/10.1007/s00221-016-4800-8" target="_blank" rel="noopener noreferrer">http://link.springer.com/article/10.1007/s00221-016-4800-8</a>
      </p>
      <h3 id="2016-January">2017 January</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 31 January 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Altered Hippocampo-Cerebello-Cortical Circuit in Schizophrenia by a Spatiotemporal Consistency and Causal Connectivity Analysis<br />
        Xi Chen, Yuchao Jiang, Lin Chen, Hui He, Li Dong, Changyue Hou, Mingjun Duan, Mi Yang, Dezhong Yao, and Cheng Luo<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnins.2017.00025/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnins.2017.00025/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 16 Number 1</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/16/1/page/1" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/16/1/page/1</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 25 January 2017, 37(4)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Role of Neuroligins in the Cerebellum Highlights the Diversity of Synapse-Specifying Molecules<br />
        Jessica Messier<br />
        - <a href="http://www.jneurosci.org/content/37/4/739" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/4/739</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 639, Pages 1-214, 3 February 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Analysis of gene expression in Ca2+-dependent activator protein for secretion 2 (Cadps2) knockout cerebellum using GeneChip and KEGG pathways<br />
        Tetsushi Sadakata, Yo Shinoda, Yasuki Ishizaki, Teiichi Furuichi<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016310163" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016310163</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Insulin/IGF-1 signaling in diabetic rats: Effects of exercise training<br />
        Mariana Eiras Borges, Alessandra Mussi Ribeiro, Jos&eacute; Rodrigo Pauli, Luciana Mendon&ccedil;a Arantes, Eliete Luciano, Leandro Pereira de Moura, Jos&eacute; Alexandre Curiacos de Almeida Leme, Alessandra Medeiros, Nat&aacute;lia Oliveira Bertolini, Clarice Yoshiko Sibuya, Ricardo Jos&eacute; Gomes<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016310072" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016310072</a>&nbsp;</p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 23 January 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar tDCS: A Novel Approach to Augment Language Treatment Post-stroke<br />
        Rajani Sebastian, Sadhvi Saxena, Kyrana Tsapkini, Andreia V. Faria, Charltien Long, Amy Wright, Cameron Davis, Donna C. Tippett, Antonios P. Mourdoukoutas, Marom Bikson, Pablo Celnik, and Argye E. Hillis<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00695/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00695/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Research Volume 114, Pages 1-70, January 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Projections from the lowest lumbar and sacral-caudal segments to the cerebellar cortex in the rat: An anterograde tracing study<br />
        Matsuo Matsushita<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0168010216301754" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0168010216301754</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 11 January 2017, 37(2)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Formation of Long-Term Locomotor Memories Is Associated with Functional Connectivity Changes in the Cerebellar&ndash;Thalamic&ndash;Cortical Network<br />
        Firas Mawase, Simona Bar-Haim, and Lior Shmuelof<br />
        - <a href="http://www.jneurosci.org/content/37/2/349.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/37/2/349.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 10 January 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        BNN27, a 17-Spiroepoxy Steroid Derivative, Interacts With and Activates p75 Neurotrophin Receptor, Rescuing Cerebellar Granule Neurons from Apoptosis<br />
        Iosif Pediaditakis, Alexandra Kourgiantaki, Kyriakos C. Prousis, Constantinos Potamitis, Kleanthis P. Xanthopoulos, Maria Zervou, Theodora Calogeropoulou, Ioannis Charalampopoulos, and Achille Gravanis<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fphar.2016.00512/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fphar.2016.00512/full</a>
      </p>
      <h3 id="2016-December">2016 December</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 595, Issue 1 Pages 1 - 413, 1 January 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        New old drug(s) for spinocerebellar ataxias<br />
        Visou Ady and Alanna J. Watt<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP273149/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP273149/abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar physiology: links between microcircuitry properties and sensorimotor functions<br />
        Henrik J&ouml;rntell<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272769/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272769/abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Progressive impairment of cerebellar mGluR signalling and its therapeutic potential for cerebellar ataxia in spinocerebellar ataxia type 1 model mice<br />
        Anton N. Shuvaev, Nobutake Hosoi, Yamato Sato, Dai Yanagihara and Hirokazu Hirai<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272950/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272950/abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The dynamic relationship between cerebellar Purkinje cell simple spikes and the spikelet number of complex spikes<br />
        Amelia Burroughs, Andrew K. Wise, Jianqiang Xiao, Conor Houghton, Tianyu Tang, Colleen Y. Suh, Eric J. Lang, Richard Apps and Nadia L. Cerminara<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272259/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272259/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NATURE NEUROSCIENCE January 2017 Volume 20 Number 1, pp 1 - 125</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A neuronal PI(3,4,5)P3-dependent program of oligodendrocyte precursor recruitment and myelination<br />
        Sandra Goebbels et al.<br />
        - <a href="http://www.nature.com/neuro/journal/v20/n1/full/nn.4425.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/neuro/journal/v20/n1/full/nn.4425.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 144, Part B, Pages 255-314, January 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Tell me twice: A multi-study analysis of the functional connectivity between the cerebrum and cerebellum after repeated trait information<br />
        Frank Van Overwalle, Elien Heleven, Ning Ma, Peter Mari&euml;n<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811916304311" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811916304311</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature 541, 87&ndash;91 (05 January 2017)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        XRCC1 mutation is associated with PARP1 hyperactivation and cerebellar ataxia<br />
        Nicolas C. Hoch, Hana Hanzlikova, Stuart L. Rulten&nbsp; et al.<br />
        - <a href="http://www.nature.com/nature/journal/v541/n7635/full/nature20790.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/nature/journal/v541/n7635/full/nature20790.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 340, Pages 1-562, 6 January 2017</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Repeated prenatal exposure to valproic acid results in cerebellar hypoplasia and ataxia&nbsp;&nbsp;&nbsp; Original Research Article<br />
        Stacey L. Main, Randy J. Kulesza<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452216305929" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452216305929</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 92, Issue 5, Pages 931-1148, 7 December 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Timing Is Right for Cerebellar Learning<br />
        Conor Dempsey, Nathaniel B. Sawtell<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627316309072" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627316309072</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 6 December 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Repetitive TMS on Left Cerebellum Affects Impulsivity in Borderline Personality Disorder: A Pilot Study<br />
        Giulia Zelda De Vidovich, Riccardo Muffatti, Jessica Monaco, Nicoletta Caramia, Davide Broglia, Edgardo Caverzasi, Francesco Barale, and Egidio D&rsquo;Angelo<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00582/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00582/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE November 26, 2016 to December 02, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellum Transcriptome of Mice Bred for High Voluntary Activity Offers Insights into Locomotor Control and Reward-Dependent Behaviors<br />
        Kelsey Caetano-Anoll&eacute;s, Justin S. Rhodes, Theodore Garland Jr, Sam D. Perez, Alvaro G. Hernandez, Bruce R. Southey, Sandra L. Rodriguez-Zas<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0167095" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0167095</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Thursday, 1 December 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cellular and Subcellular Localization of the RGS7/G&beta;5/R7BP Complex in the Cerebellar Cortex<br />
        Carolina Aguado, Cesare Orlandi, Ana Fajardo-Serrano, Mercedes Gil-Minguez, Kirill A. Martemyanov, and Rafael Luj&aacute;n<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00114/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00114/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 30 November 2016, 36(48)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Shank2 Regulates Excitatory Synapse Density, Motor Coordination, and Specific Repetitive and Anxiety-Like Behaviors<br />
        Seungmin Ha, Dongwon Lee, Yi Sul Cho, Changuk Chung, Ye-Eun Yoo, Jihye Kim, Jiseok Lee, Woohyun Kim, Hyosang Kim, Yong Chul Bae, Keiko Tanaka-Yamamoto, and Eunjoon Kim<br />
        - <a href="http://www.jneurosci.org/content/36/48/12129.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/48/12129.abstract</a>
      </p>
      <h3 id="2016-November">2016 November</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology November 1, 2016; Vol. 116, No. 5</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar tDCS dissociates the timing of perceptual decisions from perceptual change in speech<br />
        Daniel R. Lametti, Leonie Oostwoud Wijdenes, James Bonaiuto, Sven Bestmann, and John C. Rothwell<br />
        - <a href="http://jn.physiology.org/content/116/5/2023.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/5/2023.abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Responses of cerebellar Purkinje cells during fictive optomotor behavior in larval zebrafish<br />
        Karina Scalise, Takashi Shimizu, Masahiko Hibi, and Nathaniel B. Sawtell<br />
        - <a href="http://jn.physiology.org/content/116/5/2067.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/5/2067.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Opinion in Neurobiology Volume 41, Pages 1-190, December 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A comparative approach to cerebellar function: insights from electrosensory systems&nbsp;&nbsp;&nbsp; Review Article<br />
        Richard Warren, Nathaniel B Sawtell<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0959438816300976" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0959438816300976</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE November 12, 2016 to November 18, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Whole-Cell Properties of Cerebellar Nuclei Neurons In Vivo<br />
        Cathrin B. Canto, Laurens Witter, Chris I. De Zeeuw<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0165887" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0165887</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Philosophical Transactions of the Royal Society B: Biological Sciences January 5, 2017; Vol. 372, No. 1711</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The impact of cerebellar transcranial direct current stimulation (tDCS) on learning fine-motor sequences<br />
        Renee E. Shimizu, Allan D. Wu, Jasmine K. Samra, and Barbara J. Knowlton<br />
        - <a href="http://rstb.royalsocietypublishing.org/content/372/1711/20160050" target="_blank" rel="noopener noreferrer">http://rstb.royalsocietypublishing.org/content/372/1711/20160050</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 339, Pages 1-678, 17 December 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Dysregulation of the endocannabinoid signaling system in the cerebellum and brainstem in a transgenic mouse model of spinocerebellar ataxia type-3<br />
        Carmen Rodr&iacute;guez-Cueto, Mariluz Hern&aacute;ndez-G&aacute;lvez, Cecilia J. Hillard, Patricia Maciel, Luis Garc&iacute;a-Garc&iacute;a, Sara Valdeolivas, Miguel A. Pozo, Jos&eacute; A. Ramos, Mar&iacute;a G&oacute;mez-Ruiz, Javier Fern&aacute;ndez-Ruiz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452216305012" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452216305012</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 15 Number 6</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/15/6/page/1" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/15/6/page/1</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Experimental Brain Research December 2016, Volume 234, Issue 12, pp 3689&ndash;3697</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The direction of the postural response to a vestibular perturbation is mediated by the cerebellar vermis<br />
        Chris K. Lam, Craig D. Tokuno, W. Richard Staines &amp; Leah R. Bent<br />
        - <a href="http://link.springer.com/article/10.1007/s00221-016-4766-6" target="_blank" rel="noopener noreferrer">http://link.springer.com/article/10.1007/s00221-016-4766-6</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 8 November 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Increased Motor-Impairing Effects of the Neuroactive Steroid Pregnanolone in Mice with Targeted Inactivation of the GABAA Receptor &gamma;2 Subunit in the Cerebellum<br />
        Elli Lepp&auml;, Anni-Maija Linden, Maria I. Aller, Peer Wulff, Olga Vekovischeva, Bernhard Luscher, Hartmut L&uuml;ddens, William Wisden, and Esa R. Korpi<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fphar.2016.00403/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fphar.2016.00403/full</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Pedunculopontine Tegmental Nucleus as a Motor and Cognitive Interface between the Cerebellum and Basal Ganglia<br />
        Fumika Mori, Ken-ichi Okada, Taishin Nomura, and Yasushi Kobayashi<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00109/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00109/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE October 29, 2016 to November 04, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Fusion of Human Fetal Mesenchymal Stem Cells with &ldquo;Degenerating&rdquo; Cerebellar Neurons in Spinocerebellar Ataxia Type 1 Model Mice<br />
        Fathul Huda, Yiping Fan, Mamiko Suzuki, Ayumu Konno, Yasunori Matsuzaki, Nobutaka Takahashi, Jerry K. Y. Chan, Hirokazu Hirai<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0164202" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0164202</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Friday, 4 November 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Transient Developmental Purkinje Cell Axonal Torpedoes in Healthy and Ataxic Mouse Cerebellum<br />
        Lovisa Ljungberg, Daneck Lang-Ouellette, Angela Yang, Sriram Jayabal, Sabrina Quilez, and Alanna J. Watt<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2016.00248/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2016.00248/full</a>
      </p>
      <h3 id="2016-October">2016 October</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology October 1, 2016; Vol. 116, No. 4</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The inferior olive is essential for long-term maintenance of a simple motor skill<br />
        Xiang Yang Chen, Yu Wang, Yi Chen, Lu Chen, and Jonathan R. Wolpaw<br />
        - <a href="http://jn.physiology.org/content/116/4/1946.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/4/1946.abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Contribution of intravestibular sensory conflict to motion sickness and dizziness in migraine disorders<br />
        Joanne Wang and Richard F. Lewis<br />
        - <a href="http://jn.physiology.org/content/116/4/1586.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/4/1586.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Research Volume 112, Pages 1-70, November 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Differential effects of primary motor cortex and cerebellar transcranial direct current stimulation on motor learning in healthy individuals: A randomized double-blind sham-controlled study<br />
        F. Ehsani, A.H. Bakhtiary, S. Jaberzadeh, A. Talimkhani, A. Hajihasani<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0168010216300864" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0168010216300864</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 337, Pages 1-370, 19 November 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Memory deficits, gait ataxia and neuronal loss in the hippocampus and cerebellum in mice that are heterozygous for Pur-alpha<br />
        Mary F. Barbe, Jessica J. Krueger, Regina Loomis, Jessica Otte, Jennifer Gordon<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452216304560" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452216304560</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Wednesday, 19 October 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar and Spinal Direct Current Stimulation in Children: Computational Modeling of the Induced Electric Field<br />
        Serena Fiocchi, Paolo Ravazzani, Alberto Priori, and Marta Parazzini<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00522/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00522/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 633, Pages 1-292, 28 October 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Exercise-induced expression of monocarboxylate transporter 2 in the cerebellum and its contribution to motor performance<br />
        Daisuke Hoshino, Susumu Setogawa, Yu Kitaoka, Hiroyuki Masuda, Yuki Tamura, Hideo Hatta, Dai Yanagihara<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016306851" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016306851</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience Volume 44, Issue 8 Pages 2515 - 2645, October 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar output encodes a corrective saccadic command (Commentary on Sun et al.)<br />
        David J. Herzfeld and Reza Shadmehr<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13345/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13345/abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Individual neurons in the caudal fastigial oculomotor region convey information on both macro- and microsaccades<br />
        Zongpeng Sun, Marc Junker, Peter W. Dicke and Peter Thier<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13289/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13289/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 336, Pages 1-146, 12 November 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The C-terminal region of Reelin is necessary for proper positioning of a subset of Purkinje cells in the postnatal cerebellum<br />
        Kota Nakamura, Mako Beppu, Kaori Sakai, Hayata Yagyu, Saori Matsumaru, Takao Kohno, Mitsuharu Hattori<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452216304122" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452216304122</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Opinion in Neurobiology Volume 40, Pages 1-188, October 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The role of the human cerebellum in performance monitoring<br />
        Jutta Peterburs, John E Desmond<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0959438816300770" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0959438816300770</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 632, Pages 1-230, 6 October 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Ethanol exposure during development reduces GABAergic/glycinergic neuron numbers and lobule volumes in the mouse cerebellar vermis<br />
        Pranita Nirgudkar, Devin H. Taylor, Yuchio Yanagawa, C. Fernando Valenzuela<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016306292" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016306292</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Autoimmune neurological syndromes associated limbic encephalitis and paraneoplastic cerebellar degeneration<br />
        Zeynep &Ouml;z&ouml;zen Ayas, Dilcan Kotan, Yeşim G&uuml;zey Aras<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016306280" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016306280</a>
      </p>
      <h3 id="2016-September">2016 September</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE September 24, 2016 to September 30, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar tDCS Does Not Improve Learning in a Complex Whole Body Dynamic Balance Task in Young Healthy Subjects<br />
        Katharina Marie Steiner, Anne Enders, Wiebke Thier, Giorgi Batsikadze, Nicolas Ludolph, Winfried Ilg, Dagmar Timmann<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0163598" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0163598</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology Volume 116 Issue 3, 1 September 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar role in Parkinson's disease<br />
        Jasmine L. Mirdamadi<br />
        - <a href="http://jn.physiology.org/content/116/3/917.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/3/917.abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Building Neural Circuits: Wiring and Experience: Spontaneous activity and functional connectivity in the developing cerebellorubral system<br />
        Carlos Del Rio-Bermudez, Alan M. Plumeau, Nicholas J. Sattler, Greta Sokoloff, and Mark S. Blumberg<br />
        - <a href="http://jn.physiology.org/content/116/3/1316.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/3/1316.abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Purkinje cell intrinsic excitability increases after synaptic long term depression<br />
        Zhen Yang and Fidel Santamaria<br />
        - <a href="http://jn.physiology.org/content/116/3/1208.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/3/1208.abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Decreased cerebellar-cerebral connectivity contributes to complex task performance<br />
        Curren Katz and Andr&eacute; Knops<br />
        - <a href="http://jn.physiology.org/content/116/3/1434.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/3/1434.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 91, Issue 6, Pages 1185-1414, 21 September 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Dual Function of NRP1 in Axon Guidance and Subcellular Target Recognition in Cerebellum<br />
        Ludovic Telley, Christelle Cadilhac, Jean-Michel Cioni, Veronique Saywell, C&eacute;line Jahannault-Talignani, Rosa E. Huettl, Catherine Sarrailh-Faivre, Alexandre Dayer, Andrea B. Huber, Fabrice Ango<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627316305074" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627316305074</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Purkinje Cells Directly Inhibit Granule Cells in Specialized Regions of the Cerebellar Cortex<br />
        Chong Guo, Laurens Witter, Stephanie Rudolph, Hunter L. Elliott, Katelin A. Ennis, Wade G. Regehr<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627316305037" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627316305037</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 13 September 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Ontogenic Changes and Differential Localization of T-type Ca2+ Channel Subunits Cav3.1 and Cav3.2 in Mouse Hippocampus and Cerebellum<br />
        Carolina Aguado, Sebasti&aacute;n Garc&iacute;a-Madrona, Mercedes Gil-Minguez, and Rafael Luj&aacute;n<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00083/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00083/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 15 Number 5</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/15/5/page/1" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/15/5/page/1</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1648, Part A, Pages 1-526, 1 October 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neuroprotection comparison of chlorogenic acid and its metabolites against mechanistically distinct cell death-inducing agents in cultured cerebellar granule neurons<br />
        Faten Taram, Aimee N. Winter, Daniel A. Linseman<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899316305108" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899316305108</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Proceedings of the National Academy of Sciences 6 September 2016; Vol. 113, No. 36</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Reassessment of long-term depression in cerebellar Purkinje cells in mice carrying mutated GluA2 C terminus<br />
        Kazuhiko Yamaguchi, Shigeyoshi Itohara, and Masao Ito<br />
        - <a href="http://www.pnas.org/content/113/36/10192" target="_blank" rel="noopener noreferrer">http://www.pnas.org/content/113/36/10192</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 5 September 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        On the Effect of Sex on Prefrontal and Cerebellar Neurometabolites in Healthy Adults: An MRS Study<br />
        Dominique Endres, Ludger Tebartz van Elst, Bernd Feige, Stephan Backenecker, Kathrin Nickel, Anna Bubl, Thomas Lange, Irina Mader, Simon Maier, and Evgeniy Perlov<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00367/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00367/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Intracellular Signaling Molecule Darpp-32 Is a Marker for Principal Neurons in the Cerebellum and Cerebellum-Like Circuits of Zebrafish<br />
        Lena Robra and Vatsala Thirumalai<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00081/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00081/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1646, Pages 1-600, 1 September 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Specific age-related molecular alterations in the cerebellum of Down syndrome mouse models<br />
        Nicole Cr&eacute;au, Eva Cabet, Fabrice Daubigney, Benoit Souchet, Soumia Benna&iuml;, Jean Delabar<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899316304280" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899316304280</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE August 27, 2016 to September 02, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Impaired Spatio-Temporal Predictive Motor Timing Associated with Spinocerebellar Ataxia Type 6<br />
        Robin Broersen, Yoshiyuki Onuki, Abdel R. Abdelgabar, Cullen B. Owens, Samuel Picard, Jessica Willems, Henk-Jan Boele, Valeria Gazzola, Ysbrand D. Van der Werf, Chris I. De Zeeuw<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0162042" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0162042</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex Alert Volume 26, Issue 9 September 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Association Between Eye Movements and Cerebellar Activation in a Verbal Working Memory Task<br />
        Jutta Peterburs, Dominic T. Cheng, and John E. Desmond<br />
        - <a href="http://cercor.oxfordjournals.org/content/26/9/3802.abstract" target="_blank" rel="noopener noreferrer">http://cercor.oxfordjournals.org/content/26/9/3802.abstract</a>
      </p>
      <h3 id="2016-August">2016 August</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience Volume 36 Issue 35, 31 August 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Pharmacologically Counteracting a Phenotypic Difference in Cerebellar GABAA Receptor Response to Alcohol Prevents Excessive Alcohol Consumption in a High Alcohol-Consuming Rodent Genotype<br />
        Josh Steven Kaplan, Michelle A. Nipper, Ben D. Richardson, Jeremiah Jensen, Melinda Helms, Deborah Ann Finn, and David James Rossi<br />
        - <a href="http://www.jneurosci.org/content/36/35/9019" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/35/9019</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neuroligins Are Selectively Essential for NMDAR Signaling in Cerebellar Stellate Interneurons<br />
        Bo Zhang and Thomas C. S&uuml;dhof<br />
        - <a href="http://www.jneurosci.org/content/36/35/9070" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/35/9070</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A Novel and Multivalent Role of Pax6 in Cerebellar Development<br />
        Joanna Yeung, Thomas J. Ha, Douglas J. Swanson, and Dan Goldowitz<br />
        - <a href="http://www.jneurosci.org/content/36/35/9057" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/35/9057</a>&nbsp;</p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Friday, 26 August 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        N-methyl-D-Aspartate Receptors Contribute to Complex Spike Signaling in Cerebellar Purkinje Cells: An In vivo Study in Mice<br />
        Heng Liu, Yan Lan, Yan-Hua Bing, Chun-Ping Chu, and De-Lai Qiu<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2016.00172/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2016.00172/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Modeling the Cerebellar Microcircuit: New Strategies for a Long-Standing Issue<br />
        Egidio D&rsquo;Angelo, Alberto Antonietti, Stefano Casali, Claudia Casellato, Jesus A. Garrido, Niceto Rafael Luque, Lisa Mapelli, Stefano Masoli, Alessandra Pedrocchi, Francesca Prestori, Martina Francesca Rizza, and Eduardo Ros<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2016.00176/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2016.00176/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Influence of Transcranial Direct Current Stimulation to the Cerebellum on Standing Posture Control<br />
        Yasuto Inukai, Kei Saito, Ryoki Sasaki, Shinichi Kotan, Masaki Nakagawa, and Hideaki Onishi<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00325/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00325/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroanatomy<br />
        Feasibility of Diffusion Tractography for the Reconstruction of Intra-Thalamic and Cerebello-Thalamic Targets for Functional Neurosurgery: A Multi-Vendor Pilot Study in Four Subjects<br />
        Andr&aacute;s Jakab, Beat Werner, Marco Piccirelli, K&aacute;zm&eacute;r Kov&aacute;cs, Ernst Martin, John S. Thornton, Tarek Yousry, Gabor Szekely, and Ruth O&lsquo;Gorman Tuura<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00076/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00076/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS Computational Biology Published August 19, 2016 to August 25, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Inverse Stochastic Resonance in Cerebellar Purkinje Cells<br />
        Anatoly Buchin, Sarah Rieubland, Michael H&auml;usser, Boris S. Gutkin, Arnd Roth<br />
        - <a href="http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005000" target="_blank" rel="noopener noreferrer">http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1005000</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Transient cerebellar alterations during development prior to obvious motor phenotype in a mouse model of spinocerebellar ataxia type 6<br />
        Sriram Jayabal, Lovisa Ljungberg and Alanna J. Watt<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP273184/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP273184/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 91, Issue 4, Pages 711-944, 17 August 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Differential Dendritic Integration of Synaptic Potentials and Calcium in Cerebellar Interneurons<br />
        Alexandra Tran-Van-Minh, Ther&eacute;se Abrahamsson, Laurence Cathala, David A. DiGregorio<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627316304147" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627316304147</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume594, Issue16 Pages 4467 - 4684, 15 August 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Does modulation of the endocannabinoid system have potential therapeutic utility in cerebellar ataxia?<br />
        G. J. Stephens<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271106/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271106/abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Are Type 1 metabotropic glutamate receptors a viable therapeutic target for the treatment of cerebellar ataxia?<br />
        Emmet M. Power, Natalya A. English and Ruth M. Empson<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271153/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271153/abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cellular and circuit mechanisms underlying spinocerebellar ataxias<br />
        Pratap Meera, Stefan M. Pulst and Thomas S. Otis<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271897/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271897/abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar ataxias: &beta;-III spectrin's interactions suggest common pathogenic pathways<br />
        Emma Perkins, Daumante Suminaite and Mandy Jackson<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271195/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271195/abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Kv3.3 potassium channels and spinocerebellar ataxia<br />
        Yalan Zhang and Leonard K. Kaczmarek<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271343/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271343/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 629, 26 August 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Increased anxiety-like behaviour and altered GABAergic system in the amygdala and cerebellum of VPA rats &mdash; An animal model of autism<br />
        Lucia Olexov&aacute;, Peter &Scaron;tef&aacute;nik, Lucia Kr&scaron;kov&aacute;<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S030439401630444X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S030439401630444X</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Microinjection of acetylcholine into cerebellar fastigial nucleus induces blood depressor response in anesthetized rats<br />
        Changzheng Zhang, Wen Luo, Peiling Zhou, Tingzhe Sun<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016304712" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016304712</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Effects of norepinephrine on spontaneous firing activity of cerebellar Purkinje cells in vivo in mice<br />
        Ao Guo, Jun-Yang Feng, Jia Li, Nan Ding, Ying-Jun Li, De-Lai Qiu, Ri-Long Piao, Chun-Ping Chu<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016304670" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016304670</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 8 August 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Alterations of Cell Proliferation and Apoptosis in the Hypoplastic Reeler Cerebellum&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br />
        Carolina Cocito, Adalberto Merighi, Mario Giacobini, and Laura Lossi<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2016.00141/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2016.00141/full</a>
      </p>
      <h3 id="2016-July">2016 July</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Jounal of Neurophysiology July 1, 2016 Vol. 116, No. 1</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        ATM protein is located on presynaptic vesicles and its deficit leads to failures in synaptic plasticity<br />
        Graham Vail, Aifang Cheng, Yu Ray Han, Teng Zhao, Shengwang Du, Michael M. T. Loy, Karl Herrup, and Mark R. Plummer<br />
        - <a href="http://jn.physiology.org/content/116/1/201" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/116/1/201</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 27 July 2016, 36(30)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Transmission of Predictable Sensory Signals to the Cerebellum via Climbing Fiber Pathways Is Gated during Exploratory Behavior<br />
        Charlotte L. Lawrenson, Thomas C. Watson, and Richard Apps<br />
        - <a href="http://www.jneurosci.org/content/36/30/7841" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/30/7841</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar output encodes a corrective motor command<br />
        David J. Herzfeld and Reza Shadmehr<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13345/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13345/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Wednesday, 27 July 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neurology<br />
        The Cerebellum and SIDS: Disordered Breathing in a Mouse Model of Developmental Cerebellar Purkinje Cell Loss during Recovery from Hypercarbia<br />
        Michele A. Calton, Jeremy R. Howard, Ronald M. Harper, Dan Goldowitz, and Guy Mittleman<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fneur.2016.00078/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fneur.2016.00078/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neurology<br />
        Metronome Cueing of Walking Reduces Gait Variability after a Cerebellar Stroke<br />
        Rachel L. Wright, Joseph W. Bevins, David Pratt, Catherine M. Sackley, and Alan M. Wing<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fneur.2016.00084/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fneur.2016.00084/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 25 July 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Decreased Cerebellar-Orbitofrontal Connectivity Correlates with Stuttering Severity: Whole-Brain Functional and Structural Connectivity Associations with Persistent Developmental Stuttering<br />
        Kevin R. Sitek, Shanqing Cai, Deryk S. Beal, Joseph S. Perkell, Frank H. Guenther, and Satrajit S. Ghosh<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00190/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00190/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        tDCS of the Cerebellum: Where Do We Stand in 2016? Technical Issues and Critical Review of the Literature<br />
        Kim van Dun, Florian C. A. A. Bodranghien, Peter Mari&euml;n, and Mario U. Manto<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00199/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00199/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroanatomy<br />
        Eppur Si Muove: Evidence for an External Granular Layer and Possibly Transit Amplification in the Teleostean Cerebellum<br />
        Daniela Biechl, Alessandro Dorigo, Reinhard W. K&ouml;ster, Benedikt Grothe, and Mario F. Wullimann<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00049/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00049/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroscience<br />
        Cerebellar Functional Parcellation Using Sparse Dictionary Learning Clustering<br />
        Changqing Wang, Judy Kipping, Chenglong Bao, Hui Ji, and Anqi Qiu<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnins.2016.00188/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnins.2016.00188/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroscience<br />
        DNA Methylation Profiling at Single-Base Resolution Reveals Gestational Folic Acid Supplementation Influences the Epigenome of Mouse Offspring Cerebellum<br />
        Subit Barua, Salomon Kuizon, W. Ted Brown, and Mohammed A. Junaid<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnins.2016.00168/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnins.2016.00168/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE July 16, 2016 to July 22, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Analysis of Gene Expression Profiles in the Human Brain Stem, Cerebellum and Cerebral Cortex<br />
        Lei Chen, Chen Chu, Yu-Hang Zhang, Changming Zhu, Xiangyin Kong, Tao Huang, Yu-Dong Cai<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0159395" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0159395</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Progressive impairment of cerebellar mGluR signalling and its therapeutic potential for cerebellar ataxia in spinocerebellar ataxia type 1 model mice<br />
        Anton N. Shuvaev, Nobutake Hosoi, Yamato Sato, Dai Yanagihara and Hirokazu Hirai<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272950/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272950/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 91, Issue 2, Pages 205-496, 20 July 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Purkinje Cell Collaterals Enable Output Signals from the Cerebellar Cortex to Feed Back to Purkinje Cells and Interneurons<br />
        Laurens Witter, Stephanie Rudolph, R. Todd Pressler, Safiya I. Lahlaf, Wade G. Regehr<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627316302483" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627316302483</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 18 July 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Effects of Transforming Growth Factor Beta 1 in Cerebellar Development: Role in Synapse Formation<br />
        Ana P. B. Araujo, Luan P. Diniz, Cristiane M. Eller, Beatriz G. de Matos, Rodrigo Martinez, and Fl&aacute;via C. A. Gomes<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2016.00104/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2016.00104/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar physiology &ndash; links between microcircuitry properties and sensorimotor functions<br />
        Henrik J&ouml;rntell<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272769/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272769/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 12 July 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Aging Neuroscience<br />
        White Matter Microstructural Organization Is Higher with Age in Adult Superior Cerebellar Peduncles<br />
        Richard A. Kanaan, Matthew Allin, Marco M. Picchioni, Sukhwinder S. Shergill, and Philip K. McGuire<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnagi.2016.00071/full " target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnagi.2016.00071/full</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Computational Neuroscience<br />
        A Hybrid Model for the Computationally-Efficient Simulation of the Cerebellar Granular Layer<br />
        Anna Cattani, Sergio Solinas, and Claudio Canuto<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncom.2016.00030/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncom.2016.00030/full</a>
        <strong><em> </em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroanatomy<br />
        Smaller Absolute Quantities but Greater Relative Densities of Microvessels Are Associated with Cerebellar Degeneration in Lurcher Mice<br />
        Yaroslav Kolinko, Jan Cendelin, Milena Kralickova, and Zbynek Tonar<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00035/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00035/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 15 Issue 4</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/15/4" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/15/4</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 594, Issue 13 Pages 3483-3848, 1 July 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Biphasic modulation of parallel fibre synaptic transmission by co-activation of presynaptic GABAA and GABAB receptors in mice<br />
        Rebecca D. Howell and Jason R. Pugh<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272124/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272124/abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Train stimulation of parallel fibre to Purkinje cell inputs reveals two populations of synaptic responses with different receptor signatures<br />
        Suma Priya Sudarsana Devi, James R. Howe and C&eacute;line Auger<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272415/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272415/abstract</a>
      </p>
      <h3 id="2016-June">2016 June</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology June 1, 2016; Vol. 115, No. 6</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        In vivo analysis of cerebellar Purkinje cell activity in SCA2 transgenic mouse model<br />
        Polina A. Egorova, Olga A. Zakharova, Olga L. Vlasova, and Ilya B. Bezprozvanny<br />
        - <a href="http://jn.physiology.org/content/115/6/2840.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/115/6/2840.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 29 June 2016, 36(26)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Implications of Lateral Cerebellum in Proactive Control of Saccades<br />
        Jun Kunimatsu, Tomoki W. Suzuki, and Masaki Tanaka<br />
        - <a href="http://www.jneurosci.org/content/36/26/7066.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/26/7066.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Thursday, 16 June 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neural Circuits<br />
        Cerebellar Nuclei Neurons Show Only Small Excitatory Responses to Optogenetic Olivary Stimulation in Transgenic Mice: In Vivo and In Vitro Studies<br />
        Huo Lu, Bo Yang, and Dieter Jaeger<br />
        - <a href="http://url" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncir.2016.00021/full</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroanatomy<br />
        Extensive Direct Subcortical Cerebellum-Basal Ganglia Connections in Human Brain as Revealed by Constrained Spherical Deconvolution Tractography<br />
        Demetrio Milardi, Alessandro Arrigo, Giuseppe Anastasi, Alberto Cacciola, Silvia Marino, Enricomaria Mormina, Alessandro Calamuneri, Daniele Bruschetta, Giuseppina Cutroneo, Fabio Trimarchi, and Angelo Quartarone<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2016.00029/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2016.00029/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Research Volume 106, Pages 1-78, May 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Oxygen&ndash;glucose deprivation increases firing of unipolar brush cells and enhances spontaneous EPSCs in Purkinje cells in the vestibulo-cerebellum<br />
        Yukihiro Takayasu, Masato Shino, Osamu Nikkuni, Yukari Yoshida, Nobuhiko Furuya, Kazuaki Chikamatsu<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0168010215002606" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0168010215002606</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The dynamic relationship between cerebellar Purkinje cell simple spikes and complex spike spikelet number<br />
        Amelia Burroughs, Andrew K Wise, Jianqiang Xiao, Conor Houghton, Tianyu Tang, Colleen Y Suh, Eric J Lang, Richard Apps and Nadia L Cerminara<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP272259/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP272259/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex Table of Contents Alert Vol. 26, No. 7 July 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Early Cerebellar Network Shifting in Spinocerebellar Ataxia Type 6<br />
        M.I. Falcon, C.M. Gomez, E.E. Chen, A. Shereen, and A. Solodkin<br />
        - <a href="http://cercor.oxfordjournals.org/content/26/7/3205.abstract" target="_blank" rel="noopener noreferrer">http://cercor.oxfordjournals.org/content/26/7/3205.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience Volume 43, Issue 11 Pages 1413 - 1534, June 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Distinct expression patterns of inwardly rectifying potassium currents in developing cerebellar granule cells of the hemispheres and the vermis<br />
        Federico Brandalise, Rafael Lujan, Roberta Leone, Francesco Lodola, Valentina Cesaroni, Chiara Romano, Urs Gerber and Paola Rossi<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13219/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13219/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1642, Pages 1-612, 1 July 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Ethanol preconditioning of rat cerebellar cultures targets NMDA receptors to the synapse and enhances peroxiredoxin 2 expression<br />
        Robert M. Mitchell, Nuzhath Tajuddin, Edward M. Campbell, Edward J. Neafsey, Michael A. Collins<br />
        - <a href="http://url" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899316301378</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Oxidative injury in multiple sclerosis cerebellar grey matter<br />
        Kevin Kemp, Juliana Redondo, Kelly Hares, Claire Rice, Neil Scolding, Alastair Wilkins<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899316302360" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899316302360</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 90, Issue 5, Pages 905-1140, 1 June 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Functional Properties of Dendritic Gap Junctions in Cerebellar Golgi Cells<br />
        Miklos Szoboszlay, Andrea Lőrincz, Frederic Lanore, Koen Vervaeke, R. Angus Silver, Zoltan Nusser<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627316300204" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627316300204</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 1 June 2016, 36(22)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Functional Evidence for a Cerebellar Node of the Dorsal Attention Network<br />
        James A. Brissenden, Emily J. Levin, David E. Osher, Mark A. Halko, and David C. Somers<br />
        - <a href="http://www.jneurosci.org/content/36/22/6083.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/22/6083.abstract</a>
      </p>
      <h3 id="2016-May">2016 May</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Journal of Neurophysiology Volume 115, No 5, 1 May 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        mGlu1 receptor mediates homeostatic control of intrinsic excitability through Ih in cerebellar Purkinje cells<br />
        Hyun Geun Shim, Sung-Soo Jang, Dong Cheol Jang, Yunju Jin, Wonseok Chang, Joo Min Park, and Sang Jeong Kim<br />
        - <a href="http://jn.physiology.org/content/115/5/2446.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/115/5/2446.abstract</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar contributions to self-motion perception: evidence from patients with congenital cerebellar agenesis<br />
        Kilian Dahlem, Yulia Valko, Jeremy D. Schmahmann, and Richard F. Lewis<br />
        - <a href="http://jn.physiology.org/content/115/5/2280.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/115/5/2280.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>PLOS ONE May 21, 2016 to May 27, 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Robust Machine Learning-Based Correction on Automatic Segmentation of the Cerebellum and Brainstem<br />
        Jun Yi Wang, Michael M. Ngo, David Hessl, Randi J. Hagerman, Susan M. Rivera<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0156123" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0156123</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 25 May 2016, 36(21)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Optogenetic Visualization of Presynaptic Tonic Inhibition of Cerebellar Parallel Fibers<br />
        Ken Berglund, Lei Wen, Robert L. Dunbar, Guoping Feng, and George J. Augustine<br />
        - <a href="http://www.jneurosci.org/content/36/21/5709.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/21/5709.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 24 May 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Comutational Neuroscience<br />
        Distributed Cerebellar Motor Learning: A Spike-Timing-Dependent Plasticity Model<br />
        Niceto R. Luque, Jes&uacute;s A. Garrido, Francisco Naveros, Richard R. Carrillo, Egidio D'Angelo, and Eduardo Ros<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncom.2016.00017/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncom.2016.00017/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Annals of the New York Academy of Sciences Volume 1369, Issue 1 Pages 1 - 256, April 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar contributions to motor control and language comprehension: searching for common computational principles (pages 154&ndash;171)<br />
        Torgeir Moberget and Richard B. Ivry<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/nyas.13094/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/nyas.13094/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Wednesday, 18 May 2016 </em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Systems Neuroscience<br />
        Inactivation of Cerebellar Cortical Crus II Disrupts Temporal Processing of Absolute Timing but not Relative Timing in Voluntary Movements<br />
        Kenji Yamaguchi and Yoshio Sakurai<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnsys.2016.00016/full " target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnsys.2016.00016/full&nbsp;</a>
        <strong><em></em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Psychology<br />
        Anomalous Cerebellar Anatomy in Chinese Children with Dyslexia<br />
        Ying-Hui Yang, Yang Yang, Bao-Guo Chen, Yi-Wei Zhang, and Hong-Yan Bi<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fpsyg.2016.00324/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fpsyg.2016.00324/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 622, Pages 1-124, 27 May 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Evidence for cerebellar motor functional reorganization in brain tumor patients: An fMRI study<br />
        Satoshi Kurabe, Kosuke Itoh, Tsutomu Nakada, Yukihiko Fujii <br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S030439401630249X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S030439401630249X</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 594, Issue 10 Pages 2555 - 2772, 15 May 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Time-invariant feed-forward inhibition of Purkinje cells in the cerebellar cortex in vivo (pages 2729&ndash;2749)<br />
        Antonin Blot, Camille de Solages, Srdjan Ostojic, German Szapiro, Vincent Hakim and Cl&eacute;ment L&eacute;na<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271518/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271518/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Thursday, 12 May 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroscience<br />
        More Consistently Altered Connectivity Patterns for Cerebellum and Medial Temporal Lobes than for Amygdala and Striatum in Schizophrenia<br />
        Henning Peters, Junming Shao, Martin Scherr, Dirk Schwerth&ouml;ffer, Claus Zimmer, Hans F&ouml;rstl, Josef B&auml;uml, Afra Wohlschl&auml;ger, Valentin Riedl, Kathrin Koch, and Christian Sorg <br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2016.00055/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2016.00055/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 4 May 2016, 36(18)</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Prolonged Type 1 Metabotropic Glutamate Receptor Dependent Synaptic Signaling Contributes to Spino-Cerebellar Ataxia Type 1<br />
        Emmet M. Power, Adrienne Morales, and Ruth M. Empson<br />
        - <a href="http://www.jneurosci.org/content/36/18/4910" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/18/4910</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum Volume 15 Number 3</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/15/3/page/1" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/15/3/page/1</a>
      </p>
      <h3 id="2016-April">2016 April</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroimage Volume 132, Pages 1-570, 15 May 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Direct neural current imaging in an intact cerebellum with magnetic resonance imaging<br />
        Padmavathi Sundaram, Aapo Nummenmaa, William Wells, Darren Orbach, Daniel Orringer, Robert Mulkern, Yoshio Okada<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811916000744" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811916000744</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience Volume 43, Issue 8, Pages 992 - 1121, April 2016</em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar brain inhibition in the target and surround muscles during voluntary tonic activation (pages 1075&ndash;1081)<br />
        Pattamon Panyakaew, Hyun Joo Cho, Prachaya Srivanitchapoom, Traian Popa, Tianxia Wu and Mark Hallett<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13211/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13211/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience &amp; Biobehavioral Reviews, Volume 60, Pages 1-138, January 2016 </em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Have we been ignoring the elephant in the room? Seven arguments for considering the cerebellum as part of addiction circuitry<br />
        Marta Miquel, Dolores Vazquez-Sanroman, Mar&iacute;a Carbo-Gas, Isis Gil-Miravet, Carla Sanchis-Segura, Daniela Carulli, Jorge Manzo, Genaro A. Coria-Avila<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0149763415002754" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0149763415002754</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience &amp; Biobehavioral Reviews, Volume 59, Pages 1-266, December 2015&nbsp; </em></strong>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The anatomy of fear learning in the cerebellum: A systematic meta-analysis<br />
        Iris Lange, Zuzana Kasanova, Liesbet Goossens, Nicole Leibold, Chris I. De Zeeuw, Therese van Amelsvoort, Koen Schruers<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0149763415002523" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0149763415002523</a>
      </p>
      <h3 id="2016-March">2016 March</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 29 March 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Microbiology<br />
        Sub-Lethal Dose of Shiga Toxin 2 from Enterohemorrhagic Escherichia coli Affects Balance and Cerebellar Cytoarchitecture<br />
        Luciana D&rsquo;Alessio, Alipio Pinto, Adriana Cangelosi, Patricia A. Geoghegan, Carla Tironi-Farinati, Gabriela J. Brener, and Jorge Goldstein<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fmicb.2016.00133/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fmicb.2016.00133/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Loss of the Calcium Channel &beta;4 Subunit Impairs Parallel Fiber Volley and Purkinje Cell Firing in Cerebellum of Adult Ataxic Mice<br />
        Bruno Benedetti, Ariane Benedetti and Bernhard E. Flucher<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13241/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13241/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 322, Pages 1-538, 13 May 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Developmental ethanol exposure-induced sleep fragmentation predicts adult cognitive impairment<br />
        D.A. Wilson, K. Masiello, M.P. Lewin, M. Hui, J.F. Smiley, M. Saito<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452216001433" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452216001433</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Psychiatry Article Alert Friday, 18 March 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cortico-Cerebellar Connectivity in Autism Spectrum Disorder: What Do We Know So Far?<br />
        Alessandro Crippa, Giuseppe Del Vecchio, Silvia Busti Ceccarelli, Maria Nobile, Filippo Arrigoni, and Paolo Brambilla<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fpsyt.2016.00020/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fpsyt.2016.00020/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Does modulation of the endocannabinoid system have potential therapeutic utility in cerebellar ataxia?<br />
        G.J. Stephens <br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271106/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271106/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Neuron Volume 89, Issue 6, Pages 1121-1356, 16 March 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        10) Cerebellar Transcriptome Profiles of ATXN1 Transgenic Mice Reveal SCA1 Disease Progression and Protection Pathways<br />
        Melissa Ingram, Emily A.L. Wozniak, Lisa Duvick, Rendong Yang, Paul Bergmann, Robert Carson, Brennon O&rsquo;Callaghan, Huda Y. Zoghbi, Christine Henzler, Harry T. Orr <br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627316001045" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627316001045</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 617, Pages 1-288, 23 March 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        27) The role of the cerebellum in motor imagery<br />
        B&uuml;lent Cengiz, H. Evren Boran<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394016300441" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394016300441</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Frontiers in Neuroscience Article Alert Monday, 14 March 2016 </em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Editorial: The Olivo-Cerebellar System<br />
        Egidio D'Angelo, Elisa Galliano, and Chris I. De Zeeuw <br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncir.2015.00066/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncir.2015.00066/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Experimental Brain Research. Volume 234 Number 4</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellum as a forward but not inverse model in visuomotor adaptation task: a tDCS-based and modeling study<br />
        Fatemeh Yavari, Shirin Mahdavi, Farzad Towhidkhah, Mohammad-Ali Ahmadi-Pajouh, Hamed Ekhtiari &amp; Mohammad Darainy<br />
        - <a href="http://link.springer.com/article/10.1007/s00221-015-4523-2" target="_blank" rel="noopener noreferrer">http://link.springer.com/article/10.1007/s00221-015-4523-2</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE March 05, 2016 to March 11, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The cerebro-cerebellum: Could it be loci of forward models?<br />
        Takahiro Ishikawa, Saeka Tomatsu, Jun Izawa, Shinji Kakei<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S016801021500293X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S016801021500293X</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE March 05, 2016 to March 11, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Altered Cerebellar White Matter Integrity in Patients with Mild Traumatic Brain Injury in the Acute Stage<br />
        Zhongqiu Wang, Wenzhong Wu, Yongkang Liu, Tianyao Wang, Xiao Chen, Jianhua Zhang, Guoxing Zhou, Rong Chen <br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0151489" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0151489</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex Table of Contents Alert Vol. 26, No. 4 April 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Enhancing Consolidation of a New Temporal Motor Skill by Cerebellar Noninvasive Stimulation<br />
        Maximilian J. Wessel, M&aacute;ximo Zimerman, Jan E. Timmermann, Kirstin F. Heise, Christian Gerloff, and Friedhelm C. Hummel <br />
        - <a href="http://cercor.oxfordjournals.org/content/26/4/1660.abstract" target="_blank" rel="noopener noreferrer">http://cercor.oxfordjournals.org/content/26/4/1660.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience Article Alert Wednesday, 9 March 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Gray Matter Volume and Resting-State Functional Connectivity of the Motor Cortex-Cerebellum Network Reflect the Individual Variation in Masticatory Performance in Healthy Elderly People<br />
        Chia-Shu Lin, Shih-Yun Wu, Ching-Yi Wu, and Hsien-Wei Ko<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnagi.2015.00247/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnagi.2015.00247/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 15 Number 2</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/15/2" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/15/2</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE February 27, 2016 to March 04, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Differential Modulation of GABA<sub>A</sub> Receptors Underlies Postsynaptic Depolarization- and Purinoceptor-Mediated Enhancement of Cerebellar Inhibitory Transmission: A Non-Stationary Fluctuation Analysis Study<br />
        Yumie Ono, Fumihito Saitow, Shiro Konishi<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0150636" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0150636</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1635, 15 March 2016, Pages 113&ndash;120</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Localization of Neurensin1 in cerebellar Purkinje cells of the developing chick and its possible function in dendrite formation<br />
        Keiko Kiyonaga-Endou, Manabu Oshima, Kazuya Sugimoto, Mervyn Thomas, Shigeru Taketani, Masasuke Araki <br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899316300154" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899316300154</a>
      </p>
      <h3 id="2016-february">2016 February</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Time-invariant feed-forward inhibition of Purkinje cells in the cerebellar cortex in vivo<br />
        Antonin Blot, Camille de Solages, Srdjan Ostojic, German Szapiro, Vincent Hakim and Cl&eacute;ment L&eacute;na<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271518/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271518/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>A new issue of Journal of Neurophysiology February 1, 2016; Vol. 115, No. 2</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neuronal Diversity: Categorizing Types of Neurons: Classifying neuronal subclasses of the cerebellum through constellation pharmacology<br />
        Kigen J. Curtice, Lee S. Leavitt, Kevin Chase, Shrinivasan Raghuraman, Martin P. Horvath, Baldomero M. Olivera, and Russell W. Teichert<br />
        - <a href="http://jn.physiology.org/content/115/2/1031.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/115/2/1031.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar brain inhibition in the target and surround muscles during voluntary tonic activation<br />
        Pattamon Panyakaew, Hyun Joo Cho, Prachaya Srivanitchapoom, Traian Popa, Tianxia Wu and Mark Hallett<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.13211/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.13211/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE February 13, 2016 to February 19, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Coenzyme Q10 Levels Are Decreased in the Cerebellum of Multiple-System Atrophy Patients<br />
        Lucia V. Schottlaender, Concei&ccedil;&atilde;o Bettencourt, Aoife P. Kiely, Annapurna Chalasani, Viruna Neergheen, Janice L. Holton, Iain Hargreaves, Henry Houlden<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0149557" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0149557</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neural Networks Volume 75, Pages 1-196, March 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        2) A theory of cerebellar cortex and adaptive motor control based on two types of universal function approximation capability<br />
        Original Research Article, Pages 173-196<br />
        Masahiko Fujita<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0893608015002816" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0893608015002816</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience Article Alert Thursday, 18 February 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cognitive Collaborations: Bidirectional Functional Connectivity Between the Cerebellum and the Hippocampus<br />
        Wilson Yu and Esther Krook-Magnuson<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnsys.2015.00177/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnsys.2015.00177/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 128, Pages 1-432, March 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        24) Upregulation of cortico-cerebellar functional connectivity after motor learning<br />
        Original Research Article, Pages 252-263<br />
        Saeid Mehrkanoon, Tjeerd W. Boonstra, Michael Breakspear, Mark Hinder, Jeffery J. Summers<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915011659" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915011659</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 17 February 2016, 36(7)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Amygdala Modulation of Cerebellar Learning<br />
        Sean J. Farley, Jason J. Radley, and John H. Freeman<br />
        The Journal of Neuroscience, 17 February 2016, 36(7):2190-2201;<br />
        - <a href="http://www.jneurosci.org/content/36/7/2190.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/36/7/2190.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 594, Issue 4 Pages 799 - 1101, 15 February 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Determinants of rebound burst responses in rat cerebellar nuclear neurons to physiological stimuli (pages 985&ndash;1003)<br />
        Steven Dykstra, Jordan D. T. Engbers, Theodore M. Bartoletti and Ray W. Turner<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271894/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271894/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 127, Pages 1-512, 15 February 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        38) Automated cerebellar lobule segmentation with application to cerebellar structural analysis in cerebellar disease<br />
        Original Research Article, Pages 435-444<br />
        Zhen Yang, Chuyang Ye, John A. Bogovic, Aaron Carass, Bruno M. Jedynak, Sarah H. Ying, Jerry L. Prince<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915008472" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915008472</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> New Articles in PLOS ONE February 06, 2016 to February 12, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        UBA5 Mutations Cause a New Form of Autosomal Recessive Cerebellar Ataxia<br />
        Ranhui Duan, Yuting Shi, Li Yu, Gehan Zhang, Jia Li, Yunting Lin, Jifeng Guo, Junling Wang, Lu Shen, Hong Jiang, Guanghui Wang, Beisha Tang<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0149039" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0149039</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 89, Issue 2, Pages 237-422, 20 January 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        20) Excitatory Cerebellar Nucleocortical Circuit Provides Internal Amplification during Associative Conditioning<br />
        Original Research Article, Pages 645-657<br />
        Zhenyu Gao, Martina Proietti-Onori, Zhanmin Lin, Michiel M. ten Brinke, Henk-Jan Boele, Jan-Willem Potters, Tom J.H. Ruigrok, Freek E. Hoebeek, Chris I. De Zeeuw<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S089662731600009X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S089662731600009X</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE January 23, 2016 to January 29, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Reference Charts for Fetal Cerebellar Vermis Height: A Prospective Cross-Sectional Study of 10605 Fetuses<br />
        Pietro Cignini, Maurizio Giorlandino, Pierpaolo Brutti, Lucia Mangiafico, Alessia Aloisi, Claudio Giorlandino<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0147528" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0147528</a>
      </p>
      <h3 id="2016-january">2016 January</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar ataxias: &beta;-III spectrin&rsquo;s interactions suggest common pathogenic pathways<br />
        Emma Perkins, Daumante Suminaite and Mandy Jackson<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271195/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271195/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS Computational Biology Published January 22, 2016 to January 28, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A Computational Model for the AMPA Receptor Phosphorylation Master Switch Regulating Cerebellar Long-Term Depression<br />
        Andrew R. Gallimore, A. Radu Aricescu, Michisuke Yuzaki, Radu Calinescu<br />
        - <a href="http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1004664" target="_blank" rel="noopener noreferrer">http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1004664</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>A new issue of Journal of Neurophysiology January 1, 2016; Vol. 115, No. 1</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Presynaptic GluN<sub>2</sub>D receptors detect glutamate spillover and regulate cerebellar GABA release<br />
        Christophe J. Dubois, Philippe M. Lachamp, Lu Sun, Masayoshi Mishina, and Siqiong June Liu<br />
        - <a href="http://jn.physiology.org/content/115/1/271.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/115/1/271.abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Information processing in the hemisphere of the cerebellar cortex for control of wrist movement<br />
        Saeka Tomatsu, Takahiro Ishikawa, Yoshiaki Tsunoda, Jongho Lee, Donna S. Hoffman, and Shinji Kakei<br />
        - <a href="http://jn.physiology.org/content/115/1/255.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/115/1/255.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 15 Number 1</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        <a href="http://link.springer.com/journal/12311/15/1" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/15/1</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE January 16, 2016 to January 22, 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Subcellular Dynamics of the Gs-Linked Receptor GPR3 Contribute to the Local Activation of PKA in Cerebellar Granular Neurons<br />
        Tatsuhiro Miyagi, Shigeru Tanaka, Izumi Hide, Toshihiko Shirafuji, Norio Sakai<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0147466" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0147466</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 88, Issue 2, Pages 237-434, 21 October 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        11) Role of Tet1/3 Genes and Chromatin Remodeling Genes in Cerebellar Circuit Formation<br />
        Original Research Article, Pages 100-112<br />
        Xiaodong Zhu, David Girardo, Eve-Ellen Govek, Keisha John, Marian Mell&eacute;n, Pablo Tamayo, Jill P. Mesirov, Mary E. Hatten<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627315010363" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627315010363</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Are Type 1 metabotropic glutamate receptors a viable therapeutic target for the treatment of cerebellar ataxia?<br />
        Emmet M. Power, Natalya A. English and Ruth M. Empson<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271153/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271153/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 89, Issue 1, Pages 1-236, 6 January 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        11) Role of Tet1/3 Genes and Chromatin Remodeling Genes in Cerebellar Circuit Formation<br />
        Original Research Article, Pages 100-112<br />
        Xiaodong Zhu, David Girardo, Eve-Ellen Govek, Keisha John, Marian Mell&eacute;n, Pablo Tamayo, Jill P. Mesirov, Mary E. Hatten<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627315010363" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627315010363</a>
      </p>
      <h3 id="2015-december">2015 December</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 610, Pages 1-228, 1 January 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        14) Anatomical and functional relationships between deep cerebellar nuclei and cerebellar cortical Crus II in vivo in mice<br />
        Original Research Article, Pages 73-78<br />
        Nan Ding, Hua Jin, Bin-Bin Zhang, Ao Guo, Jin-Di Shi, Jun-Yang Feng, Jia Li, Xuan-Xi Shen, Yu Shi, De-Lai Qiu, Chun-Ping Chu<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015302275" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015302275</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        29) Altered cerebellar-amygdala connectivity in violent offenders: A resting-state fMRI study<br />
        Original Research Article, Pages 160-164<br />
        Verena Leutgeb, Albert Wabnegger, Mario Leitner, Thomas Zussner, Wilfried Scharm&uuml;ller, Doris Klug, Anne Schienle<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015302263" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015302263</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        35) Ginsenoside Rg1 prevents cerebral and cerebellar injury induced by obstructive jaundice in rats via inducing expression of TIPE-2<br />
        Original Research Article, Pages 193-199<br />
        Tingting Zhou, Guo Zu, Lu Zhou, Ningwei Che, Jing Guo, Zhanhua Liang<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015302524" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015302524</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Proceedings of the National Academy of Sciences 15 December 2015; Vol. 112, No. 50</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Numb deficiency in cerebellar Purkinje cells impairs synaptic expression of metabotropic glutamate receptor and motor coordination<br />
        Liang Zhou, Dong Yang, De-Juan Wang, Ya-Jun Xie, Jia-Huan Zhou, Lin Zhou, Hao Huang, Shuo Han, Chong-Yu Shao, Hua-Shun Li, J. Julius Zhu, Meng-Sheng Qiu, Chris I. De Zeeuw, and Ying Shen<br />
        - <a href="http://www.pnas.org/content/112/50/15474.abstract.html" target="_blank" rel="noopener noreferrer">http://www.pnas.org/content/112/50/15474.abstract.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Determinants of rebound burst responses in rat cerebellar nuclear neurons to physiological stimuli<br />
        Steven Dykstra, Jordan D. T. Engbers, Theodore M. Bartoletti and Ray W. Turner<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP271894/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP271894/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1627, Pages 1-242, 19 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        5) Circadian rhythms of clock gene expression in the cerebellum of serotonin-deficient Pet-1 knockout mice<br />
        Original Research Article,<br />
        Pages 10-17<br />
        Erin V. Paulus, Eric M. Mintz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S000689931500815X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S000689931500815X</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS Biology Published December 02, 2015 to December 08, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Motor Learning Unfolds over Different Timescales in Distinct Neural Systems<br />
        Janelle Weaver<br />
        - <a href="http://journals.plos.org/plosbiology/article?id=10.1371/journal.pbio.1002313" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosbiology/article?id=10.1371/journal.pbio.1002313</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuropharmacology Volume 101, Pages 1-590, February 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        41) The 5-HT7 receptor triggers cerebellar long-term synaptic depression via PKC-MAPK<br />
        Original Research Article, Pages 426-438<br />
        Pellegrino Lippiello, Eriola Hoxha, Luisa Speranza, Floriana Volpicelli, Angela Ferraro, Marcello Leopoldo, Enza Lacivita, Carla Perrone-Capano, Filippo Tempia, Maria Concetta Miniaci<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0028390815301428" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0028390815301428</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1627, Pages 1-242, 19 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        45) Suppressed expression of cystathionine &beta;-synthase and smaller cerebellum in Wistar Kyoto rats<br />
        Original Research Article, Pages 208-213<br />
        Mao Nagasawa, Hiromi Ikeda, Takahiro Kawase, Ayaka Iwamoto, Shinobu Yasuo, Mitsuiro Furuse<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315005879" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315005879</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        10) Reduced serine racemase expression in aging rat cerebellum is associated with oxidative DNA stress and hypermethylation in the promoter<br />
        Original Research Article, Pages 221-230<br />
        He Zhang, Xiu-Li Kuang, Yuhua Chang, Jinfang Lu, Haiyan Jiang, Shengzhou Wu<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315007969" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315007969</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1629, Pages 1-360, 10 December 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        10) Reduced serine racemase expression in aging rat cerebellum is associated with oxidative DNA stress and hypermethylation in the promoter<br />
        Original Research Article, Pages 221-230<br />
        He Zhang, Xiu-Li Kuang, Yuhua Chang, Jinfang Lu, Haiyan Jiang, Shengzhou Wu<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315007969" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315007969</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        29) Protective effect of histamine microinjected into cerebellar fastigial nucleus on stress gastric mucosal damage in rats<br />
        Original Research Article, Pages 351-360<br />
        Xiao Qiao, Jun Yang, Su-Juan Fei, Jin-Zhou Zhu, Sheng-Ping Zhu, Zhang-Bo Liu, Ting-Ting Li, Jian-Fu Zhang<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315007519" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315007519</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS Computational Biology Published November 27, 2015 to December 03, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar Nuclear Neurons Use Time and Rate Coding to Transmit Purkinje Neuron Pauses<br />
        Shyam Kumar Sudhakar, Benjamin Torben-Nielsen, Erik De Schutter<br />
        - <a href="http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1004641" target="_blank" rel="noopener noreferrer">http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1004641</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Trends in Neurosciences Volume 38, Issue 11, Pages 669-750, November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        5) Controlling Cerebellar Output to Treat Refractory Epilepsy<br />
        Review Article, Pages 787-799<br />
        Lieke Kros, Oscar H.J. Eelkman Rooda, Chris I. De Zeeuw, Freek E. Hoebeek<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S016622361500226X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S016622361500226X</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 609, Pages 1-240, 16 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        5) Propofol effects on cerebellar long-term depression<br />
        Original Research Article, Pages 18-22<br />
        Kwan Young Lee, Young Im Kim, Se Hoon Kim, Hyung Seo Park, Youn Joon Park, Myung Sook Ha, Yunju Jin, Dong Kwan Kim<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015301634" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015301634</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Abnormally increased surface expression of AMPA receptors in the cerebellum, cortex and striatum of <i>Cln3</i><sup>&minus;/&minus;</sup> mice<br />
        Original Research Article,<br />
        Pages 29-34<br />
        Attila D. Kov&aacute;cs, Caitlin Hof, David A. Pearce<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015301385" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015301385</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 2 December 2015, 35(48)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Visualization of Ca<sup>2+</sup> Filling Mechanisms upon Synaptic Inputs in the Endoplasmic Reticulum of Cerebellar Purkinje Cells<br />
        Yohei Okubo, Junji Suzuki, Kazunori Kanemaru, Naotoshi Nakamura, Tatsuo Shibata, and Masamitsu Iino<br />
        - <a href="http://www.jneurosci.org/content/35/48/15837.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/48/15837.abstract</a>
      </p>
      <h3 id="2015-november">2015 November</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 309, Pages 1-280, 19 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        59) Spontaneous malformations of the cerebellar vermis: Prevalence, inheritance, and relationship to lobule/fissure organization in the C57BL/6 lineage<br />
        Original Research Article,<br />
        Pages 242-251<br />
        S.E. Van Dine, N.Y. Siu, A. Toia, J.A. Cuoco, A.J. Betz, V.J. Bolivar, G. Torres, R.L. Ramos<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215008441" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215008441</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Biological Cybernetics. Volume 109 Number 6</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellum-inspired neural network solution of the inverse kinematics problem (Open Access)<br />
        Mitra Asadi-Eydivand, Mohammad Mehdi Ebadzadeh, Mehran Solati-Hashjin, Christian Darlot &amp; Noor Azuan Abu Osman<br />
        - <a href="http://link.springer.com/article/10.1007/s00422-015-0661-7" target="_blank" rel="noopener noreferrer">http://link.springer.com/article/10.1007/s00422-015-0661-7</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NATURE NEUROSCIENCE December 2015 Volume 18 Number 12, pp 1699 - 1862</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Climbing fibers encode a temporal-difference prediction error during cerebellar learning in mice pp1798 - 1803<br />
        Shogo Ohmae and Javier F Medina<br />
        - <a href="http://www.nature.com/neuro/journal/v18/n12/full/nn.4167.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/neuro/journal/v18/n12/full/nn.4167.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>A new issue of Journal of Neurophysiology October 1, 2015; Vol. 114, No. 4</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Modulation of cerebello-cerebral resting state networks by site-specific stimulation<br />
        Anuj Rastogi, Ayda Ghahremani, and Robin Cash<br />
        - <a href="http://jn.physiology.org/content/114/4/2084.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/114/4/2084.abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Modulation of error-sensitivity during a prism adaptation task in people with cerebellar degeneration<br />
        Ritsuko Hanajima, Reza Shadmehr, Shinya Ohminami, Ryosuke Tsutsumi, Yuichiro Shirota, Takahiro Shimizu, Nobuyuki Tanaka, Yasuo Terao, Shoji Tsuji, Yoshikazu Ugawa, Motoaki Uchimura, Masato Inoue, and Shigeru Kitazawa<br />
        - <a href="http://jn.physiology.org/content/114/4/2460.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/114/4/2460.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 18 November 2015, 35(46)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Functional and Physical Interaction of Diacylglycerol Kinase &zeta; with Protein Kinase C&alpha; Is Required for Cerebellar Long-Term Depression<br />
        Dongwon Lee, Yukio Yamamoto, Eunjoon Kim, and Keiko Tanaka-Yamamoto<br />
        - <a href="http://www.jneurosci.org/content/35/46/15453.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/46/15453.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers in Neuroscience Article Alert Tuesday, 17 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Deposition pattern and subcellular distribution of disease-associated prion protein in cerebellar organotypic slice cultures infected with scrapie<br />
        Hanna Wolf, Andre Hossinger, Andrea Fehlinger, Sven Buettner, Valerie Sim, Debbie McKenzie, and Ina Maja Vorberg<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnins.2015.00410/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnins.2015.00410/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebro-cerebellar circuits in autism spectrum disorder<br />
        Anila M. D&rsquo;Mello and Catherine J. Stoodley<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnins.2015.00408/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnins.2015.00408/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Autism spectrum disorders and neuropathology of the cerebellum<br />
        David R Hampson and Gene J Blatt<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnins.2015.00420/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnins.2015.00420/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE November 07, 2015 to November 13, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Anoctamin Calcium-Activated Chloride Channels May Modulate Inhibitory Transmission in the Cerebellar Cortex<br />
        Weiping Zhang, Steffen Schmelzeisen, Daniel Parthier, Stephan Frings, Frank M&ouml;hrlen<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0142160" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0142160</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 124, Part B, Pages 1065-1262, 1 January 2016</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        26) Functional connectivity between the cerebrum and cerebellum in social cognition: A multi-study analysis<br />
        Original Research Article, Pages 248-255<br />
        Frank Van Overwalle, Peter Mari&euml;n<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S105381191500796X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S105381191500796X</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        56) Differential motor and prefrontal cerebello-cortical network development: Evidence from multimodal neuroimaging<br />
        Original Research Article, Pages 591-601<br />
        Jessica A. Bernard, Joseph M. Orr, Vijay A. Mittal<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915008289" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915008289</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        68) A probabilistic atlas of the cerebellar white matter<br />
        Original Research Article, Pages 724-732<br />
        K.M. van Baarsen, M. Kleinnijenhuis, S. Jbabdi, S.N. Sotiropoulos, J.A. Grotenhuis, A.M. van Cappellen van Walsum<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915008113" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915008113</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Volume 593, Issue 22 Pages 4813 - 5024, 15 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        High-frequency focal repetitive cerebellar stimulation induces prolonged increases in human pharyngeal motor cortex excitability (pages 4963&ndash;4977)<br />
        Dipesh H. Vasant, Emilia Michou, Satish Mistry, John C Rothwell and Shaheen Hamdy<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP270817/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP270817/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE October 31, 2015 to November 06, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Theta Burst Stimulation of the Cerebellum Modifies the TMS-Evoked N100 Potential, a Marker of GABA Inhibition<br />
        Allanah Harrington, Graeme David Hammond-Tooke<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0141284" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0141284</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 14 Number 6</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/14/6" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/14/6</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 608, Pages 1-70, 3 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Abnormally increased surface expression of AMPA receptors in the cerebellum, cortex and striatum of Cln3<sup>&minus;/&minus;</sup>&nbsp;mice<br />
        Original Research Article, Pages 29-34<br />
        Attila D. Kov&aacute;cs, Caitlin Hof, David A. Pearce<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015301385" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015301385</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1627, Pages 1-242, 19 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        45) Suppressed expression of cystathionine f-synthase and smaller cerebellum in Wistar Kyoto rats<br />
        Original Research Article, Pages 208-213<br />
        Mao Nagasawa, Hiromi Ikeda, Takahiro Kawase, Ayaka Iwamoto, Shinobu Yasuo, Mitsuiro Furuse<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315005879" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315005879</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 309, Pages 1-280, 19 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        59) Spontaneous malformations of the cerebellar vermis: Prevalence, inheritance, and relationship to lobule/fissure organization in the C57BL/6 lineage<br />
        Original Research Article, Pages 242-251<br />
        S.E. Van Dine, N.Y. Siu, A. Toia, J.A. Cuoco, A.J. Betz, V.J. Bolivar, G. Torres, R.L. Ramos<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215008441" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215008441</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 607, Pages 1-124, 21 October 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Abnormally increased surface expression of AMPA receptors in the cerebellum, cortex and striatum of Cln3&minus;/&minus; mice<br />
        Original Research Article, Pages 29-34<br />
        Attila D. Kov&aacute;cs, Caitlin Hof, David A. Pearce<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015301385" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015301385</a>
      </p>
      <h3 id="2015-october">2015 October</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1624, Pages 1-544, 22 October 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        45) Suppressed expression of cystathionine &beta;-synthase and smaller cerebellum in Wistar Kyoto rats<br />
        Original Research Article, Pages 208-213<br />
        Mao Nagasawa, Hiromi Ikeda, Takahiro Kawase, Ayaka Iwamoto, Shinobu Yasuo, Mitsuiro Furuse<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315005879" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315005879</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE October 17, 2015 to October 23, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Periconception Maternal Folate Status and Human Embryonic Cerebellum Growth Trajectories: The Rotterdam Predict Study<br />
        Irene V. Koning, Irene A. L. Groenenberg, Anniek W. Gotink, Sten P. Willemsen, Manon Gijtenbeek, Jeroen Dudink, Attie T. J. I. Go, Irwin K. M. Reiss, Eric A. P. Steegers, R&eacute;gine P. M. Steegers-Theunissen<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0141089" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0141089</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 121, Pages 1-262, 1 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Striatal&ndash;cerebellar networks mediate consolidation in a motor sequence learning task: An fMRI study using dynamic causal modelling<br />
        Original Research Article, Pages 52-64<br />
        Elinor Tzvi, Anne Stoldt, Karsten Witt, Ulrike M. Kr&auml;mer<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915006977" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915006977</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 14 Number 5</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/14/5" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/14/5</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS Computational Biology Published October 16, 2015 to October 22, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        At the Edge of Chaos: How Cerebellar Granular Layer Network Dynamics Can Provide the Basis for Temporal Filters<br />
        Christian R&ouml;ssert, Paul Dean, John Porrill<br />
        - <a href="http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1004515" target="_blank" rel="noopener noreferrer">http://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1004515</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS Genetics Published October 16, 2015 to October 22, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Alkaline Ceramidase 3 Deficiency Results in Purkinje Cell Degeneration and Cerebellar Ataxia Due to Dyshomeostasis of Sphingolipids in the Brain<br />
        Kai Wang, Ruijuan Xu, Jennifer Schrandt, Prithvi Shah, Yong Z. Gong, Chet Preston, Louis Wang, Jae Kyo Yi, Chih-Li Lin, Wei Sun, Demetri D. Spyropoulos, Soyoung Rhee, Mingsong Li, Jie Zhou, Shaoyu Ge, Guofeng Zhang, Ashley J. Snider, Yusuf A. Hannun, Lina M. Obeid, Cungui Mao<br />
        - <a href="http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1005591" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1005591</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Proceedings of the National Academy of Sciences 20 October 2015; Vol. 112, No. 42</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Control of cerebellar granule cell output by sensory-evoked Golgi cell inhibition (Open Access Article)<br />
        Ian Duguid, Tiago Branco, Paul Chadderton, Charlotte Arlt, Kate Powell, and Michael H&auml;usser<br />
        - <a href="http://www.pnas.org/content/112/42/13099.abstract.html" target="_blank" rel="noopener noreferrer">http://www.pnas.org/content/112/42/13099.abstract.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 121, Pages 1-262, 1 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Striatal&ndash;cerebellar networks mediate consolidation in a motor sequence learning task: An fMRI study using dynamic causal modelling<br />
        Original Research Article, Pages 52-64<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915006977" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915006977</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex Table of Contents Alert Vol. 25, No. 11 November 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Interaction Between Hippocampus and Cerebellum Crus I in Sequence-Based but not Place-Based Navigation<br />
        Kinga Igl&oacute;i, Christian F. Doeller, Anne-Lise Paradis, Karim Benchenane, Alain Berthoz, Neil Burgess, and Laure Rondi-Reig<br />
        - <a href="https://cercor.oxfordjournals.org/content/25/11/4146.abstract" target="_blank" rel="noopener noreferrer">https://cercor.oxfordjournals.org/content/25/11/4146.abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neural Correlates of Animacy Attribution Include Neocerebellum in Healthy Adults<br />
        Allison Jack and Kevin A. Pelphrey<br />
        - <a href="https://cercor.oxfordjournals.org/content/25/11/4240.abstract" target="_blank" rel="noopener noreferrer">https://cercor.oxfordjournals.org/content/25/11/4240.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS Genetics Published October 09, 2015 to October 15, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Type IV Collagen Controls the Axogenesis of Cerebellar Granule Cells by Regulating Basement Membrane Integrity in Zebrafish<br />
        Miki Takeuchi, Shingo Yamaguchi, Shigenobu Yonemura, Kisa Kakiguchi, Yoshikatsu Sato, Tetsuya Higashiyama, Takashi Shimizu, Masahiko Hibi<br />
        - <a href="http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1005587" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1005587</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Nature contents: 15 October 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Encoding of action by the Purkinje cells of the cerebellum<br />
        David J. Herzfeld, Yoshiko Kojima, Robijanto Soetedjo et al.<br />
        - <a href="http://www.nature.com/nature/journal/v526/n7573/full/nature15693.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/nature/journal/v526/n7573/full/nature15693.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE October 03, 2015 to October 09, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Sulf1 and Sulf2 Differentially Modulate Heparan Sulfate Proteoglycan Sulfation during Postnatal Cerebellum Development: Evidence for Neuroprotective and Neurite Outgrowth Promoting Functions<br />
        Ina Kalus, Susanne Rohn, Tania M. Puvirajesinghe, Scott E. Guimond, Pieter J. Eyckerman-K&ouml;lln, Gerdy ten Dam, Toin H. van Kuppevelt, Jeremy E. Turnbull, Thomas Dierks<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0139853" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0139853</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 605, Pages 1-74, 25 September 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        33) Cerebellar vermis contributes to the extinction of conditioned fear<br />
        Original Research Article, Pages 173-177<br />
        A. Utz, M. Th&uuml;rling, T.M. Ernst, A. Hermann, R. Stark, O.T. Wolf, D. Timmann, C.J. Merz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S030439401530046X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S030439401530046X</a>
      </p>
      <h3 id="2015-september">2015 September</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>A new issue of Journal of Neurophysiology September 1, 2015; Vol. 114, No. 3</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Adaptation and aftereffects of split-belt walking in cerebellar lesion patients<br />
        Wouter Hoogkamer, Sjoerd M. Bruijn, Stefan Sunaert, Stephan P. Swinnen, Frank Van Calenbergh, and Jacques Duysens<br />
        - <a href="http://jn.physiology.org/content/114/3/1693.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/114/3/1693.abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Twitch-related and rhythmic activation of the developing cerebellar cortex<br />
        Greta Sokoloff, Alan M. Plumeau, Didhiti Mukherjee, and Mark S. Blumberg<br />
        - <a href="http://jn.physiology.org/content/114/3/1746.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/114/3/1746.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NATURE REVIEWS NEUROSCIENCE October 2015 Volume 16 Number 10</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neurodegenerative disease: Downsizing neurons<br />
        - <a href="http://www.nature.com/nrn/journal/v16/n10/full/nrn4027.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/nrn/journal/v16/n10/full/nrn4027.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 306, Pages 1-152, 15 October 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        16) Dissociable cerebellar activity during spatial navigation and visual memory in bilateral vestibular failure<br />
        Original Research Article, Pages 257-267<br />
        N.M. Jandl, A. Sprenger, J.F. Wojak, M. G&ouml;ttlich, T.F. M&uuml;nte, U.M. Kr&auml;mer, C. Helmchen<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215007198" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215007198</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        1) Alcohol hangover induces mitochondrial dysfunction and free radical production in mouse cerebellum<br />
        Original Research Article, Pages 47-59<br />
        A.G. Karadayian, J. Bustamante, A. Czerniczyniec, P. Lombardi, R.A. Cutrera, S. Lores-Arnaiz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006193" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006193</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Differential cerebellar GABA<sub>A</sub>receptor expression in mice with mutations in Ca<sub>V</sub>&nbsp;2.1 (P/Q-type) calcium channels<br />
        Original Research Article, Pages 198-208<br />
        S. Kaja, A.J. Payne, E.&Oslash;. Nielsen, C.L. Thompson, A.M.J.M. van den Maagdenberg, P. Koulen, T.P. Snutch<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006582" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006582</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        11) Electron tomographic structure and protein composition of isolated rat cerebellar, hippocampal and cortical postsynaptic densities<br />
        Original Research Article, Pages 286-301<br />
        M.M. Farley, M.T. Swulius, M.N. Waxham<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006867" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006867</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        23) Reactive nitrogen species mediate oxidative stress and astrogliosis provoked byin vivoadministration of phytanic acid in cerebellum of adolescent rats: A potential contributing pathomechanism of cerebellar injury in peroxisomal disorders<br />
        Original Research Article, Pages 122-132<br />
        C.G. Borges, C.R. Canani, C.G. Fernandes, &Acirc;. Zanatta, B. Seminotti, C.A.J. Ribeiro, G. Leipnitz, C.R. Vargas, M. Wajner<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006429" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006429</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Friday, 11 September 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Terra incognita&mdash;cerebellar contributions to neuropsychiatric and cognitive dysfunction in behavioral variant frontotemporal dementia<br />
        Rachel H Tan, Emma Devenney, Matthew C Kiernan, Glenda M Halliday, John R Hodges, and Michael Hornberger<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnagi.2015.00121/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnagi.2015.00121/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Reappraisal of Bergmann glial cells as modulators of cerebellar circuit function<br />
        Chris I De Zeeuw and Tycho M. Hoogland<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2015.00246/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2015.00246/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Direct and indirect spino-cerebellar pathways: shared ideas but different functions in motor control<br />
        Juan Jiang, Eiman Azim, Carl-Fredrik Ekerot, and Bror Alstermark<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncom.2015.00075/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncom.2015.00075/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1621, Pages 1-380, 24 September 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        2) Selective transgene expression in cerebellar Purkinje cells and granule cells using adeno-associated viruses together with specific promoters<br />
        Original Research Article, Pages 1-16<br />
        Yoonhee Kim, Taegon Kim, Jun Kyu Rhee, Dongwon Lee, Keiko Tanaka-Yamamoto, Yukio Yamamoto<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315004011" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315004011</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Physiology Accepted Articles</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Ca<sup>2+&nbsp;</sup>current facilitation determines short-term facilitation at inhibitory synapses between cerebellar Purkinje cells<br />
        Fran&ccedil;oise D&iacute;az-Rojas, Takeshi Sakaba and Shin-ya Kawaguchi<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1113/JP270704/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1113/JP270704/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 603, Pages 1-94, 31 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        33) Cerebellar vermis contributes to the extinction of conditioned fear<br />
        Original Research Article, Pages 173-177<br />
        A. Utz, M. Th&uuml;rling, T.M. Ernst, A. Hermann, R. Stark, O.T. Wolf, D. Timmann, C.J. Merz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S030439401530046X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S030439401530046X</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroReport October 21, 2015 - Volume 26 - Issue 15</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Propofol facilitates excitatory inputs of cerebellar Purkinje cells by depressing molecular layer interneuron activity during sensory information processing in vivo in mice<br />
        He, Yuan-Yuan; Jin, Ri; Jin, Wen-Zhe; Liu, Heng; Chu, Chun-Ping; Qiu, De-Lai<br />
        - <a href="http://journals.lww.com/neuroreport/Abstract/2015/10020/Propofol_facilitates_excitatory_inputs_of.7.aspx" target="_blank" rel="noopener noreferrer">http://journals.lww.com/neuroreport/Abstract/2015/10020/Propofol_facilitates_excitatory_inputs_of.7.aspx</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 9 September 2015, 35(36)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        ROR&alpha; Regulates Multiple Aspects of Dendrite Development in Cerebellar Purkinje Cells In Vivo<br />
        Yukari H. Takeo, Wataru Kakegawa, Eriko Miura, and Michisuke Yuzaki<br />
        - <a href="http://www.jneurosci.org/content/35/36/12518.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/36/12518.abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Correction: Mark et al., Spinocerebellar Ataxia Type 6 Protein Aggregates Cause Deficits in Motor Learning and Cerebellar Plasticity<br />
        The Journal of Neuroscience, 9 September 2015, 35(36):12606-12607;<br />
        - <a href="http://www.jneurosci.org/content/35/36/12606" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/36/12606</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuron Volume 87, Issue 4, Pages 673-908, 19 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        11) Neuroligins Sculpt Cerebellar Purkinje-Cell Circuits by Differential Control of Distinct Classes of Synapses<br />
        Original Research Article, Pages 781-796<br />
        Bo Zhang, Lulu Y. Chen, Xinran Liu, Stephan Maxeiner, Sung-Jin Lee, Ozgun Gokce, Thomas C. S&uuml;dhof<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S089662731500642X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S089662731500642X</a>
      </p>
      <h3 id="2015-august">2015 August</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 305, Pages 1-392, 1 October 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        23) Reactive nitrogen species mediate oxidative stress and astrogliosis provoked byin vivoadministration of phytanic acid in cerebellum of adolescent rats: A potential contributing pathomechanism of cerebellar injury in peroxisomal disorders<br />
        Original Research Article, Pages 122-132<br />
        C.G. Borges, C.R. Canani, C.G. Fernandes, &Acirc;. Zanatta, B. Seminotti, C.A.J. Ribeiro, G. Leipnitz, C.R. Vargas, M. Wajner<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006429" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006429</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        11) Electron tomographic structure and protein composition of isolated rat cerebellar, hippocampal and cortical postsynaptic densities<br />
        Original Research Article, Pages 286-301<br />
        M.M. Farley, M.T. Swulius, M.N. Waxham<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006867" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006867</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Differential cerebellar GABAAreceptor expression in mice with mutations in CaV 2.1 (P/Q-type) calcium channels<br />
        Original Research Article, Pages 198-208<br />
        S. Kaja, A.J. Payne, E.&Oslash;. Nielsen, C.L. Thompson, A.M.J.M. van den Maagdenberg, P. Koulen, T.P. Snutch<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006582" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006582</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        1) Alcohol hangover induces mitochondrial dysfunction and free radical production in mouse cerebellum<br />
        Original Research Article, Pages 47-59<br />
        A.G. Karadayian, J. Bustamante, A. Czerniczyniec, P. Lombardi, R.A. Cutrera, S. Lores-Arnaiz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006193" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006193</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        16) Dissociable cerebellar activity during spatial navigation and visual memory in bilateral vestibular failure<br />
        Original Research Article, Pages 257-267<br />
        N.M. Jandl, A. Sprenger, J.F. Wojak, M. G&ouml;ttlich, T.F. M&uuml;nte, U.M. Kr&auml;mer, C. Helmchen<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215007198" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215007198</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>

        <strong><em>NeuroReport September 30, 2015 - Volume 26 - Issue 14 pp: 803-881</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Clinical Neuroscience<br />
        Learning to live without the cerebellum<br />
        Arrigoni, Filippo; Romaniello, Romina; Nordio, Andrea; Gagliardi, Chiara; Borgatti, Renato<br />
        - <a href="http://journals.lww.com/neuroreport/Abstract/2015/10010/Learning_to_live_without_the_cerebellum.2.aspx" target="_blank" rel="noopener noreferrer">http://journals.lww.com/neuroreport/Abstract/2015/10010/Learning_to_live_without_the_cerebellum.2.aspx</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cellular, Molecular and Developmental Neuroscience<br />
        Possible involvement of the cerebellum in motor-function impairment in progranulin-deficient mice<br />
        Matsuwaki, Takashi; Kobayashi, Akira; Mase, Kanade; Nakamura, Katsuyuki; Nakano, Shin-Ichi; Miyoshi, Takahiro; Yamanouchi, Keitaro; Nishihara, Masugi<br />
        - <a href="http://journals.lww.com/neuroreport/Abstract/2015/10010/Possible_involvement_of_the_cerebellum_in.13.aspx" target="_blank" rel="noopener noreferrer">http://journals.lww.com/neuroreport/Abstract/2015/10010/Possible_involvement_of_the_cerebellum_in.13.aspx</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>

        <strong><em> Brain Research Volume 1621, Pages 1-380, 24 September 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        26) Cerebellar learning mechanisms<br />
        Review Article, Pages 260-269<br />
        John H. Freeman<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899314013353" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899314013353</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        2) Selective transgene expression in cerebellar Purkinje cells and granule cells using adeno-associated viruses together with specific promoters<br />
        Original Research Article, Pages 1-16<br />
        Yoonhee Kim, Taegon Kim, Jun Kyu Rhee, Dongwon Lee, Keiko Tanaka-Yamamoto, Yukio Yamamoto<br />
        -<a href="http://www.sciencedirect.com/science/article/pii/S0006899315004011" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315004011</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Wednesday, 26 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Differential Effects of Parietal and Cerebellar Stroke in Response to Object Location Perturbation<br />
        Trudy A Pelton, Alan M Wing, Dagmar Fraser, and Paulette Van Vliet<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2015.00293/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2015.00293/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NATURE NEUROSCIENCE September 2015 Volume 18 Number 9, pp 1191 - 1341</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Learning to expect the unexpected: rapid updating in primate cerebellum during voluntary self-motion pp1310 - 1317<br />
        Jessica X Brooks, Jerome Carriot and Kathleen E Cullen<br />
        - <a href="http://www.nature.com/neuro/journal/v18/n9/abs/nn.4077.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/neuro/journal/v18/n9/abs/nn.4077.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 24 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Facial stimulation induces long-term depression at cerebellar molecular layer interneuron&ndash;Purkinje cell synapses in vivo in mice<br />
        De-Lai Qiu, Yan-Hua Bing, Mao-Cheng Wu, and Chun-Ping Chu<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2015.00214/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2015.00214/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Brain Research Volume 1620, Pages 1-188, 16 September 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        2) Selective transgene expression in cerebellar Purkinje cells and granule cells using adeno-associated viruses together with specific promoters<br />
        Original Research Article, Pages 1-16<br />
        Yoonhee Kim, Taegon Kim, Jun Kyu Rhee, Dongwon Lee, Keiko Tanaka-Yamamoto, Yukio Yamamoto<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315004011" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315004011</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 304, Pages 1-378, 24 September 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        1) Alcohol hangover induces mitochondrial dysfunction and free radical production in mouse cerebellum<br />
        Original Research Article, Pages 47-59<br />
        A.G. Karadayian, J. Bustamante, A. Czerniczyniec, P. Lombardi, R.A. Cutrera, S. Lores-Arnaiz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006193" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006193</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        7) Differential cerebellar GABAAreceptor expression in mice with mutations in CaV 2.1 (P/Q-type) calcium channels<br />
        Original Research Article, Pages 198-208<br />
        S. Kaja, A.J. Payne, E.&Oslash;. Nielsen, C.L. Thompson, A.M.J.M. van den Maagdenberg, P. Koulen, T.P. Snutch<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006582" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006582</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        11) Electron tomographic structure and protein composition of isolated rat cerebellar, hippocampal and cortical postsynaptic densities<br />
        Original Research Article, Pages 286-301<br />
        M.M. Farley, M.T. Swulius, M.N. Waxham<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006867" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006867</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        23) Reactive nitrogen species mediate oxidative stress and astrogliosis provoked byin vivoadministration of phytanic acid in cerebellum of adolescent rats: A potential contributing pathomechanism of cerebellar injury in peroxisomal disorders<br />
        Original Research Article, Pages 122-132<br />
        C.G. Borges, C.R. Canani, C.G. Fernandes, &Acirc;. Zanatta, B. Seminotti, C.A.J. Ribeiro, G. Leipnitz, C.R. Vargas, M. Wajner<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215006429" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215006429</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Neuron Volume 87, Issue 3, Pages 463-672, 5 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        11) Neuroligins Sculpt Cerebellar Purkinje-Cell Circuits by Differential Control of Distinct Classes of Synapses<br />
        Original Research Article, Pages 781-796<br />
        Bo Zhang, Lulu Y. Chen, Xinran Liu, Stephan Maxeiner, Sung-Jin Lee, Ozgun Gokce, Thomas C. S&uuml;dhof<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S089662731500642X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S089662731500642X</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE August 10, 2015 to August 14, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Single Session Imaging of Cerebellum at 7 Tesla: Obtaining Structure and Function of Multiple Motor Subsystems in Individual Subjects<br />
        Melissa A. Batson, Natalia Petridou, Dennis W. J. Klomp, Maarten A. Frens, Sebastiaan F. W. Neggers<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0134933" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0134933</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Population Genetics and New Insight into Range of CAG Repeats of Spinocerebellar Ataxia Type 3 in the Han Chinese Population<br />
        Shi-Rui Gan, Wang Ni, Yi Dong, Ning Wang, Zhi-Ying Wu<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0134405" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0134405</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Wednesday, 12 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Parallel fiber to Purkinje cell synaptic impairment in a mouse model of spinocerebellar ataxia type 27<br />
        Filippo Tempia, Eriola Hoxha, Giulia Negro, Musaad A Alshammari, Tahani Alshammari, Neli Panova-Elektronova, and Fernanda Laezza<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2015.00205/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2015.00205/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 12 August 2015, 35(32)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Selective Loss of Presynaptic Potassium Channel Clusters at the Cerebellar Basket Cell Terminal Pinceau in Adam11 Mutants Reveals Their Role in Ephaptic Control of Purkinje Cell Firing<br />
        Matthew J. Kole, Jing Qian, Marc P. Waase, Tara L. Klassen, Tim T. Chen, George J. Augustine, and Jeffrey L. Noebels<br />
        - <a href="http://www.jneurosci.org/content/35/32/11433.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/32/11433.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroReport September 9, 2015 - Volume 26 - Issue 13 pp: 735-801</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Increased calcium in neurons in the cerebral cortex and cerebellum is not associated with cell loss in the mdx mouse model of Duchenne muscular dystrophy<br />
        Tuckett, Emma; Gosetti, Troy; Hayes, Alan; Rybalka, Emma; Verghese, Elizabeth<br />
        - <a href="http://journals.lww.com/neuroreport/Abstract/2015/09010/Increased_calcium_in_neurons_in_the_cerebral.9.aspx" target="_blank" rel="noopener noreferrer">http://journals.lww.com/neuroreport/Abstract/2015/09010/Increased_calcium_in_neurons_in_the_cerebral.9.aspx</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 5 August 2015, 35(31)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Inositol Hexakisphosphate Kinase-3 Regulates the Morphology and Synapse Formation of Cerebellar Purkinje Cells via Spectrin/Adducin<br />
        Chenglai Fu, Jing Xu, Ruo-Jing Li, Joshua A. Crawford, A. Basit Khan, Ting Martin Ma, Jiyoung Y. Cha, Adele M. Snowman, Mikhail V. Pletnikov, and Solomon H. Snyder<br />
        - <a href="http://www.jneurosci.org/content/35/31/11056.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/31/11056.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>A new issue of Journal of Neurophysiology May 1, 2015; Vol. 113, No. 9</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar control of saccade dynamics: contribution of the fastigial oculomotor region<br />
        Julie Quinet and Laurent Goffart<br />
        J Neurophysiol May 1, 2015 113:3323-3336; published ahead of print March 4, 2015, doi:10.1152/jn.01021.2014<br />
        - <a href="http://jn.physiology.org/content/113/9/3323.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/113/9/3323.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>A new issue of Journal of Neurophysiology April 1, 2015; Vol. 113, No. 7</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Neuronal Diversity: Categorizing Types of Neurons: Differential Purkinje cell simple spike activity and pausing behavior related to cerebellar modules<br />
        Haibo Zhou, Kai Voges, Zhanmin Lin, Chiheng Ju, and Martijn Schonewille<br />
        J Neurophysiol April 1, 2015 113:2524-2536;<br />
        - <a href="http://jn.physiology.org/content/113/7/2524.abstract" target="_blank" rel="noopener noreferrer">http://jn.physiology.org/content/113/7/2524.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE July 27, 2015 to July 31, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Kruppel-Like Factor 4 Regulates Granule Cell Pax6 Expression and Cell Proliferation in Early Cerebellar Development<br />
        Peter Zhang, Thomas Ha, Matt Larouche, Douglas Swanson, Dan Goldowitz<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0134390" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0134390</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Surface-Based Display of Volume-Averaged Cerebellar Imaging Data<br />
        J&ouml;rn Diedrichsen, Ewa Zotow<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0133402" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0133402</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Opinion in Neurobiology Volume 33, Pages 1-196, August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        20) Cerebellar damage impairs internal predictions for sensory and motor function<br />
        Review Article, Pages 127-133<br />
        Amanda S Therrien, Amy J Bastian<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0959438815000689" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0959438815000689</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        23) Regional functionality of the cerebellum<br />
        Review Article, Pages 150-155<br />
        Laurens Witter, Chris I De Zeeuw<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0959438815000720" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0959438815000720</a>
      </p>
      <h3 id="2015-july">2015 July</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1618, Pages 1-316, 27 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        2) Endurance training upregulates the nitric oxide/soluble guanylyl cyclase/cyclic guanosine 3&prime;,5&prime;-monophosphate pathway in the striatum, midbrain and cerebellum of male rats<br />
        Original Research Article, Pages 29-40<br />
        Małgorzata Chalimoniuk, Stanisław J. Chrapusta, Nadežda Lukačova, J&oacute;zef Langfort<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315004138" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315004138</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>eNeuro Alert to July 21</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Trace Eyeblink Conditioning in Mice Is Dependent upon the Dorsal Medial Prefrontal Cortex, Cerebellum, and Amygdala: Behavioral Characterization and Functional Circuitry<br />
        Jennifer J. Siegel, William Taylor, Richard Gray, Brian Kalmbach, Boris V. Zemelman, Niraj S. Desai, Daniel Johnston, Raymond A. Chitwood<br />
        - <a href="http://eneuro.org/content/2/4/ENEURO.0051-14.2015" target="_blank" rel="noopener noreferrer">http://eneuro.org/content/2/4/ENEURO.0051-14.2015</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 117, Pages 1-456, 15 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        32) Meta-analytic connectivity and behavioral parcellation of the human cerebellum<br />
        Original Research Article, Pages 327-342<br />
        Michael C. Riedel, Kimberly L. Ray, Anthony S. Dick, Matthew T. Sutherland, Zachary Hernandez, P. Mickle Fox, Simon B. Eickhoff, Peter T. Fox, Angela R. Laird<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915003778" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915003778</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE July 13, 2015 to July 17, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Role of Intermittent Hypoxia on the Proliferative Inhibition of Rat Cerebellar Astrocytes<br />
        Sheng-Chun Chiu, Yu-Jou Lin, Sung-Ying Huang, Chih-Feng Lien, Shee-Ping Chen, Cheng-Yoong Pang, Jian-Hong Lin, Kun-Ta Yang<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0132263" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0132263</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        A New Method for Functional Evaluation of Motor Commands in Patients with Cerebellar Ataxia<br />
        Jongho Lee, Yasuhiro Kagamihara, Shinji Kakei<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0132983" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0132983</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Predictor of Mortality within Six-Months in Patients with Spontaneous Cerebellar Hemorrhage: A Retrospective Study<br />
        Chih-Ya Chang, Ching-Yueh Lin, Liang-Cheng Chen, Chia-Hung Sun, Tsung-Ying Li, Tung-Han Tsai, Shin-Tsu Chang, Yung-Tsan Wu<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0132975" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0132975</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Volume 301, Pages 1-590, 20 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        15) Physical exercise improves brain cortex and cerebellum mitochondrial bioenergetics and alters apoptotic, dynamic and auto(mito)phagy markers<br />
        Original Research Article, Pages 480-495<br />
        I. Marques-Aleixo, E. Santos-Alves, M.M. Balca, D. Rizo-Roca, P.I. Moreira, P.J. Oliveira, J. Magalhaes, A. Ascensao<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0306452215005680" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0306452215005680</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Friday, 10 July 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Alternative kynurenic acid synthesis routes studied in the rat cerebellum<br />
        Tonali Blanco Ayala, Rafael Lugo Huitr&oacute;n, Liliana Aparicio Carmona, Daniela Ramirez Ortega, Dinora Gonzalez Esquivel, Jos&eacute; Pedraza Chaverr&iacute;, Gonzalo P&eacute;rez de la Cruz, Camilo Rios, Robert Schwarcz, and Ver&oacute;nica P&eacute;rez de la Cruz<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2015.00178/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2015.00178/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Anodal transcranial direct current stimulation to the cerebellum improves handwriting and cyclic drawing kinematics in focal hand dystonia<br />
        Lynley Bradnam, Lynton Graetz, Michelle Nadine McDonnell, and Michael Ridding<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2015.00286/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2015.00286/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 14 Number 4</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/14/4" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/14/4</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 6 July 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Behavioral Neuroscience<br />
        Mutation-related differences in exploratory, spatial, and depressive-like behavior in pcd and Lurcher cerebellar mutant mice<br />
        Jan Tuma, Yaroslav Kolinko, Frantisek Vozeh, and Jan Cendelin<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnbeh.2015.00116/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnbeh.2015.00116/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Systems Neuroscience<br />
        &ldquo;And the little brain said to the big brain&hellip;&rdquo; Editorial: Distributed networks: new outlooks on cerebellar function<br />
        Thomas C Watson<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnsys.2015.00078/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnsys.2015.00078/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroanatomy<br />
        Cytoarchitectonic mapping of the human brain cerebellar nuclei in stereotaxic space and delineation of their co-activation patterns<br />
        Stefanie Tellmann, Sebastian Bludau, Simon B. Eickhoff, Hartmut Mohlberg, Martina Minnerop, and Katrin Amunts<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2015.00054/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2015.00054/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE June 25, 2015 to July 01, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Successful Working Memory Processes and Cerebellum in an Elderly Sample: A Neuropsychological and fMRI Study<br />
        Elkin O. Luis, Gonzalo Arrondo, Marta Vidorreta, Martin Mart&iacute;nez, Francis Loayza, Mar&iacute;a A. Fern&aacute;ndez-Seara, Mar&iacute;a A. Pastor<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0131536" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0131536</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 1 July 2015, 35(26)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        &alpha;6-Containing GABAA Receptors Are the Principal Mediators of Inhibitory Synapse Strengthening by Insulin in Cerebellar Granule Cells<br />
        Michael V. Accardi, Patricia M.G.E. Brown, Lo&iuml;s S. Miraucourt, Beverley A. Orser, and Derek Bowie<br />
        - <a href="http://www.jneurosci.org/content/35/26/9676.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/26/9676.abstract</a>
      </p>
      <h3 id="2015-june">2015 June</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 30 June 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Behavioral Neuroscience<br />
        Cerebellar damage impairs the self-rating of regret feeling in a gambling task<br />
        Silvia Clausi, Giorgio Coricelli, Iolanda Pisotta, Enea Francesco Pavone, Marco Lauriola, Marco Molinari, and Maria Leggio<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnbeh.2015.00113/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnbeh.2015.00113/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Integrated plasticity at inhibitory and excitatory synapses in the cerebellar circuit<br />
        Lisa Mapelli, Martina Pagani, Jesus Alcazar Garrido, and Egidio D&lsquo;Angelo<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2015.00169/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2015.00169/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neural Circuits<br />
        A realistic bi-hemispheric model of the cerebellum uncovers the purpose of the abundant granule cells during motor control<br />
        Ruben Dario Pinzon Morales and Yutaka Hirata<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncir.2015.00018/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncir.2015.00018/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE June 18, 2015 to June 24, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Dicer Is Required for Normal Cerebellar Development and to Restrain Medulloblastoma Formation<br />
        Frederique Zindy, Youngsoo Lee, Daisuke Kawauchi, Olivier Ayrault, Leila Ben Merzoug, Yang Li, Peter J. McKinnon, Martine F. Roussel<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0129642" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0129642</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience Volume 41, Issue 12 Pages 1506 - 1634, June 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Genetic mapping of ASIC4 and contrasting phenotype to ASIC1a in modulating innate fear and anxiety (pages 1553&ndash;1568)<br />
        Shing-Hong Lin, Ya-Chih Chien, Wei-Wei Chiang, Yan-Zhen Liu, Cheng-Chang Lien and Chih-Cheng Chen<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.12905/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.12905/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Proceedings of the National Academy of Sciences 16 June 2015; Vol. 112, No. 24</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Liver X receptors alpha and beta promote myelination and remyelination in the cerebellum<br />
        Delphine Meffre, Ghjuvan&rsquo;Ghjacumu Shackleford, Mehdi Hichor, Victor Gorgievski, Eleni T. Tzavara, Amalia Trousson, Abdel M. Ghoumari, Cyrille Deboux, Brahim Nait Oumesmar, Philippe Liere, Michael Schumacher, Etienne-Emile Baulieu, Fr&eacute;d&eacute;ric Charbonnier, Julien Grenier, and Charbel Massaad<br />
        - <a href="http://www.pnas.org/content/112/24/7587.abstract.html" target="_blank" rel="noopener noreferrer">http://www.pnas.org/content/112/24/7587.abstract.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NeuroImage Volume 116, Pages 1-256, 1 August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        20) Ageing shows a pattern of cerebellar degeneration analogous, but not equal, to that in patients suffering from cerebellar degenerative disease<br />
        Original Research Article, Pages 196-206<br />
        Thomas Hulst, Jos N. van der Geest, M. Thurling, S. Goericke, Maarten A. Frens, Dagmar Timmann, Opher Donchin<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S1053811915003201" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S1053811915003201</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> The Journal of Neuroscience, 10 June 2015, 35(23)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Loss of the Neuron-Specific F-Box Protein FBXO41 Models an Ataxia-Like Phenotype in Mice with Neuronal Migration Defects and egeneration in the Cerebellum<br />
        Chaitali Mukherjee, Anna Holubowska, Nicola Schwedhelm-Domeyer, Miso Mitkovski, Shih-Ju Lee, Madhuvanthi Kannan, Annika Matz, Mayur Vadhvani, and Judith Stegm&uuml;ller<br />
        - <a href="http://www.jneurosci.org/content/35/23/8701.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/23/8701.abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Spinocerebellar Ataxia Type 6 Protein Aggregates Cause Deficits in Motor Learning and Cerebellar Plasticity<br />
        Melanie D. Mark, Martin Krause, Henk-Jan Boele, Wolfgang Kruse, Stefan Pollok, Thomas Kuner, Deniz Dalkara, Sebastiaan Koekkoek, Chris I. De Zeeuw, and Stefan Herlitze<br />
        - <a href="http://www.jneurosci.org/content/35/23/8882.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/23/8882.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuropharmacology Volume 95, Pages 1-510, August 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        48) MAM-2201, a synthetic cannabinoid drug of abuse, suppresses the synaptic input to cerebellar Purkinje cells via activation of presynaptic CB1 receptors<br />
        Original Research Article, Pages 479-491<br />
        Tomohiko Irie, Ruri Kikura-Hanajiri, Makoto Usami, Nahoko Uchiyama, Yukihiro Goda, Yuko Sekino<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0028390815000817" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0028390815000817</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Cerebral Cortex Table of Contents Alert Vol. 25, No. 7 July 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Laterality Differences in Cerebellar&ndash;Motor Cortex Connectivity<br />
        John E. Schlerf, Joseph M. Galea, Danny Spampinato, and Pablo A. Celnik<br />
        - <a href="https://cercor.oxfordjournals.org/content/25/7/1827.abstract" target="_blank" rel="noopener noreferrer">https://cercor.oxfordjournals.org/content/25/7/1827.abstract</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Evidence for Cerebellar Contributions to Adaptive Plasticity in Speech Perception<br />
        Sara Guediche, Lori L. Holt, Patryk Laurent, Sung-Joo Lim, and Julie A. Fiez<br />
        - <a href="https://cercor.oxfordjournals.org/content/25/7/1867.abstract" target="_blank" rel="noopener noreferrer">https://cercor.oxfordjournals.org/content/25/7/1867.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 2 June 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Altered cerebellar connectivity in Parkinson&rsquo;s patients ON and OFF L-DOPA medication<br />
        Sara B Festini, Jessica A Bernard, Youngbin Kwak, Scott Peltier, Nicolaas I Bohnen, Martijn L. T. M. M&uuml;ller, Praveen Dayalu, and Rachael D Seidler<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2015.00214/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2015.00214/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1612, Pages 1-152, 1 July 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        5) Neural correlates of impaired emotional face recognition in cerebellar lesions<br />
        Original Research Article, Pages 1-12<br />
        Michael Adamaszek, Kenneth C Kirkby, Fedrico D׳Agata, Sebastian Olbrich, Sonke Langner, Christopher Steele, Bernhard Sehm, Stefan Busse, Christof Kessler, Alfons Hamm<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315000475" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315000475</a>
      </p>
      <h3 id="2015-may">2015 May</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1614, Pages 1-112, 21 July 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        4) Developmental exposure to ethanol increases the neuronal vulnerability to oxygen&ndash;glucose deprivation in cerebellar granule cell cultures<br />
        Original Research Article, Pages 1-13<br />
        Diana Le Duc, Ana Spataru, Mihai Ceanga, Leon Zagrean, Torsten Schoneberg, Emil C. Toescu, Ana-Maria Zagrean<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S000689931500298X" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S000689931500298X</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        5) The effect of cyclic phosphatidic acid on the proliferation and differentiation of mouse cerebellar granule precursor cells during cerebellar development<br />
        Original Research Article, Pages 28-37<br />
        Misa Konakazawa, Mari Gotoh, Kimiko Murakami-Murofushi, Ayana Hamano, Yasunori Miyamoto<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315003029" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315003029</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Cerebellum. Volume 14 Number 3</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        - <a href="http://link.springer.com/journal/12311/14/3" target="_blank" rel="noopener noreferrer">http://link.springer.com/journal/12311/14/3</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE May 14, 2015 to May 22, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Cerebellar-Dependent Associative Learning Is Preserved in Duchenne Muscular Dystrophy: A Study Using Delay Eyeblink Conditioning<br />
        Ulrike Schara, Melanie Busse, Dagmar Timmann, Marcus Gerwig<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0126528" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0126528</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Thursday, 21 May 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Force dysmetria in spinocerebellar ataxia 6 correlates with functional capacity<br />
        Agostina Casamento Moran, Yen-Ting Chen, MinHyuk Kwon, Amy Snyder, S H Subramony, David Vaillancourt, and Evangelos A Christou<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2015.00184/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2015.00184/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Neuroanatomy<br />
        Diffusion tensor imaging of the human cerebellar pathways and their interplay with cerebral macrostructure<br />
        Zafer Keser, Khader M Hasan, Benson Mwangi, Arash Kamali, Fehime Eymen Ucisik-Keser, Roy F Riascos, Nuray Yozbatiran, Gerard E. Francisco, and Ponnada Narayana<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnana.2015.00041/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnana.2015.00041/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 20 May 2015, 35(20)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Relating Cerebellar Purkinje Cell Activity to the Timing and Amplitude of Conditioned Eyelid Responses<br />
        Hunter E. Halverson, Andrei Khilkevich, and Michael D. Mauk<br />
        - <a href="http://www.jneurosci.org/content/35/20/7813.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/20/7813.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Brain Research Volume 1611, Pages 1-114, 22 June 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        10) Dystonia and cerebellar degeneration in theleanermouse mutant<br />
        Original Research Article, Pages 56-64<br />
        Robert S. Raike, Ellen J. Hess, H.A. Jinnah<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0006899315001936" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0006899315001936</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience Volume 41, Issue 10 Pages 1263 - 1391, May 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Differentiating neural systems mediating the acquisition vs. expression of goal-directed and habitual behavioral control (pages 1358&ndash;1371)<br />
        Mimi Liljeholm, Simon Dunne and John P. O&rsquo;Doherty<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.12897/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.12897/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 13 May 2015, 35(19)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Heterogeneity and Bipotency of Astroglial-Like Cerebellar Progenitors along the Interneuron and Glial Lineages<br />
        Elena Parmigiani, Ketty Leto, Chiara Rolando, Mar&iacute;a Figueres-O&ntilde;ate, Laura L&oacute;pez-Mascaraque, Annalisa Buffo, and Ferdinando Rossi<br />
        - <a href="http://www.jneurosci.org/content/35/19/7388.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/19/7388.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE April 30, 2015 to May 06, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Caffeine Modulates Vesicle Release and Recovery at Cerebellar Parallel Fibre Terminals, Independently of Calcium and Cyclic AMP Signalling<br />
        Katharine L. Dobson, Claire Jackson, Saju Balakrishnan, Tomas C. Bellamy<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0125974" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0125974</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 6 May 2015, 35(18)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        BK Channels Localize to the Paranodal Junction and Regulate Action Potentials in Myelinated Axons of Cerebellar Purkinje Cells<br />
        Moritoshi Hirono, Yasuhiro Ogawa, Kaori Misono, Daniel R. Zollinger, James S. Trimmer, Matthew N. Rasband, and Hiroaki Misonou<br />
        - <a href="http://www.jneurosci.org/content/35/18/7082.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/18/7082.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Current Biology Volume 25, Issue 9, Pages R345-R390, 1113-1248, 4 May 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        22) Role of Synchronous Activation of Cerebellar Purkinje Cell Ensembles in Multi-joint Movement Control<br />
        Original Research Article, Pages 1157-1165<br />
        Tycho M. Hoogland, Jornt R. De Gruijl, Laurens Witter, Cathrin B. Canto, Chris I. De Zeeuw<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0960982215003231" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0960982215003231</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Monday, 4 May 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Shaping pseudoneglect with transcranial cerebellar direct current stimulation and music listening<br />
        Silvia Picazio, Chiara Granata, Carlo Caltagirone, Laura Petrosini, and Massimiliano Oliveri<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2015.00158/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2015.00158/full</a>
      </p>
      <h3 id="2015-april">2015 April</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Frontiers Article Alert Wednesday, 29 April 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Cellular Neuroscience<br />
        Dendritic patch-clamp recordings from cerebellar granule cells demonstrate electrotonic compactness<br />
        Igor Delvendahl, Isabelle Straub, and Stefan Hallermann<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncel.2015.00093/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncel.2015.00093/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Computational Neuroscience<br />
        How the credit assignment problems in motor control could be solved after the cerebellum predicts increases in error<br />
        Sergio Oscar Verduzco-Flores and Randall C O&rsquo;Reilly<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncom.2015.00039/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncom.2015.00039/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Frontiers in Human Neuroscience<br />
        Aberrant cerebellar connectivity in motor and association networks in schizophrenia<br />
        Ann K. Shinn, Justin T. Baker, Kathryn Eve Lewandowski, Dost Ongur, and Bruce M. Cohen<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnhum.2015.00134/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnhum.2015.00134/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience, 29 April 2015, 35(17)</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Intracellular FGF14 (iFGF14) Is Required for Spontaneous and Evoked<br />
        Firing in Cerebellar Purkinje Neurons and for Motor Coordination and<br />
        Balance<br />
        Marie K. Bosch, Yarimar Carrasquillo, Joseph L. Ransdell, Ajay<br />
        Kanakamedala, David M. Ornitz, and Jeanne M. Nerbonne<br />
        - <a href="http://www.jneurosci.org/content/35/17/6752.abstract?etoc" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/17/6752.abstract?etoc</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Neuroscience Letters Volume 595, Pages 1-138, 19 May 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        5) The effect of carmustine on Bergmann cells of the cerebellum Pages 18-24<br />
        Maria Alejandra Gonzalez-Gonzalez, Aline Ostos-Valverde, Armando Becerra-Hernandez, Hugo Sanchez-Castillo, Ataulfo Martinez-Torres<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015002700" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015002700</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        26) Molecular composition and expression pattern of the extracellular matrix in a mossy fiber-generating precerebellar nucleus of rat, the prepositus hypoglossi Pages 122-126<br />
        Botond Gaal, Szilvia Kecskes, Clara Matesz, Andras Birinyi, Andrea Hunyadi, Eva Racz<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015002499" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015002499</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>NATURE NEUROSCIENCE May 2015 Volume 18 Number 5, pp 611 - 786</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Strength in more than numbers pp614 - 616<br />
        Nathaniel B Sawtell and L F Abbott<br />
        - <a href="http://www.nature.com/neuro/journal/v18/n5/abs/nn.4006.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/neuro/journal/v18/n5/abs/nn.4006.html</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Regulation of chromatin accessibility and Zic binding at enhancers in the developing cerebellum pp647 - 656<br />
        Christopher L Frank et al.<br />
        - <a href="http://www.nature.com/neuro/journal/v18/n5/abs/nn.3995.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/neuro/journal/v18/n5/abs/nn.3995.html</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        GABAergic regulation of cerebellar NG2 cell development is altered in perinatal white matter injury pp674 - 682<br />
        Marzieh Zonouzi et al.<br />
        - <a href="http://www.nature.com/neuro/journal/v18/n5/abs/nn.3990.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/neuro/journal/v18/n5/abs/nn.3990.html</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Synaptic diversity enables temporal coding of coincident multisensory inputs in single neurons pp718 - 727<br />
        Francois P Chabrol et al.<br />
        - <a href="http://www.nature.com/neuro/journal/v18/n5/abs/nn.3974.html" target="_blank" rel="noopener noreferrer">http://www.nature.com/neuro/journal/v18/n5/abs/nn.3974.html</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE April 16, 2015 to April 22, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Anti-Yo Antibody Uptake and Interaction with Its Intracellular Target Antigen Causes Purkinje Cell Death in Rat Cerebellar Slice Cultures: A Possible Mechanism for Paraneoplastic Cerebellar Degeneration in Humans with Gynecological or Breast Cancers<br />
        John E. Greenlee, Susan A. Clawson, Kenneth E. Hill, Blair Wood, Stacey L. Clardy, Ikuo Tsunoda, Noel G. Carlson<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0123446" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0123446</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Change in the Cortical Complexity of Spinocerebellar Ataxia Type 3 Appears Earlier than Clinical Symptoms<br />
        Tzu-Yun Wang, Chii-Wen Jao, Bing-Wen Soong, Hsiu-Mei Wu, Kuo-Kai Shyu, Po-Shan Wang, Yu-Te Wu<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0118828" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0118828</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Neuron Volume 86, Issue 2, Pages 343-602, 22 April 2015 </em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        18) Circuit Mechanisms Underlying Motor Memory Formation in the Cerebellum<br />
        Original Research Article,<br />
        Pages 529-540<br />
        Ka Hung Lee, Paul J. Mathews, Alexander M.B. Reeves, Katrina Y. Choe, Shekib A. Jami, Raul E. Serrano, Thomas S. Otis<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0896627315002068" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0896627315002068</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>European Journal of Neuroscience</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Differentiating neural systems mediating the acquisition vs. expression of goal-directed and habitual behavioral control<br />
        Mimi Liljeholm, Simon Dunne and John P. O&rsquo;Doherty<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/ejn.12897/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/ejn.12897/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Annals of the New York Academy of Sciences Volume 1343, Issue 1 Dizziness and Balance Disorders Pages 1 - 119, April 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Pharmacotherapy of vestibular and cerebellar disorders and downbeat nystagmus: translational and back-translational research (pages 27&ndash;36)<br />
        Michael Strupp, Andreas Zwergal, Katharina Feil, Tatiana Bremova and Thomas Brandt<br />
        - <a href="http://onlinelibrary.wiley.com/doi/10.1111/nyas.12774/abstract" target="_blank" rel="noopener noreferrer">http://onlinelibrary.wiley.com/doi/10.1111/nyas.12774/abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em> Frontiers Article Alert Wednesday, 15 April 2015 </em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        High frequency switched-mode stimulation can evoke post synaptic responses in cerebellar principal neurons<br />
        Marijn Van Dongen, Freek Hoebeek, S K E Koekkoek, Chris De Zeeuw, and Wouer Serdijn<br />
        <a href="http://journal.frontiersin.org/article/10.3389/fneng.2015.00002/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fneng.2015.00002/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE April 02, 2015 to April 08, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Meningiomas of the Cerebellopontine Angle: Radiological Differences in Tumors with Internal Auditory Canal Involvement and Their Influence on Surgical Outcome<br />
        Kun Gao, Housheng Ma, Yong Cui, Xuzhu Chen, Jun Ma, Jianping Dai<br />
        <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0122949" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0122949</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Metabolomic Method: UPLC-q-ToF Polar and Non-Polar Metabolites in the Healthy Rat Cerebellum Using an In-Vial Dual Extraction<br />
        Amera A. Ebshiana, Stuart G. Snowden, Madhav Thambisetty, Richard Parsons, Abdul Hye, Cristina Legido-Quigley<br />
        <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0122883" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0122883</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>The Journal of Neuroscience Online Table of Contents Alert 8 April 2015; Vol. 35, No. 14</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        The Role of the Posterior Cerebellum in Saccadic Adaptation: A Transcranial Direct Current Stimulation Study<br />
        Muriel T.N. Panouill&egrave;res, R. Chris Miall, and Ned Jenkinson<br />
        - <a href="http://www.jneurosci.org/content/35/14/5471.abstract" target="_blank" rel="noopener noreferrer">http://www.jneurosci.org/content/35/14/5471.abstract</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuroscience Letters Volume 593, Pages 1-134, 23 April 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        25) Muscarinic acetylcholine receptor in cerebellar cortex participates in acetylcholine-mediated blood depressor response in rats<br />
        Pages 129-133<br />
        Peiling Zhou, Qingfeng Zhu, Ming Liu, Jing Li, Yong Wang, Changzheng Zhang, Tianmiao Hua<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0304394015002293" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0304394015002293</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Frontiers Article Alert Tuesday, 7 April 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Distributed cerebellar plasticity implements generalized multiple-scale memory components in real-robot sensorimotor tasks<br />
        Claudia Casellato, Alberto Antonietti, Jesus A Garrido, Giancarlo Ferrigno, Egidio D&lsquo;Angelo, and Alessandra Pedrocchi<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fncom.2015.00024/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fncom.2015.00024/full</a>
      </p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Properties and molecular identity of NMDA receptors at synaptic and non-synaptic inputs in cerebellar molecular layer interneurons<br />
        Celine Bidoret, Guy Bouvier, Annick Ayon, German Szapiro, and Mariano Casado<br />
        - <a href="http://journal.frontiersin.org/article/10.3389/fnsyn.2015.00001/full" target="_blank" rel="noopener noreferrer">http://journal.frontiersin.org/article/10.3389/fnsyn.2015.00001/full</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE March 26, 2015 to April 01, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        Material Properties and Constitutive Modeling of Infant Porcine Cerebellum Tissue in Tension at High Strain Rate<br />
        Kui Li, Hui Zhao, Wenjun Liu, Zhiyong Yin<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0123506" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0123506</a>
      </p>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>Neuropharmacology Volume 93, Pages 1-314, June 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        21) Pharmacological characterization of mGlu1 receptors in cerebellar granule cells reveals biased agonism<br />
        Original Research Article,<br />
        Pages 199-208<br />
        Hannah A. Hathaway, Sergey Pshenichkin, Ewa Grajkowska, Tara Gelb, Andrew C. Emery, Barry B. Wolfe, Jarda T. Wroblewski<br />
        - <a href="http://www.sciencedirect.com/science/article/pii/S0028390815000544" target="_blank" rel="noopener noreferrer">http://www.sciencedirect.com/science/article/pii/S0028390815000544</a>
      </p>
      <h3 id="2015-march">2015 March</h3>
      <p style={{ marginLeft: "40px" }}>
        <strong><em>New Articles in PLOS ONE March 19, 2015 to March 24, 2015</em></strong></p>
      <p style={{ marginLeft: "55px", marginBottom: "25px" }}>
        N-Acetyl-L-Leucine Accelerates Vestibular Compensation after Unilateral Labyrinthectomy by Action in the Cerebellum and Thalamus<br />
        Lisa G&uuml;nther, Roswitha Beck, Guoming Xiong, Heidrun Potschka, Klaus Jahn, Peter Bartenstein, Thomas Brandt, Mayank Dutia, Marianne Dieterich, Michael Strupp, Christian la Foug&egrave;re, Andreas Zwergal<br />
        - <a href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0120891" target="_blank" rel="noopener noreferrer">http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0120891</a>
      </p>
    </>
  );
}

export default NewArticleAlert;