import React from 'react';
import { MultiLang } from '../config';
import Functions from '../functions';
import bannerCBS from './assets/theme/images/banner-riken-cbs.png';
import bannerRIKEN from './assets/theme/images/banner-riken.png';
import bannerXooNIps from './assets/theme/images/banner-xoonips.png';
import LinkImage from './lib/LinkImage';

interface Props {
    lang: MultiLang;
}

const Footer = (props: Props) => {
    const { lang } = props;
    return (
        <footer id="footer">
            <div className="links">
                <LinkImage image={bannerRIKEN} title={Functions.mlang('[en]RIKEN[/en][ja]理化学研究所[/ja]', lang)} url={'http://www.riken.jp/'} />
                <LinkImage image={bannerCBS} title={Functions.mlang('[en]RIKEN Center for Brain Science[/en][ja]理化学研究所 脳神経科学研究センター[/ja]', lang)} url={'https://cbs.riken.jp/'} />
                <LinkImage image={bannerXooNIps} title={Functions.mlang('[en]The XooNIps Project[/en][ja]XooNIps 公式サイト[/ja]', lang)} url={'https://xoonips.osdn.jp/'} />
            </div>
            <div className="copyright">Copyright (C) 2018 Neuroinformatics Unit, RIKEN Center for Brain Science</div>
        </footer>
    );
}

export default Footer;
