import React from 'react';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../../config';

interface Props {
    lang: MultiLang;
}

const Welcome = (props: Props) => {
    const { lang } = props;
    return (
        <>
            <NoticeSiteHasBeenArchived lang={lang} />
            <p>
                Cerebellar Platform is a digital research archive for cerebellar research.
                Available resources include mini-reviews of contemporary cerebellar research, list of papers and mathematical models for cerebellar operation.
                If you need more information about this website, please click <Link to="/credits/1">HERE</Link>.
                You will find a 'Privacy Policy' and some instructions for use.
                This database was supported by Grant-in-Aid for Publication of Scientific Research Results from Japan Society for the Promotion of Science (2007).
            </p>
            <p>
                <a href="https://pf.cerebellum.neuroinf.jp/" target="_blank" rel="noopener noreferrer">New "Cerebellar Platform"</a> provides utilities (SNS and a cloud system) for anyone who is interested in the cerebellum.
                It provides you focused and up-to-date information related to the cerebellum easily and quickly.
                It also features a communication field like FaceBook, which is designed to accelerate interaction between cerebellar researchers.
                <a href="https://pf.cerebellum.neuroinf.jp/" target="_blank" rel="noopener noreferrer">Please click HERE!</a>
            </p>
        </>
    );
}

export default Welcome;