import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import D3ForumUtils, { D3ForumCategoryData, D3ForumForumData, D3ForumPostData, D3ForumPostSortOrder, D3ForumTopicData } from './lib/D3ForumUtils';
import ForumJump from './lib/ForumJump';
import Post from './lib/Post';
import PostsTree from './lib/PostsTree';

interface Params {
    id?: string;
}

interface Props extends RouteComponentProps<Params> {
    lang: MultiLang;
    name: string;
}

interface State {
    loading: boolean;
    name: string;
    topicId: number;
    order: D3ForumPostSortOrder;
}

class D3ForumTopic extends Component<Props, State> {

    private topic: D3ForumTopicData | null = null;
    private forum: D3ForumForumData | null = null;
    private category: D3ForumCategoryData | null = null;
    private posts: D3ForumPostData[] = [];

    constructor(props: Props) {
        super(props);
        const { name } = props;
        this.state = {
            loading: true,
            name: name,
            topicId: 0,
            order: D3ForumPostSortOrder.TREE,
        };
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const params = new URLSearchParams(nextProps.location.search);
        const topicId = typeof nextProps.match.params.id === 'undefined' ? 0 : parseInt(nextProps.match.params.id, 10);
        const order_ = params.get('order');
        const order = (order_ !== null && order_.match(/(0|1|2)/)) ? parseInt(order_, 10) as D3ForumPostSortOrder : D3ForumPostSortOrder.TREE;
        if (nextProps.name !== prevState.name || prevState.topicId !== topicId || prevState.order !== order) {
            return { loading: true, name: nextProps.name, topicId, order };
        }
        return null;
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate() {
        this.load();
    }

    load() {
        const { name } = this.props;
        if (this.state.loading) {
            this.topic = D3ForumUtils.getTopic(name, this.state.topicId);
            this.forum = D3ForumUtils.getForum(name, this.topic === null ? 0 : this.topic.forum_id);
            this.category = D3ForumUtils.getCategory(name, this.forum === null ? 0 : this.forum.cat_id);
            this.posts = D3ForumUtils.getPosts(name, this.state.topicId, this.state.order);
            this.setState({ loading: false });
        }
    }

    render() {
        const { lang, name } = this.props;
        if (this.state.loading) {
            return <Loading />;
        }
        if (this.topic === null || this.forum === null || this.category === null) {
            return <PageNotFound lang={lang} />;
        }
        const orderCtrl = (
            <div className="clearfix">
                <div className="d3f_order_ctrl">
                    {this.state.order !== D3ForumPostSortOrder.TREE && <HashLink to={D3ForumUtils.getTopicUrl(name, this.topic.topic_id, D3ForumPostSortOrder.TREE, null)}>{Functions.mlang('[en]Tree order[/en][ja]ツリー構造順で表示[/ja]', lang)}</HashLink>}
                    {this.state.order !== D3ForumPostSortOrder.OLD && <HashLink to={D3ForumUtils.getTopicUrl(name, this.topic.topic_id, D3ForumPostSortOrder.OLD, null)}>{Functions.mlang('[en]Older is upper[/en][ja]投稿の古いものから[/ja]', lang)}</HashLink>}
                    {this.state.order !== D3ForumPostSortOrder.NEW && <HashLink to={D3ForumUtils.getTopicUrl(name, this.topic.topic_id, D3ForumPostSortOrder.NEW, null)}>{Functions.mlang('[en]Newer is upper[/en][ja]投稿の新しいものから[/ja]', lang)}</HashLink>}
                </div>
            </div>
        );
        const title = Functions.mlang(this.topic.topic_title, lang);
        return (
            <>
                <Helmet>
                    <title>{title} - {D3ForumUtils.getTitle(name, lang)} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <div className="d3f_breadcrumbs">
                    <HashLink to={D3ForumUtils.getIndexUrl(name)}>{Functions.mlang('[en]Top[/en][ja]トップ[/ja]', lang)}</HashLink>
                    &nbsp;&gt;&nbsp;
                    <HashLink to={D3ForumUtils.getCategoryUrl(name, this.category.cat_id)}>{Functions.mlang(this.category.cat_title, lang)}</HashLink>
                    &nbsp;&gt;&nbsp;
                    <HashLink to={D3ForumUtils.getForumUrl(name, this.forum.forum_id)}>{Functions.mlang(this.forum.forum_title, lang)}</HashLink>
                </div>
                <h1 className="d3f_title">{title}</h1>
                <PostsTree lang={lang} name={name} posts={this.posts} postId={0} />
                {orderCtrl}
                <div className="d3f_wrap">
                    {this.posts.map((post, idx) => {
                        const prevPostId = idx === 0 ? 0 : this.posts[idx - 1].post_id;
                        const nextPostId = (idx + 1) === this.posts.length ? 0 : this.posts[idx + 1].post_id;
                        return <Post key={post.post_id} lang={lang} name={name} post={post} prevPostId={prevPostId} nextPostId={nextPostId} inTopic={true} />;
                    })}
                </div>
                {orderCtrl}
                <ForumJump lang={lang} name={name} forumId={this.forum.forum_id} />
            </>
        );
    }
}

export default D3ForumTopic;
