import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import imageCategory0 from './assets/images/category_0.gif';
import imageCategory1 from './assets/images/category_1.gif';
import imageForum0 from './assets/images/forum_0.gif';
import imageForum1 from './assets/images/forum_1.gif';
import CategoryJump from './lib/CategoryJump';
import D3ForumUtils, { D3ForumCategoryData, D3ForumForumData } from './lib/D3ForumUtils';
import PostIcon from './lib/PostIcon';

interface Params {
    id?: string;
}
interface Props extends RouteComponentProps<Params> {
    lang: MultiLang;
    name: string;
}

interface D3ForumCategoryProps {
    lang: MultiLang;
    name: string;
    catId: number;
}

const D3ForumCategorySubCategory = (props: D3ForumCategoryProps) => {
    const { lang, name, catId } = props;
    const LABEL_HEADER_TITLE = Functions.mlang('[en]Subcategories[/en][ja]サブカテゴリ[/ja]', lang);
    const LABEL_TOTAL_TOPICS = Functions.mlang('[en]Total topics[/en][ja]総トピック数[/ja]', lang);
    const LABEL_TOTAL_POSTS = Functions.mlang('[en]Total posts[/en][ja]総投稿数[/ja]', lang);
    const LABEL_LATEST_POST = Functions.mlang('[en]Latest post[/en][ja]最新投稿[/ja]', lang);
    const subCategories = D3ForumUtils.getSubCategories(name, catId).map((category: D3ForumCategoryData) => {
        const title = Functions.mlang(category.cat_title, lang);
        const url = D3ForumUtils.getCategoryUrl(name, category.cat_id);
        const image = category.cat_posts_count === 0 ? imageCategory0 : imageCategory1;
        const latest = category.cat_last_post_time === 0 ? '' : ', ' + LABEL_LATEST_POST + ': ' + moment(new Date(category.cat_last_post_time * 1000)).format('Y/M/D H:m');
        return (
            <li key={category.cat_id}>
                <Link to={url} title={title}><img src={image} alt={title} width="16" height="16" /> {title}</Link>
                &nbsp;
                ({LABEL_TOTAL_TOPICS}: {category.cat_topics_count}, {LABEL_TOTAL_POSTS}: {category.cat_posts_count}{latest})
            </li>
        );
    });
    if (subCategories.length === 0) {
        return null;
    }
    return (
        <>
            <h2 className="head">{LABEL_HEADER_TITLE}</h2>
            <ul className="d3f_subcategories">
                {subCategories}
            </ul>
        </>
    );
}

const D3ForumCategoryForum = (props: D3ForumCategoryProps) => {
    const { lang, name, catId } = props;
    const LABEL_HEADER_TITLE = Functions.mlang('[en]Forum[/en][ja]フォーラム[/ja]', lang);
    const LABEL_FORUM = Functions.mlang('[en]Forum[/en][ja]フォーラム[/ja]', lang);
    const LABEL_TOPICS = Functions.mlang('[en]Topics[/en][ja]トピック数[/ja]', lang);
    const LABEL_POSTS = Functions.mlang('[en]Posts[/en][ja]投稿数[/ja]', lang);
    const LABEL_LATEST_POST = Functions.mlang('[en]Latest post[/en][ja]最新投稿[/ja]', lang);
    const LABEL_NEW_POSTS = Functions.mlang('[en]New posts[/en][ja]新しい投稿があります[/ja]', lang);
    const LABEL_NO_NEW_POSTS = Functions.mlang('[en]No new posts[/en][ja]新しい投稿はありません[/ja]', lang);
    const forums = D3ForumUtils.getForums(name, catId).map((forum: D3ForumForumData, idx: number) => {
        const evenodd = idx % 2 === 0 ? 'even' : 'odd';
        const title = Functions.mlang(forum.forum_title, lang);
        const desc = Functions.mlang(forum.forum_desc, lang);
        const url = D3ForumUtils.getForumUrl(name, forum.forum_id);
        const image = forum.forum_posts_count === 0 ? imageForum0 : imageForum1;
        const getPoster = (postId: number) => {
            const post = D3ForumUtils.getPost(name, postId);
            if (post === null) {
                return null;
            }
            const subject = Functions.mlang(post.subject, lang);
            return (
                <>
                    {moment(new Date(post.post_time * 1000)).format('Y/M/D H:m')}<br />
                    {post.uid_uname}
                    &nbsp;
                    <Link to={D3ForumUtils.getPostUrl(name, post.post_id)}>
                        <PostIcon lang={lang} post={post} title={subject} />
                    </Link>
                </>
            );
        };
        return (
            <tr key={forum.forum_id} className={evenodd}>
                <td className="d3f_mainicon"><img src={image} alt={title} /></td>
                <td>
                    <dl>
                        <dt><Link to={url}>{title}</Link></dt>
                        <dd>{desc}</dd>
                    </dl>
                </td>
                <td>{forum.forum_topics_count}</td>
                <td>{forum.forum_posts_count}</td>
                <td className="d3f_posters">
                    {getPoster(forum.forum_last_post_id)}
                </td>
            </tr>
        );
    });
    if (forums.length === 0) {
        return null;
    }
    return (
        <>
            <h2 className="head">{LABEL_HEADER_TITLE}</h2>
            <table className="outer d3f_table">
                <thead>
                    <tr className="head">
                        <th colSpan={2}>{LABEL_FORUM}</th>
                        <th>{LABEL_TOPICS}</th>
                        <th>{LABEL_POSTS}</th>
                        <th>{LABEL_LATEST_POST}</th>
                    </tr>
                </thead>
                <tbody>
                    {forums}
                </tbody>
            </table>
            <div className="d3f_iconexps">
                <ul className="d3f_iconexp">
                    <li><img src={imageForum1} alt="" width="18" height="18" /> = {LABEL_NEW_POSTS}</li>
                </ul>
                <ul className="d3f_iconexp">
                    <li><img src={imageForum0} alt="" width="18" height="18" /> = {LABEL_NO_NEW_POSTS}</li>
                </ul>
            </div>
        </>
    );
}

const D3ForumCategory = (props: Props) => {
    const { lang, name } = props;
    const params = props.match.params;
    if (typeof params.id === 'undefined') {
        return <PageNotFound lang={lang} />;
    }
    const catId = parseInt(params.id, 10);
    const category = D3ForumUtils.getCategory(name, catId);
    if (category === null) {
        return <PageNotFound lang={lang} />;
    }
    const title = Functions.mlang(category.cat_title, lang);
    return (
        <>
            <Helmet>
                <title>{title} - {D3ForumUtils.getTitle(name, lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <div className="d3f_breadcrumbs">
                <Link to={D3ForumUtils.getIndexUrl(name)}>{Functions.mlang('[en]Top[/en][ja]トップ[/ja]', lang)}</Link>
            </div>
            <h1 className="d3f_title">{title}</h1>
            <D3ForumCategorySubCategory lang={lang} name={name} catId={catId} />
            <D3ForumCategoryForum lang={lang} name={name} catId={catId} />
            <CategoryJump lang={lang} name={name} catId={catId} />
        </>
    );
}

export default D3ForumCategory;