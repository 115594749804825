import React, { ChangeEvent, Component, FormEvent } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Config, { MultiLang } from '../config';
import ItemUtil from '../database/lib/ItemUtil';
import Functions from '../functions';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    keyword: string;
}

class Header extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { keyword: '' };
    }

    handleChangeKeyword(event: ChangeEvent<HTMLInputElement>) {
        const keyword = event.target.value;
        this.setState({ keyword });
    }

    handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const keyword = this.state.keyword.trim();
        if (keyword !== '') {
            const url = ItemUtil.getSearchByKeywordUrl('all', this.state.keyword);
            this.props.history.push(url);
        }
    }

    render() {
        const { lang } = this.props;
        const title = Functions.mlang(Config.SITE_TITLE, lang);
        return (
            <div id="header">
                <div className="r_back">
                    <div className="logo">
                        <Link to="/" title={title} className="go_home"><span className="hidden">{title}</span></Link>
                        <a className="go_jnode" href="https://www.neuroinf.jp/" title="INCF Japan Node Portal Site" target="_blank" rel="noopener noreferrer">
                            <span className="hidden">INCF Japan Node Portal Site</span>
                        </a>
                        <div className="search_box">
                            <form onSubmit={this.handleSubmit}>
                                <input type="text" value={this.state.keyword} onChange={this.handleChangeKeyword} />
                                &nbsp;&nbsp;
                                <input className="formButton" type="submit" value="Search" />
                                &nbsp;&nbsp;
                                <Link to="/database/advanced">{Functions.mlang('[en]Advanced[/en][ja]詳細検索[/ja]', lang)}</Link>
                            </form>
                        </div>
                        <div className="sub">
                            <div className="menu">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);