import React from 'react';
import { MultiLang } from '../../config';

interface Props {
    lang: MultiLang;
}

const CerebellarPlatformCommittee = (props: Props) => {
    return (
        <>
            <h3>Members:</h3>
            <p>
                <b>Shinji Kakei</b>:  Motor Disorders Project, Tokyo Metropolitan Institute for Neuroscience<br />
                <b>Yutaka Hirata</b>: Dept. of Computer Science, College of Engineering, Chubu University<br />
                <a href="http://neuralgorithm.org" target="_blank" rel="noopener noreferrer"><b>Tadashi Yamazaki</b></a>: The University of Electro-Communications<br />
                <a href="https://pf.cerebellum.neuroinf.jp/profile/76/" target="_blank" rel="noopener noreferrer"><b>Soichi Nagao</b></a>: Laboratory for Integrative Brain Functions, Nozomi Hospital<br />
                <b>Shiro Usui</b>: Toyohashi University of Technology<br />
                <b>Yoko Yamaguchi</b>: Neuroinformatics Unit, RIKEN CBS<br />
                <a href="https://pf.cerebellum.neuroinf.jp/profile/57/" target="_blank" rel="noopener noreferrer"><b>Takeru Honda</b></a>: Motor Disorders Project, Tokyo Metropolitan Institute for Neuroscience
      </p>
        </>
    );
}

export default CerebellarPlatformCommittee;
