import React from 'react';
import { MultiLang } from '../config';
import CreditsMenu from '../credits/block/CreditsMenu';
import IndexTree from '../database/blocks/IndexTree';
import Functions from '../functions';
import MainMenu from './blocks/MainMenu';
import SelectLang from './blocks/SelectLang';

interface Props {
    lang: MultiLang;
}

const LeftColumn = (props: Props) => {
    const { lang } = props;
    const titles = {
        selectLang: Functions.mlang('[en]Select Language[/en][ja]言語選択[/ja]', lang),
        mainMenu: Functions.mlang('[en]Main Menu[/en][ja]メインメニュー[/ja]', lang),
        indexTree: Functions.mlang('[en]Index Tree[/en][ja]インデックスツリー[/ja]', lang),
        siteInfo: Functions.mlang('[en]Site Information[/en][ja]サイト情報[/ja]', lang),
    };
    return (
        <div className="leftcolumn">
            <div className="block">
                <div className="blockTitle">{titles.selectLang}</div>
                <div className="blockContent">
                    <SelectLang lang={lang} />
                </div>
            </div>
            <div className="block">
                <div className="blockTitle">{titles.mainMenu}</div>
                <div className="blockContent">
                    <MainMenu lang={lang} />
                </div>
            </div>
            <div className="block">
                <div className="blockTitle">{titles.indexTree}</div>
                <div className="blockContent">
                    <IndexTree lang={lang} />
                </div>
            </div>
            <div className="block">
                <div className="blockTitle">{titles.siteInfo}</div>
                <div className="blockContent">
                    <CreditsMenu lang={lang} />
                </div>
            </div>
        </div>
    );
}

export default LeftColumn;
