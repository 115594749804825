import React from 'react';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import imageRank2 from '../assets/images/rank3dbf8e94a6f72.gif';
import imageRank3 from '../assets/images/rank3dbf8e9e7d88d.gif';
import imageRank4 from '../assets/images/rank3dbf8ea81e642.gif';
import imageRank5 from '../assets/images/rank3dbf8eb1a72e7.gif';
import imageRank6 from '../assets/images/rank3dbf8edf15093.gif';
import imageRank7 from '../assets/images/rank3dbf8ee8681cd.gif';
import imageRank1 from '../assets/images/rank3e632f95e81ca.gif';

interface Props {
    lang: MultiLang;
    rank: number;
    posts: number;
}

const userRanks = [
    { title: '[en]Just popping in[/en][ja]新米[/ja]', min: 0, max: 20, special: false, image: imageRank1 },
    { title: '[en]Not too shy to talk[/en][ja]半人前[/ja]', min: 21, max: 40, special: false, image: imageRank2 },
    { title: '[en]Quite a regular[/en][ja]常連[/ja]', min: 41, max: 70, special: false, image: imageRank3 },
    { title: '[en]Just can\'t stay away[/en][ja]一人前[/ja]', min: 71, max: 150, special: false, image: imageRank4 },
    { title: '[en]Home away from home[/en][ja]長老[/ja]', min: 151, max: 10000, special: false, image: imageRank5 },
    { title: '[en]Moderator[/en][ja]モデレータ[/ja]', min: 0, max: 0, special: true, image: imageRank6 },
    { title: '[en]Webmaster[/en][ja]管理人[/ja]', min: 0, max: 0, special: true, image: imageRank7 },
];

const UserRankStarImage = (props: Props) => {
    const { lang, rank, posts } = props;
    const findRank = (posts: number) => {
        const rank = userRanks.find((userRank) => {
            if (posts >= userRank.min && posts <= userRank.max) {
                return true;
            }
            return false;
        });
        return typeof rank !== 'undefined' ? rank : userRanks[0];

    }
    const userRank = rank > 0 && rank <= 7 ? userRanks[rank - 1] : findRank(posts);
    const title = Functions.mlang(userRank.title, lang);
    return <img src={userRank.image} alt={title} title={title} />;
}

export default UserRankStarImage;