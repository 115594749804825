const SITE_TITLE = '[en]Cerebellar Platform[/en][ja]小脳プラットフォーム[/ja]';
const SITE_SLOGAN = 'Official Site';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-2787349-1';
const XOONIPS_ITEMTYPES = ['data', 'model', 'paper', 'book', 'tool', 'url', 'memo', 'binder'];
const D3FORUM_MODULES = ['forum'];
const PICO_MODULES = ['documents'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
    D3FORUM_MODULES,
    PICO_MODULES,
}

export default Config;
