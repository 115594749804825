import React from 'react';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import imagePost0 from '../assets/images/posticon0.gif';
import imagePost1 from '../assets/images/posticon1.gif';
import imagePost2 from '../assets/images/posticon2.gif';
import imagePost3 from '../assets/images/posticon3.gif';
import imagePost4 from '../assets/images/posticon4.gif';
import imagePost5 from '../assets/images/posticon5.gif';
import imagePost6 from '../assets/images/posticon6.gif';
import imagePost7 from '../assets/images/posticon7.gif';
import { D3ForumPostData } from './D3ForumUtils';

interface Props {
    lang: MultiLang;
    post: D3ForumPostData;
    title?: string;
}

const postIcons = [imagePost0, imagePost1, imagePost2, imagePost3, imagePost4, imagePost5, imagePost6, imagePost7];
const postIconMeanings = ['[en]none[/en][ja]なし[/ja]', '[en]normal[/en][ja]通常[/ja]', '[en]unhappy[/en][ja]不満[/ja]', '[en]happy[/en][ja]満足[/ja]', '[en]lower it[/en][ja]下げ[/ja]', '[en]raise it[/en][ja]上げ[/ja]', '[en]report[/en][ja]報告[/ja]', '[en]question[/en][ja]質問[/ja]'];

const PostIcon = (props: Props) => {
    const { lang, post, title } = props;
    const num = post.icon < 0 && post.icon > 7 ? 0 : post.icon;
    const icon = postIcons[num];
    const meaning = Functions.mlang(postIconMeanings[num], lang);
    const title_ = typeof title === 'undefined' ? meaning : title;
    return <img src={icon} alt={meaning} title={title_} />;
}

export default PostIcon;
