import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import D3ForumUtils, { D3ForumCategoryData } from './D3ForumUtils';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    name: string;
    catId: number;
}

export const CategoryJump = (props: Props) => {
    const { lang, name, catId } = props;
    const LABEL_TOP = Functions.mlang('[en]Top[/en][ja]トップ[/ja]', lang);
    const LABE_SUBMIT = Functions.mlang('[en]Jump to a category[/en][ja]カテゴリージャンプ[/ja]', lang);
    const catSelect = React.createRef<HTMLSelectElement>();
    const catSelectOptions: JSX.Element[] = [
        <option key="0" value="0">({LABEL_TOP})</option>
    ];
    const catSelectLoop = (categories: D3ForumCategoryData[]) => {
        categories.forEach((category) => {
            const title = Functions.mlang(category.cat_title, lang);
            const depth = category.cat_depth_in_tree !== 0 ? '--'.repeat(category.cat_depth_in_tree) + ' ' : '';
            catSelectOptions.push(<option key={category.cat_id} value={category.cat_id}>{depth}{title}</option>);
            catSelectLoop(D3ForumUtils.getSubCategories(name, category.cat_id));
        });
    };
    catSelectLoop(D3ForumUtils.getCategories(name));
    return (<form className="d3f_form" onSubmit={(e) => {
        e.preventDefault();
        if (catSelect.current !== null) {
            const catId = parseInt(catSelect.current.value, 10)
            const url = catId === 0 ? D3ForumUtils.getIndexUrl(name) : D3ForumUtils.getCategoryUrl(name, catId);
            props.history.push(url);
        }
    }}>
        <select ref={catSelect} defaultValue={String(catId)}>
            {catSelectOptions}
        </select>
        <input type="submit" value={LABE_SUBMIT} />
    </form>);
};

export default withRouter(CategoryJump);