import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/d3forum_common.css';
import './assets/d3forum_main.css';
import D3ForumCategory from './D3ForumCategory';
import D3ForumForum from './D3ForumForum';
import D3ForumPost from './D3ForumPost';
import D3ForumTop from './D3ForumTop';
import D3ForumTopic from './D3ForumTopic';
import D3ForumUtils from './lib/D3ForumUtils';

interface Props {
    lang: MultiLang;
    name: string;
}

const D3Forum = (props: Props) => {
    const { lang, name } = props;
    return (
        <>
            <Helmet>
                <title>{D3ForumUtils.getTitle(name, lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <Switch>
                <Route exact path={'/' + name} render={() => <D3ForumTop {...props} />} />
                <Route exact path={'/' + name + '/category/:id'} render={(props: RouteComponentProps<{ id?: string }>) => <D3ForumCategory lang={lang} name={name} {...props} />} />
                <Route exact path={'/' + name + '/forum/:id'} render={(props: RouteComponentProps<{ id?: string }>) => <D3ForumForum lang={lang} name={name} {...props} />} />
                <Route exact path={'/' + name + '/topic/:id'} render={(props: RouteComponentProps<{ id?: string }>) => <D3ForumTopic lang={lang} name={name} {...props} />} />
                <Route exact path={'/' + name + '/post/:id'} render={(props: RouteComponentProps<{ id?: string }>) => <D3ForumPost lang={lang} name={name} {...props} />} />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}

export default D3Forum;